import { defineStore } from 'pinia'
import ChatType from '../common/enums/ChatType'

export interface Localization {
  code: string,
  label: string
}

export const useApplicationStore = defineStore({
  id: 'application',
  state: () => ({
    chatType: ChatType.Video as ChatType,
    emailConfirmResendTime: 0,
    newUser: false, // Только что зарегался, не обновлял страницу
    localization: {
      code: 'RU',
      label: 'Русский',
    } as Localization
  }),
  getters: {
    currentChatType: (state) : string => state.chatType,
  },
  actions: {
    setChatType(type: ChatType) {
      this.chatType = type
    },
    setLocalization(localization: Localization) {
      this.localization = localization
    }
  }
})
