<template lang="pug">
.lines
  .lines__item(v-for="(line, index) in lines", :key="index", :class="`size-${line.sizeIndex}`")
</template>
<script lang="ts" setup>
import {ref, onMounted, onUnmounted} from 'vue'

interface Line {
  sizeIndex: number
}

let updateInterval : NodeJS.Timeout
const lines = ref<Line[]>([])
const linesCount = 4

for (let i = 0; i < linesCount; i++) {
  lines.value.push({
    sizeIndex: Math.round(Math.random() * 5)
  })
}

const updateLines = () => {
  for (let i = 0; i < linesCount; i++) {
    if (Math.random() > 0.5) {
      lines.value[i].sizeIndex = Math.round(Math.random() * 5)
    }
  }
}

onMounted(() => {
  updateInterval = setInterval(() => {
    updateLines()
  }, Math.random() * 200 + 100)
})

onUnmounted(() => {
  clearInterval(updateInterval)
})
</script>
