const locales = [
  {
    code: 'EN',
    label: 'English',
  },
  {
    code: 'DE',
    label: 'Deutsch',
  },
  {
    code: 'ES',
    label: 'Español',
  },
  {
    code: 'FR',
    label: 'Français',
  },
  {
    code: 'IT',
    label: 'Italiano',
  },
  {
    code: 'PL',
    label: 'Polski',
  },
  {
    code: 'RO',
    label: 'Română',
  },
  {
    code: 'RU',
    label: 'Русский',
  },
  {
    code: 'TR',
    label: 'Türkçe',
  },
  {
    code: 'NL',
    label: 'Nederlands',
  },
  {
    code: 'FI',
    label: 'Suomi',
  },
  {
    code: 'UK',
    label: 'Українська',
  },
  {
    code: 'JA',
    label: '日本語',
  },
  {
    code: 'PT',
    label: 'Português',
  },
  {
    code: 'ZH',
    label: '中文(简体)',
  }
]

export default locales
