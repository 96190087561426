import MessagesCache from './ApplicationCache/MessagesCache.class'
import DialogModel from './CoomeetChat/models/DialogModel.class'
import DialogsCache from './ApplicationCache/DialogsCache.class'
import {ContactsListRule} from './CoomeetChat/CoomeetChat.class'
import {DialogModelMessage} from './CoomeetChat/models/DialogModel/interfaces'
import OptionsCache from './ApplicationCache/OptionsCache.class'

class ApplicationCache {
  private _messagesCache!: MessagesCache
  private _dialogsCache!: DialogsCache

  public optionsCache!: OptionsCache

  public init() : void {
    this._messagesCache = new MessagesCache()
    this._dialogsCache = new DialogsCache()
    this.optionsCache = new OptionsCache()
  }

  public addDialogMessages(dialog: DialogModel, messagesList: DialogModelMessage[]) : void {
    this._messagesCache.addDialog(dialog, messagesList)
  }

  public getDialogMessages(dialog: DialogModel) : any [] {
    const data = this._messagesCache.getDialogMessagesFromCache(dialog)
    // console.log('getDialogMessages', data)
    return data
  }

  public clearDialogMessages(dialog: DialogModel) : void {
    this._messagesCache.clearDialog(dialog)
  }

  public cacheDialogsList(rule: ContactsListRule, dialogs: DialogModel[]) {
    this._dialogsCache.cacheList(rule, dialogs)

    let allDialogs = [] as any[]
    Object.keys(this._dialogsCache.cache).forEach((rule) => {
      allDialogs = allDialogs.concat(this._dialogsCache.cache[rule] as any[])
    })

    if (dialogs.length > 0) {
      Object.keys(this._messagesCache.cache).forEach((dialogIdKey) => {
        const dialogId = parseInt(dialogIdKey)

        if (this._messagesCache.cache[dialogId] && allDialogs.filter((dialogData) => dialogData.ID === dialogId).length === 0) {
          this._messagesCache.deleteDialogById(dialogId)
        }
      })
    }
  }

  public clearDialogs(rule: ContactsListRule) : void {
    this._dialogsCache.clearList(rule)
  }

  public getDialogsCache() {
    return this._dialogsCache.cache
  }

  public clearMessagesCache() : void {
    this._messagesCache.clear()
  }

  public clear() : void {
    this._messagesCache.clear()
    this._dialogsCache.clear()
  }
}

const applicationCache = new ApplicationCache()
export default applicationCache
