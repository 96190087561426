<template lang="pug">
.timer
  .timer__item(v-for="char in hours")
    .timer__item--bg
      .timer__item--bg__top
    .timer__item--value {{char}}

  .timer__separator
    .timer__separator--dot
    .timer__separator--dot

  .timer__item(v-for="char in minutes")
    .timer__item--bg
      .timer__item--bg__top
    .timer__item--value {{char}}

  .timer__separator
    .timer__separator--dot
    .timer__separator--dot

  .timer__item(v-for="char in seconds")
    .timer__item--bg
      .timer__item--bg__top
    .timer__item--value {{char}}
</template>
<script lang="ts" setup>
import {ref, computed} from 'vue'
import CoomeetChatInstance from '../../../../../../../common/classes/CoomeetChat/CoomeetChat.class'

const dialog = CoomeetChatInstance.currentDialog
const nowTimeStamp = Date.now() / 1000

const time = computed(() => {
  return Math.round((dialog.value!.willDeleted??0) - nowTimeStamp)
})

const hours = computed(() => Math.floor(time.value / 3600).toString().padStart(2, '0'))

const minutes = computed(() => {
  const hoursInt = parseInt(hours.value)
  return Math.floor((time.value - hoursInt * 3600) / 60).toString().padStart(2, '0')
})
const seconds = computed(() => {
  const hoursInt = parseInt(hours.value)
  const minutesInt = parseInt(minutes.value)
  return (time.value - hoursInt * 3600 - minutesInt * 60).toString().padStart(2, '0')
})

</script>
