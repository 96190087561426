function *CommandIdGenerator() {
  let i = 1
  while (true) {
    yield i++
  }
}

const commandIdGenerator = CommandIdGenerator()
const getScopeCommandId = () : string => (commandIdGenerator.next().value as number).toString()

export default getScopeCommandId
