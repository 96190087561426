<template lang="pug">
.invite-form
  AcceptInvite(v-if="acceptInviteFormVisible")
  InviteRequest(v-else-if="requestInviteFormVisible")
  SendInvite(v-else)
</template>
<script lang="ts" setup>
import {computed} from 'vue'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import AcceptInvite from './InviteForm/AcceptInvite.vue'
import InviteRequest from './InviteForm/InviteRequest.vue'
import SendInvite from './InviteForm/SendInvite.vue'
import {DialogModelStatus} from "../../../../common/classes/CoomeetChat/models/DialogModel/interfaces";
const dialog = CoomeetChatInstance.currentDialog

const acceptInviteFormVisible = computed(() => {
  return dialog.value!.status === DialogModelStatus.SendForMeInvite
})

const requestInviteFormVisible = computed(() => {
  return dialog.value!.status === DialogModelStatus.IamSendInvite
})
</script>
