enum ScopeUserCommands {
  Abuse = 'Abuse',
  AcceptTerms = 'AcceptTerms',
  AccountDelete = 'AccountDelete',
  AccountDeleteCode = 'AccountDeleteCode',
  AccountDeleteConfirm = 'AccountDeleteConfirm',
  AccountDeleteReason = 'AccountDeleteReason',
  AccountValidationStatus = 'AccountValidationStatus',
  AccountVerification = 'AccountVerification',
  ActiveBan = 'ActiveBan',
  Authorization = 'Authorization',
  BlockUser = 'BlockUser',
  CancelChangeEmail = 'CancelChangeEmail',
  ChangeNotification = 'ChangeNotification',
  ChangePassword = 'ChangePassword',
  ChangePersonal = 'ChangePersonal',
  ChangePrivacy = 'ChangePrivacy',
  ChangeUsername = 'ChangeUsername',
  ConfirmMail = 'ConfirmMail',
  DoubleEnter = 'DoubleEnter',
  Info = 'Info',
  Login = 'Login',
  Logout = 'Logout',
  MailConfirm = 'MailConfirm',
  ConfirmCode = 'ConfirmCode',
  MailRestoreStatus = 'MailRestoreStatus',
  MakeAvatar = 'MakeAvatar',
  MakeUser = 'MakeUser',
  NeedAccountValidation = 'NeedAccountValidation',
  Registration = 'Registration',
  RegistrationComplete = 'RegistrationComplete',
  RemoveAvatar = 'RemoveAvatar',
  RemoveBan = 'RemoveBan',
  Restore = 'Restore',
  Referrals = 'Referrals',
  RestoreUser = 'RestoreUser',
  SendConfirmMail = 'SendConfirmMail',
  SetBan = 'SetBan',
  Update = 'Update',
  UpdateScore = 'UpdateScore',
  UserCardCheck = 'UserCardCheck',
  UserCardCheckCancel = 'UserCardCheckCancel',
  UserCardConfirm = 'UserCardConfirm',
  PaymentHistory = 'PaymentHistory',
  WebPushViewMsg = 'WebPushViewMsg',
  WebPushShow = 'WebPushShow',
  WebPushSubscribe = 'WebPushSubscribe',
  ChangeTranslationSettings = 'ChangeTranslationSettings',
  Subscribe = 'Subscribe',
  UnSubscribe = 'UnSubscribe',
  WebPushAfterBlock = 'WebPushAfterBlock',
  NeedUpgrade = 'NeedUpgrade',
  SystemReboot = 'SystemReboot',
  ChangeLang = 'ChangeLang',
  Referral = 'Referral',
  WorkStat = 'WorkStat',
  PromoBonus = 'PromoBonus',
  AddBonus = 'AddBonus',
  OneTap = 'OneTap'
}

export default ScopeUserCommands
