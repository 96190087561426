<template lang="pug">
.video-message(:class="{inbox: !props.message.inbox}")
  .video-message__content(@click="onPlayButtonClick")
    .video
      .preview(v-if="videoData.previewready && !videoPlaying", :style="{backgroundImage: `url(${previewUrl})`}")
      .preview-placeholder(v-else)
      .play-button(v-show="!videoPlaying")
        .play-button__icon
        //PlayVideoIcon
      .preloader(v-show="!videoData.mp4ready")
        .play-button(v-show="isVideoBinaryData")
          .play-button__icon


    .info
      .info__sending(v-if="props.message.scopeData && !props.message.markedForResend")
      template(v-else)
        .info__time(:class="{resend: props.message.markedForResend}") {{messageTime}}
        .info__status(:class="{read: props.message.read}", v-if="!props.message.scopeData")
        .info__status(v-if="props.message.markedForResend").bad
    .corner
      CornerIcon
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
// import PlayVideoIcon from '@/assets/images/icons/play-video.svg'
import {ref, computed, watchEffect, onUnmounted} from 'vue'
import moment from 'moment'
import type {
  DialogModelMessage,
  DialogModelMessageVideo
} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {GetMessageVideoPreviewUrl, GetMessageVideoUrl} from '../../../../../../common/utils/DialogMessageUtils'
import {useChatStore} from '../../../../../../stores/chat'
import {useI18n} from 'vue-i18n'
import {usePopupOverlayStore, Popups} from '../../../../../../stores/popupOverlay'

const props = defineProps<{
  message: DialogModelMessage
}>()

const {t, d} = useI18n()
const popupsStore = usePopupOverlayStore()

const videoData = computed(() => props.message.msg as DialogModelMessageVideo)
const video = ref<HTMLVideoElement | null>(null)
const videoPlaying = ref(false)

const videoUrl = computed(() => {
  return props.message.markedForResend || isVideoBinaryData.value ? (props.message.msg as DialogModelMessageVideo).mp4 : GetMessageVideoUrl(props.message)
})

const previewUrl = computed(() => {
  return props.message.markedForResend || isBinaryData.value ? (props.message.msg as DialogModelMessageVideo).preview :  GetMessageVideoPreviewUrl(props.message)
})

const isBinaryData = computed(() => { return (props.message.msg as DialogModelMessageVideo).preview.indexOf('data:') === 0})
const isVideoBinaryData = computed(() => { return (props.message.msg as DialogModelMessageVideo).mp4?.indexOf('data:') === 0})

const messageTime = computed(() => {
  //return moment(props.message.creationTime).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})
const chatStore = useChatStore()

const onPlayButtonClick = () => {
  //if (videoData.value.mp4ready || isVideoBinaryData.value) popupsStore.showPopup(Popups.VideoViewer, videoUrl.value)
  popupsStore.showPopup(Popups.VideoViewer, videoUrl.value)
  // if (video.value) {
  //   video.value!.currentTime = 0
  //   video.value!.play().then(() => {
  //     videoPlaying.value = true
  //     chatStore.setPlayingVideo(props.message)
  //   })
  // }
}

const onVideoClick = () => {
  stopVideo()
}

const stopVideo = () => {
  if (video.value) video.value!.pause()
  videoPlaying.value = false
  chatStore.setPlayingVideo(null)
}

const onCloseButtonClick = () => {
  stopVideo()
}

onUnmounted(() => {
  if (chatStore.playingVideo?.id === props.message.id) {
    chatStore.setPlayingVideo(null)
  }
})

watchEffect(() => {
  if (chatStore.playingVideo?.id !== props.message.id && videoPlaying.value) {
    stopVideo()
  }
})
</script>
