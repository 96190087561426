<template lang="pug">
.text-message(:class="{inbox: !props.message.inbox}")
  .text-message__content
    template(v-if="props.message.translate && textVisible")
      .text(v-if="currentDialog?.isSupport && props.message.inbox" v-html="props.message.translate" )
      .text(v-else) {{props.message.translate}}
      .translated-text(v-if="props.message.translate") {{message.viewText??''}}

    template(v-else)
      .text(v-if="currentDialog?.isSupport && props.message.inbox" v-html="message.viewText??''" )
      .text(v-else) {{message.viewText??''}}
    .info
      .info__sending(v-if="props.message.scopeData && !props.message.markedForResend")
      template(v-else)
        .info__ball(v-if="props.message.ball > 0") +{{props.message.ball}}{{t('APP_POINT_SHORT')}}
        .info__time(:class="{resend: props.message.markedForResend}") {{messageTime}}
        .info__status(v-if="!props.message.scopeData", :class="{read: props.message.read}")
        .info__status(v-if="props.message.markedForResend").bad
    .corner
      CornerIcon
    div(style="clear: both;")
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
import {computed} from 'vue'
import moment from 'moment'
import type {DialogModelMessage} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {useI18n} from 'vue-i18n'
import CoomeetChatInstance from '../../../../../../common/classes/CoomeetChat/CoomeetChat.class'

const {t, d} = useI18n()
const currentDialog = CoomeetChatInstance.currentDialog

const props = defineProps<{
  message: DialogModelMessage
}>()

const messageTime = computed(() => {
  //moment(props.message.creationTime).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})

const textVisible = computed(() => !!CoomeetChatInstance.user.value.settings?.TranslateIn && props.message.translate !== (props.message.viewText??''))
</script>
