<template lang="pug">
.promo-form
  .promo-form__avatar
    UiUserAvatar(:model="dialog", :size="205")
  .promo-form__title {{t('C_PROMO_MALE_TITLE')}}
  .promo-form__description {{t('APP_MALE_PROMO_APPEAL')}}
  Timer
  .promo-form__actions
    UiSimpleButton.color-green.size-46(@click="onGetPremiumClick") {{t('C_PROMO_MALE_ACTION')}}
</template>
<script lang="ts" setup>
import {usePopupOverlayStore, Popups} from '../../../../../../stores/popupOverlay'
import UiSimpleButton from '../../../../../../components/ui/Buttons/UiSimpleButton.vue'
import UiUserAvatar from '../../../../../../components/ui/UiUserAvatar.vue'
import Timer from './PromoForm/Timer.vue'
import CoomeetChatInstance from '../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const popupsStore = usePopupOverlayStore()
const dialog = CoomeetChatInstance.currentDialog
const user = CoomeetChatInstance.user

const onGetPremiumClick = () => {
  if (user.value.isGuest) {
    popupsStore.showPopup(Popups.LoginRegistration, 'registration')
  } else {
    popupsStore.showPopup(Popups.BuyPremium)
  }
}
</script>
