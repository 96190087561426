import TrackJsWrapper from './ErrorLogger/TrackJsWrapper.class'
import UserModel from './CoomeetChat/models/UserModel.class'
import Application from './Application.class'

class ErrorLogger {
  private _logger?: TrackJsWrapper

  init() {
    if (!Application.config.development) {
      if (!this._logger) {
        this._logger = new TrackJsWrapper()
      } else {
        throw new Error('ErrorLogger has logger instance')
      }
    }
  }

  public setUser(user: UserModel) : void {
    this._logger?.setUser(user)
  }

  public log(...args: any[]) : void {
    this._logger?.log(...args)
  }

  public debug(...args: any[]) : void {
    this._logger?.debug(...args)
  }

  public info(...args: any[]) : void {
    this._logger?.info(...args)
  }

  public warn(...args: any[]) : void {
    this._logger?.warn(...args)
  }

  public error(...args: any[]) : void {
    this._logger?.error(...args)
  }

  public showStack() : void {
    try {
      throw Error('Catch stack trace')
    } catch (e: any) {
      console.log(e.stack)
    }
  }
}

const ErrorLoggerInstance = new ErrorLogger()

export default ErrorLoggerInstance
