<template lang="pug">
.registration(v-if="formVisible")
  .registration-info
    .registration-info__title {{t('APP_REGISTER_BONUS_MINUTES')}}
    .registration-info__description {{t('APP_PL_MINUTE_', 5)}} {{t('APP_FREE')}}!
  form.registration-form(@submit="onSubmit", novalidate)
    .registration-form__form
      UiIconInput(
        v-model="email",
        type="email",
        autocomplete="off",
        name="registration-email",
        :maxlength="254",
        :placeholder="t('H_EMAIL')",
        :tabindex="1",
        @blur="onEmailBlur"
        @input="onEmailInput").email-field
        template(v-slot:icon)
          ShieldIcon
        template(v-slot:error, v-if="emailError")
          span {{emailError}}
        template(v-slot:error, v-if="emailAlreadyRegistered")
          span {{t('C_EMAIL_EXIST')}}.
          a.enter(@click.stop="onLoginClick") {{t('APP_LOG_IN')}}
      UiIconInput(:placeholder="t('H_PASSWORD_CREATE')",
        v-model="password",
        autocomplete="off",
        name="registration-password",
        :type="passwordInputType",
        :tabindex="2",
        @blur="onPasswordBlur"
        @input="onPasswordInput").password-field
        template(v-slot:icon)
          .icon-wrapper(@click="onPasswordFieldIconClick")
            .icon-wrapper__icon-closed-eye(v-if="passwordInputType === 'password'")
            .icon-wrapper__icon-opened-eye(v-else)
        template(v-slot:error, v-if="passwordError")
          span {{passwordError}}

    UiFormSimpleButton.color-pink.size-46 {{t('A_SIGN_UP')}}

  .social-buttons
    .social-buttons-header
      .social-buttons-header__line
      .social-buttons-header__label {{t('C_AUTH_SOCIAL')}}
      .social-buttons-header__line
    AuthSocialNetworks
</template>
<script lang="ts" setup>
import ShieldIcon from '../../../assets/images/icons/shield-filled-size-20.svg'
import CrossedEyeIcon from '@/assets/images/icons/crossed-eye-size-16.svg'
import OpenedEyeIcon from '@/assets/images/icons/opened-eye-size-16.svg'
import UiIconInput from '../../../components/ui/Inputs/UiIconInput.vue'
import UiSimpleButton from '../../../components/ui/Buttons/UiSimpleButton.vue'
import UiFormSimpleButton from '../../../components/ui/Buttons/UiFormSimpleButton.vue'
import AuthSocialNetworks from '../../../components/CoomeetChat/PopupOverlay/_common/AuthSocialNetworks.vue'
import { ref, watchEffect, computed, onMounted } from 'vue'
import CoomeetChatInstance, {CoomeetChat} from '../../../common/classes/CoomeetChat/CoomeetChat.class'
import {useRouter} from 'vue-router'
import Validators from '../../../common/utils/Validators'
import {usePopupOverlayStore, Popups} from '../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const router = useRouter()
const popupsStore = usePopupOverlayStore()

const email = ref('')
const emailError = ref('')
const emailAlreadyRegistered = ref(false)
const password = ref('')
const passwordError = ref('')
const passwordInputType = ref('password')

const formVisible = computed(() => !popupsStore.popupOpened(Popups.LoginRegistration))

const formHasErrors = computed(() => {
  return emailAlreadyRegistered.value && !passwordError.value && !emailError.value
})

const validateForm = () => {
  emailError.value = Validators.email(email.value.trim()) ? '' : t('ERROR_67')
  passwordError.value = password.value.trim() === '' ? t('V_REQUIRED_PASSWORD') : ''
}

const onPasswordFieldIconClick = () => {
  passwordInputType.value = passwordInputType.value === 'password' ? 'text' : 'password'
}

const onLoginClick = () => {
  popupsStore.showPopup(Popups.LoginRegistration, 'login')
}

const onRegisterButtonClick = () => {
  validateForm()

  if (!formHasErrors.value) {
    CoomeetChatInstance.registration({
      email: email.value.trim(),
      password: password.value.trim()
    })?.then(() => {
    }).catch(({response}) => {
      if (response.data.code === 412) {
        emailAlreadyRegistered.value = true
        emailError.value = ''
      } else if (response.data.code === 411) {
        emailError.value = t('ERROR_411')
      } else if (response.data.code === 405) {
        passwordError.value = t('ERROR_405')
      } else if (response.data.code === 498) {
        emailError.value = t('ERROR_498')
      } else if (response.data.code === 401) {
        emailError.value = t('V_REQUIRED_EMAIL')
      }
    })
  }
}

const onEmailBlur = () => {
  emailError.value = Validators.email(email.value.trim()) ? '' : t('ERROR_67')
}
const onEmailInput = () => {
  emailAlreadyRegistered.value = false
  emailError.value = Validators.email(email.value.trim()) ? '' : t('ERROR_67')
}

const onPasswordBlur = () => {
  passwordError.value = password.value.trim() === '' ? t('V_REQUIRED_PASSWORD') : ''
}
const onPasswordInput = () => {
  passwordError.value = password.value.trim() === '' ? t('V_REQUIRED_PASSWORD') : ''
}

const onSubmit = (e: Event) => {
  e.preventDefault()
  onRegisterButtonClick()
}

watchEffect(() => {
  if (!CoomeetChatInstance.user.value?.isGuest) {
    router.replace('/')
  }
})

//onMounted(() => {})

</script>
