<template lang="pug">
.ui-scroll
  UiScrollArea(ref="scrollArea",
    :nailToBottom="props.nailToBottom",
    :videochat="props.videochat",
    @scroll:size="onScrollSize",
    @scroll:move="onScrollMove",
    @scroll:reached-top="onScrollReachedTop",
    @scroll:reached-bottom="onScrollReachedBottom")
    slot
  UiScrollBar(ref="scrollBar",
    @rail:move="onRailMove")
</template>
<script lang="ts" setup>
import {ref} from 'vue'
import UiScrollArea from './UiScroll/UiScrollArea.vue'
import UiScrollBar from './UiScroll/UiScrollBar.vue'

export interface UiScrollInterface {
  animateToBottom: () => void
}

const props = withDefaults(defineProps<{
  nailToBottom?: boolean,
  videochat?: boolean
}>(), {
  nailToBottom: false,
  videochat: false
})

const emit = defineEmits(['scroll:reached-top', 'scroll:reached-bottom', 'scroll:move'])

const scrollBar = ref<{setViewportPercents: (percents: number) => void, setPositionPercents: (percents: number) => void} | null>(null)
const scrollArea = ref<{setPositionPercents: (percents: number) => void, animateToBottom: () => void} | null>(null)

const onScrollSize = ({viewportPercents}: {viewportPercents: number}) => {
  scrollBar.value!.setViewportPercents(viewportPercents)
}

const onScrollMove = ({positionPercents, animationEvent, scrollPosition, scrollPositionInverted } :
                          {positionPercents: number, animationEvent: boolean, scrollPosition: number, scrollPositionInverted: number}) => {
  scrollBar.value!.setPositionPercents(positionPercents)
  emit('scroll:move', {positionPercents, animationEvent, scrollPosition, scrollPositionInverted})
}

const onRailMove = ({positionPercents}: {positionPercents: number}) => {
  scrollArea.value!.setPositionPercents(positionPercents)
}

const animateToBottom = () => {
  scrollArea.value!.animateToBottom()
}

const onScrollReachedTop = () => {
  emit('scroll:reached-top')
}

const onScrollReachedBottom = () => {
  emit('scroll:reached-bottom')
}

defineExpose({
  animateToBottom
})

</script>
