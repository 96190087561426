<template lang="pug">
.ui-icon-input(:class="{error: !!slots.error, focus: focused}")
  input(
    v-if="props.autocomplete === 'off'",
    ref="tempInput",
    type="text",
    :tabindex="-1",
    style="position: fixed; top: -100px; left: 0px")
  input(
    v-if="!!props.maska"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :placeholder="props.inputPlaceholder",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    v-maska,
    :data-maska="props.maska")
  input(
    v-else-if="props.type === 'text' || props.type === 'phone' || props.type === 'char' || props.type === 'password' || props.type === 'email'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :placeholder="props.inputPlaceholder",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    :enterkeyhint="props.type === 'text' || props.type === 'password' ? 'enter' : 'next'")
  input(
    v-else-if="props.type === 'int'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :placeholder="props.inputPlaceholder",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    v-only-number="'int'")
  input(
    v-else-if="props.type === 'float'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :placeholder="props.inputPlaceholder",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    v-only-number="'float'")

  .icon(v-if="slots.icon")
    slot(name="icon")
  .placeholder(:class="{top: !!props.inputPlaceholder || modelValue !== '' || focused}")
    .placeholder__line
    .placeholder__label(v-html="props.placeholder")
  .error-text(:class="{visible: !!slots.error}")
    .error-text__line
    .error-text__content
      slot(name="error")
</template>
<script lang="ts" setup>
import { withDefaults, useSlots, ref, onMounted } from 'vue'

const props = withDefaults(defineProps<{
  type?: 'float' | 'int' | 'text' | 'password' | 'char' | 'phone',
  placeholder?: string,
  inputPlaceholder?: string,
  error?: string,
  modelValue: string,
  maxlength?: number,
  name?: string,
  autocomplete?: 'on' | 'off' | 'username' | 'current-password',
  maska?: string,
  tabindex?: number
}>(), {
  type: 'text',
  placeholder: 'Введите текст',
  autocomplete: 'on'
})

const emits = defineEmits(['update:modelValue', 'input', 'blur', 'keydown'])
const slots = useSlots()

const input = ref<HTMLInputElement | null>(null)
const tempInput = ref<HTMLInputElement | null>(null)
const focused = ref(false)
const inputReadonly = ref(props.autocomplete === 'off')

let selectionRange: {start: number, end: number} | null = null
let fieldValue = props.modelValue

let fireEvents = !inputReadonly.value

const triggerFocus = () => {
  input.value!.focus()
}

const onInput = (e: InputEvent) => {
  const target = (e.target as HTMLInputElement)

  if (props.type === 'char') {
    const charsRegexp = /^[a-zA-Z]+$/

    if (charsRegexp.test(target.value) || target.value.trim() === '') {
      fieldValue = target.value
    } else {
      target.value = fieldValue
      target.setSelectionRange(selectionRange!.start, selectionRange!.end)
    }
  } else if (props.type === 'phone') {
    const charsRegexp = /^([0-9]|\+|\-|\s|\(|\))+$/
    if (charsRegexp.test(target.value) || target.value.trim() === '') {
      fieldValue = target.value
    } else {
      target.value = fieldValue
      target.setSelectionRange(selectionRange!.start, selectionRange!.end)
    }
  }

  if (fireEvents) {
    emits('update:modelValue', target.value)
    emits('input')
  }
}

const onInputFocus = () => {
  focused.value = true
  
  if (inputReadonly.value) {
    inputReadonly.value = false
    
    setTimeout(() => {
      tempInput.value!.focus()
      
      setTimeout(() => {
        input.value!.focus()
        fireEvents = true
      }, 0)
    }, 0)
  }
}

const onInputBlur = () => {
  if (fireEvents) {
    focused.value = false
    emits('blur')
  }
}

const onKeyDown = (e: KeyboardEvent) => {
  const target = (e.target as HTMLInputElement)
  selectionRange = {
    start: target.selectionStart!,
    end: target.selectionEnd!,
  }

  if (fireEvents) {
    emits('keydown', e)
  }
}

defineExpose({ triggerFocus })
</script>
