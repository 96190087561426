enum ScopeCommonCommands {
  Ping = 'Ping',
  Write = 'Write',
  ViewSettings = 'ViewSettings',
  ViewTop = 'ViewTop',
  ShowTopBonus = 'ShowTopBonus',
  RemoveStory = 'RemoveStory',
  PublishStory = 'PublishStory',
  UpdateStory = 'UpdateStory',
  StoryAutoDeleted = 'StoryAutoDeleted',
  FingerPrint = 'FingerPrint',
  Info = 'Info'
}

export default ScopeCommonCommands
