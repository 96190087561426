import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

export class HttpApi {
  private static _Instance: HttpApi

  private _apiURL!: string
  private _axios!: AxiosInstance

  public static GetInstance() : HttpApi {
    if (!this._Instance) this._Instance = new HttpApi()
    return this._Instance
  }

  public setApiURL(url: string) : void {
    this._apiURL = url
    this._createAxiosInstance()
  }

  private _createAxiosInstance () : void {
    if (!this._axios) {
      this._axios = axios.create({
        baseURL: this._apiURL
      })
    }
  }

  public async getRequest(url: string, config?: AxiosRequestConfig) : Promise<any> {
    const response = await this._axios.get(url, config)
    return response.data
  }

  public async apiServerGet(environment : string | null = 'test') : Promise<any> {
    const response = await this._axios.get('/server.get', {
      params: {
        env: environment
      }
    })

    return response.data
  }
}

export default HttpApi.GetInstance()
