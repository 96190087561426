<template lang="pug">
.dots-menu(@click="onMenuClick", ref="menuButton")
  DotsMenuIcon
  teleport(to="body")
    .settings-profile-menu(ref="menu", v-show="menuVisible")
      .settings-profile-menu-option-item(v-for="(menuItem, index) in menuItems", :key="index", @click="onMenuItemClick(menuItem)")
        .settings-profile-menu-option-item__bg
        .settings-profile-menu-option-item__label {{t(menuItem.label)}}
</template>
<script lang="ts" setup>
import {ref, onMounted, onBeforeUnmount, nextTick, watchEffect, computed} from 'vue'
import DotsMenuIcon from '@/assets/images/icons/dots-menu.svg'
import {usePopupOverlayStore, Popups} from '../../../../../stores/popupOverlay'
import CoomeetChatInstance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {useI18n} from "vue-i18n";

interface MenuItem {
  id: string,
  label: string
}

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const menuVisible = ref(false)
const menuButton = ref<HTMLDivElement | null>(null)
const menu = ref<HTMLDivElement | null>(null)

const menuItems = computed<MenuItem[]>(() => {
  return !!CoomeetChatInstance.user.value.bill?.PremiumSubscription?.IsActive ? [
    {
      id: 'unsubscribe',
      label: 'APP_CANCEL_PREMIUM_SUBSCRIPTION'
    },
    {
      id: 'delete-account',
      label: 'C_PROFILE_DELETE'
    }
  ] : [
    {
      id: 'delete-account',
      label: 'C_PROFILE_DELETE'
    }
  ]
})

const selectedMenuItem = ref<MenuItem>(menuItems.value[0])

const onMenuClick = () => {
  menuVisible.value = !menuVisible.value
  nextTick(() => {
    updateMenuPosition()
  })
}

const updateMenuPosition = () => {
  if (menuVisible.value && menuButton.value && menu.value) {
    const menuEl = menu.value!
    const menuButtonEl = menuButton.value!

    const menuRect = menuEl.getBoundingClientRect()
    const buttonRect = menuButtonEl.getBoundingClientRect()

    menuEl.style.left = `${buttonRect.left - menuRect.width + buttonRect.width}px`
    menuEl.style.top = `${buttonRect.top - buttonRect.height + 10}px`
  }
}

const onMenuItemClick = (menuItem: MenuItem) => {
  selectedMenuItem.value = menuItem
  menuVisible.value = false

  if (menuItem.id === 'delete-account') {
    popupsStore.showPopup(Popups.DeleteAccount)
  } else if (menuItem.id === 'unsubscribe') {
    popupsStore.showPopup(Popups.PersonalSubscriptionCancel)
  }
}

const onMouseDown = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('.settings-profile-menu') !== menu.value) {
    if ((e.target as HTMLElement).closest('.dots-menu') !== menuButton.value) {
      menuVisible.value = false
    }
  }
}

onMounted(() => {
  document.addEventListener('click', onMouseDown)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', onMouseDown)
})
</script>
