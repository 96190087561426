import {Md5} from 'md5-typescript'
import {detect} from 'detect-browser'
import iframeMessenger from './IframeMessenger.class'

export enum PushNotificationEvents {
  Dialog = 'Dialog'
}

class PushNotification extends EventTarget {
  public locale : string = 'en'
  private _notificationsAvailable = false
  private _subscription?: PushSubscription
  private _vapid = ''

  public get supported () {
    const browserInfo = detect()
    const browserSupported = ['firefox', 'safari'].indexOf((browserInfo?.name??'').toLowerCase()) < 0

    return browserSupported &&
        window.PushManager !== undefined && window.navigator.serviceWorker !== undefined
  }

  public get notificationsGranted () {
    return Notification.permission === 'granted'
  }

  constructor() {
    super();
  }

  public get subscription() : PushSubscription | undefined {
    return this._subscription
  }

  private _urlBase64ToUint8Array(base64String: string) : Uint8Array {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
  }

  private _convertBase64ToUint8Array(payload: string) {
    try {
      const padding = '='.repeat((4 - (payload.length % 4)) % 4);

      const base64 = (payload + padding).replace(/-/g, '+').replace(/_/g, '/');

      const rawData = window.atob(base64);

      const result = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; i += 1) {
        result[i] = rawData.charCodeAt(i);
      }

      return result;
    } catch (error) {
      throw error;
    }
  }

  private _onServiceWorkerMessage = (e: MessageEvent) : void => {
    const {cmd, data} = e.data

    switch (cmd) {
      case 'NotificationClick':
        this.dispatchEvent(new CustomEvent(PushNotificationEvents.Dialog, {
          detail: data
        }))
        break
    }
  }

  public setVapid(vapid: string) : void {
    this._vapid = vapid
  }

  public async getPushCode() {
    let code = null as string | null
    try {
      const registration = await navigator.serviceWorker.getRegistration()
      if (registration) {
        const subscription = await registration.pushManager.getSubscription()
        code = Md5.init(JSON.stringify(subscription))
      }
    } catch (e) {
      console.log('Error on receive push code')
    }

    return code
  }

  public async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        navigator.serviceWorker.addEventListener('message', this._onServiceWorkerMessage)

        const sw = await navigator.serviceWorker.register('/push-notification-service-worker.js')
        this._notificationsAvailable = true
        // console.log('Service worker registered', sw)

        const serviceWorkerRegistration = await navigator.serviceWorker.ready
        const subscription = await serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this._convertBase64ToUint8Array(this._vapid)
        })

        if (subscription) {
          this._subscription = subscription
          // console.log('Subscription', this._subscription)

          this.postMessage({
            cmd: 'Origin',
            data: iframeMessenger.active ? iframeMessenger.parentWindowOrigin : window.origin
          })
        } else {
          throw Error('Subscription not received')
        }

      } catch (e) {
        console.error('Service worker not registered', e);
      }
    } else {
      console.error('Push notifications not supported')
      this._notificationsAvailable = false
    }
  }

  public postMessage(message: any) : void {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage(message)
    } else {
      console.log('Message not sended, empty service worker controller', navigator.serviceWorker)
    }
  }

  public requestPermission() : Promise<unknown> {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification
        .requestPermission((result) => {
          resolve(result)
        })

      if (permissionResult) {
        permissionResult.then(resolve, reject)
      }
    }).then((permissionResult) => {
      return permissionResult
    })
  }

  public openEnableNotificationsPopup(withBonus = false) : void {
    const url = `${location.origin}/push-notification?locale=${this.locale}&r=${performance.now()}`
    const notificationWindow = window.open(url, 'subscription-window', 'width=640,height=480')

    if (notificationWindow !== null) {
      notificationWindow.addEventListener('load', () => {
        setTimeout(() => {
          notificationWindow!.postMessage({
            cmd: 'vapid',
            data: {
              withBonus,
              vapid: this._vapid
            }
          }, '*')
        }, 1000)
      })
    }
  }
}

const pushNotification = new PushNotification()

export default pushNotification
