import Scope from './Scope.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from "../WebSocketApi.class";
import ScopeScoresCommands from './ScopeScores/commands'

/**
 * Скопе для вывода бабок
 */
export default class ScopeScores extends Scope {
  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      default:
        break
    }
  }

  public payoutCheck() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.PayoutCheck, undefined)
  }

  public validationCard(month: number, year: number, requisites: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.ValidationCard, {
      month,
      year,
      requisites
    })
  }

  public validationCardholder(name: string, surname: string, birthday: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.ValidationCardholder, {
      name,
      surname,
      birthday
    })
  }

  public getWithdrawalBitcoin() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.GetWithdrawalBitcoin, null)
  }

  public validationRequisites(payout: string, requisites: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.ValidationRequisites, {
      payout,
      requisites
    })
  }

  public confirmWithdrawalCancel() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.ConfirmWithdrawalCancel, null)
  }

  public payout(data : any) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.Payout, data)
  }

  public confirmWithdrawal(code: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.ConfirmWithdrawal, {
      code
    })
  }

  public removeRequisites(payout: string, requisites: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeScoresCommands.RemoveRequisites, {
      payout,
      requisites
    }).then(({response, command}) => {
      this._coomeetChatInstance.user.value.savedRequisites = this._coomeetChatInstance.user.value.savedRequisites
        .filter((item) => item.id !== payout || item.Number !== requisites)
      return {response, command}
    })
  }
}
