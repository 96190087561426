enum ScopeScoresCommands {
  PayoutCheck = 'PayoutCheck',
  WrongRequest = 'WrongRequest',
  Payout = 'Payout',
  ValidationCard = 'ValidationCard',
  ValidationCardholder = 'ValidationCardholder',
  GetWithdrawalBitcoin = 'GetWithdrawalBitcoin',
  ValidationRequisites = 'ValidationRequisites',
  ConfirmWithdrawalCancel = 'ConfirmWithdrawalCancel',
  ConfirmWithdrawal = 'ConfirmWithdrawal',
  RemoveRequisites = 'RemoveRequisites'
}

export default ScopeScoresCommands
