<template lang="pug">
.support-menu(v-if="botStatus")
  .support-menu__message(v-if="botStatus.decor")
    .text-message
      .text-message__content
        .text(v-html="botStatus.decor.replaceAll('\\n', '</br>')")
        .corner
          CornerIcon
        div(style="clear: both;")
  .support-menu__list(v-if="botStatus.questions.length > 0 || botStatus.commands.length > 0", :class="{disabled: menuDisabled}")
    template(v-if="botStatus.commands.length === 0")
      .support-menu__list--item(
        v-for="(question, index) in botStatus.questions",
        :key="index",
        @click="onSupportMenuItemClick(question)") {{question}}

    .support-menu__list--item(
      v-for="(command, index) in botStatus.commands",
      :key="index",
      @click="onSupportCommandItemClick(command)") {{command.text}}
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {computed, ref} from 'vue'
import type {DialogModelBotStatusCommand} from '../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'

const emit = defineEmits(['message:sent'])

const currentDialog = CoomeetChatInstance.currentDialog
const botStatus = computed(() => {
  return currentDialog.value!.botStatus
})

const menuDisabled = ref(false)

const onSupportMenuItemClick = (message: string) => {
  emit('message:sent')
  menuDisabled.value = true
  CoomeetChatInstance.sendMessage(message, currentDialog.value!)?.then(() => {
    menuDisabled.value = false
  })
}

const onSupportCommandItemClick = (commandItem: DialogModelBotStatusCommand) => {
  emit('message:sent')
  menuDisabled.value = true
  CoomeetChatInstance.sendMessage(commandItem.text, currentDialog.value!, commandItem.key)?.then(() => {
    menuDisabled.value = false
  })
}
</script>
