<template lang="pug">
.ui-user-avatar(ref="root", :class="{stroked, gray: storyViewed}", :style="rootStyle")
  .ui-user-avatar__image(ref="image",
    :class="avatarType",
    :style="imageStyle")
  .ui-user-avatar__story-button(v-if="showPhoto", :class="{hidden: props.size <= 48}",  @click.stop="onShowPhotoClick")
    //.ui-user-avatar__story-button--bg
  .online-indicator(v-if="props.onlineIndicatorVisible && (props.model.online || isSelf)")
  .premium-indicator(v-if="props.premiumIndicatorVisible && props.model.access" :class="{bottom: premiumIndicatorBottom}")
</template>

<script lang="ts" setup>
import UserModel from '../../common/classes/CoomeetChat/models/UserModel.class'
import { ref, computed, withDefaults } from 'vue'
import DialogModel from '../../common/classes/CoomeetChat/models/DialogModel.class'
import StoryModel from '../../common/classes/CoomeetChat/models/StoryModel.class'
import {useChatStore} from '../../stores/chat'
import {usePopupOverlayStore, Popups} from '../../stores/popupOverlay'
import CoomeetChatInstance from '../../common/classes/CoomeetChat/CoomeetChat.class'

const props = withDefaults(defineProps<{
  model: UserModel | DialogModel | StoryModel,
  onlineIndicatorVisible?: boolean,
  premiumIndicatorVisible?: boolean,
  premiumIndicatorBottom?: boolean
  size?: number,
  showStory?: boolean,
  showPhotoByClick?: boolean,
  gender?: 'male' | 'female'
}>(), {
  size: 48,
  onlineIndicatorVisible: false,
  premiumIndicatorVisible: false,
  premiumIndicatorBottom: false,
  showStory: false,
  showPhotoByClick: false,
  gender: 'male'
})

const chatStore = useChatStore()
const popupsStore = usePopupOverlayStore()

const root = ref<HTMLElement | null>(null)
const image = ref<HTMLElement | null>(null)

const isSelf = computed(() => props.model.id === CoomeetChatInstance.user.value.id)

const showPhoto = computed(() => {
  return (originalAvatarUrl.value && props.showPhotoByClick) ||
         (originalAvatarUrl.value && !props.showStory && props.showPhotoByClick)
})

const stroked = computed(() => {
  return props.model instanceof DialogModel ?  props.model?.hasStory : false
})

const storyViewed = computed(() => {
  return props.model instanceof DialogModel ? props.model?.isStoryViewed : false
})

const avatarType = computed(() => {
  return props.model instanceof UserModel && props.model?.gender ? (props.model?.gender && props.model?.gender === 2 ? 'female' : 'male') : props.gender
})

const buttonsBlockedByCall = computed(() => {
  if (CoomeetChatInstance.outgoingCall.value) {
    return !CoomeetChatInstance.outgoingCall.value!.callCancelled
  }
  return false
})

const avatarUrl = computed(() => {
  if (props.model && (props.model as DialogModel).accountDeleted) return null
  if (props.model?.avatar?.s) {
    return props.size > 48 ? `https://n.coomeet.com/${props.model?.avatar.m}/192/192/` : `https://n.coomeet.com/${props.model?.avatar.s}/192/192/`
  }
  return null
})

const originalAvatarUrl = computed(() => {
  if (props.model) {
    if ((props.model as DialogModel).accountDeleted) return null
    if (props.model?.avatar?.o) {
      return `https://n.coomeet.com/${props.model?.avatar?.o}`
    } else if (props.model?.avatar?.m) {
      return `https://n.coomeet.com/${props.model?.avatar?.m}`
    }
  }
  return null
})

const rootStyle = computed(() => {
  return {
    width: `${props.size! as number}px`,
    height: `${props.size! as number}px`,
  }
})

const imageStyle = computed(() => {
  return stroked.value ? {
    backgroundImage: avatarUrl.value ? `url(${avatarUrl.value})` : null,
    width: `${props.size! as number - 8}px`,
    height: `${props.size! as number - 8}px`
  } : {
    backgroundImage: avatarUrl.value ? `url(${avatarUrl.value})` : null,
    width: `${props.size! as number}px`,
    height: `${props.size! as number}px`
  }
})

const onShowPhotoClick = () => {
  if (!buttonsBlockedByCall.value) popupsStore.showPopup(Popups.PhotoViewer, originalAvatarUrl)
}
</script>
