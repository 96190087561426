<template lang="pug">
.photo-message(:class="{inbox: !props.message.inbox}")
  .photo-message__content
    .photo(:class="{scoped: props.message.scopeData}")
      img(:src="messageUrl", @click="onImageClick")
    .info
      .info__sending(v-if="props.message.scopeData && !props.message.markedForResend")
      template(v-else)
        .info__ball(v-if="props.message.ball > 0") +{{props.message.ball}}{{t('APP_POINT_SHORT')}}
        .info__time(:class="{resend: props.message.markedForResend}") {{messageTime}}
        .info__status(:class="{read: props.message.read}", v-if="!props.message.scopeData")
        .info__status(v-if="props.message.markedForResend").bad
    .corner
      CornerIcon
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
import {ref, computed} from 'vue'
import moment from 'moment'
import type {DialogModelMessage} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {GetMessageImageUrl} from '../../../../../../common/utils/DialogMessageUtils'
import {usePopupOverlayStore, Popups} from '../../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const props = defineProps<{
  message: DialogModelMessage
}>()

const popupsStore = usePopupOverlayStore()
const {t, d} = useI18n()

const messageUrl = computed(() => props.message.scopeData
    ? 'data:image/png;base64,' + props.message.scopeData.data.byte as string
    : GetMessageImageUrl(props.message))

const messageTime = computed(() => {
  //return moment(props.message.creationTime).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})

const onImageClick = () => {
  popupsStore.showPopup(Popups.PhotoViewer, messageUrl)
}

</script>
