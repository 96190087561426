<template lang="pug">
.top-area
  .top-area__content
    .top-area__title(v-if="!isRusvideodatingOrigin") {{t('C_TOP_TITLE')}}
    .top-area__title(v-else) {{t('APP_VIDEODATING_TOP_TITLE')}}
    .top-area__chart
      .chart-tabs
        .chart-tabs__item(
		  v-for="(tabsTypeItem) in tabsTypes"
		  :key="tabsTypeItem.type",
		  @click="onTabsClick(tabsTypeItem.type)"
		  :class="{'--active': tabsTypeItem.type === currentTab}") {{t(tabsTypeItem.label)}}
      .chart-countdown(v-html="t('C_TOP_C_INVOICE_DETAIL_END', {duration: `<span class=\"chart-countdown__timer\">${leftTime}</span>`})")
      .chart-users
        .chart-users__header
          .chart-users__cell.chart-users__cell--header {{t('C_TOP_TABLE_PLACE')}}
          .chart-users__cell.chart-users__cell--header {{t('S_NAME')}}
          .chart-users__cell.chart-users__cell--header {{t('C_TOP_TABLE_POINTS')}}
        template(v-if="currentTopList")
          .chart-users__item(v-for="(topItem) in currentTopList.items")
            .chart-users__cell.chart-users__cell--place(v-if="topItem.place <= 3")
              CrownGoldIcon(v-if="topItem.place === 1")
              CrownSilverIcon(v-if="topItem.place === 2")
              CrownBronzeIcon(v-if="topItem.place === 3")
            .chart-users__cell.chart-users__cell--place(v-else) {{topItem.place}}
            .chart-users__cell.chart-users__cell--shift
              ArrowUp(v-if="topItem.Change > 0")
              ArrowDown(v-if="topItem.Change < 0")
            .chart-users__cell.chart-users__cell--name {{topItem.is_me ? t('S_YOU') : topItem.Username}}
            .chart-users__cell.chart-users__cell--bonus +{{topItem.Bonus}}
        .chart-users__footer(v-if="currentTopList && myPlace > 1")
          .chart-users__note {{myPlace > 8 ? t('S_YOU') : ''}}
          .chart-users__goal(v-html="t('C_TOP_MISSING', {position: `<span class=\"chart-users__place\">${t('APP_PL_POSITION_OT', {n: myPlace - 1})}</span>`, balls: `<span class='chart-users__points'>${t('APP_PL_POINT_', {n: currentTopList.BallsLeft})}</span>`})")
      .chart-info {{t('C_TOP_NOTE_1')}} <br> * {{t('C_TOP_NOTE_2')}}

</template>
<script lang="ts" setup>

import CrownGoldIcon from '@/assets/images/icons/crown-gold.svg'
import CrownSilverIcon from '@/assets/images/icons/crown-silver.svg'
import CrownBronzeIcon from '@/assets/images/icons/crown-bronze.svg'
import ArrowUp from '@/assets/images/icons/arrow-up.svg'
import ArrowDown from '@/assets/images/icons/arrow-down.svg'
import {useI18n} from 'vue-i18n'
import {computed, ref, onBeforeUnmount, watchEffect} from 'vue'
import CoomeetChatInstance from '../../common/classes/CoomeetChat/CoomeetChat.class'
import iframeMessenger from '../../common/classes/IframeMessenger.class'
import {usePopupOverlayStore, Popups} from '../../stores/popupOverlay'

enum TabsType {
  Daily = 'day',
  Weekly = 'week',
  Monthly = 'month'
}

interface TopItem {
  place: number,
  Bonus: number,
  Change: number,
  Username: string,
  is_me: number,
  user_id: number
}

interface TopList {
  type: TabsType,
  BallsLeft: number,
  Change: number,
  SecondsLeft: number,
  items: TopItem[],
  congratulated: boolean
}

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()
const isRusvideodatingOrigin = iframeMessenger.isRusvideodatingOrigin

const currentTab = ref<TabsType | null>(null)
const loadedTab = ref<TabsType | null>(null)
const topsLists = ref<TopList[]>([])

const tabsTypes = ([
  {
    type: TabsType.Daily,
    label: 'C_TOP_DAY'
  },
  {
    type: TabsType.Weekly,
    label: 'C_TOP_WEEK'
  },
  {
    type: TabsType.Monthly,
    label: 'C_TOP_MONTH'
  }
])

const currentTopList = computed(() => {
  const type = currentTab.value !== loadedTab.value ? currentTab.value : loadedTab.value

  return currentTab.value && loadedTab.value ? getTopListByType(type!) : null
})

const leftTime = computed(() => {
  if (currentTopList.value) {
    const delta = currentTopList.value!.SecondsLeft >= 0 ? currentTopList.value!.SecondsLeft : 0
    const days = Math.floor(delta / 86400)
    const hours = Math.floor((delta - days * 86400) / 3600)
    const minutes = Math.floor((delta - days * 86400 - hours * 3600) / 60)
    const seconds = Math.floor((delta - days * 86400 - hours * 3600 - minutes * 60))

    let timeString = ''

    if (days > 0) {
      timeString += `${t('APP_PL_DAY_', {n: days})} `
    }

    timeString += `${t('APP_PL_HOUR_', {n: hours})} ${t('APP_PL_MINUTE_', {n: minutes})} ${seconds} ${t('S_SECOND_S')}`

    return timeString
  }

  return ''
})

const myPlace = computed(() => {
  let place = 9
  const filteredItems = currentTopList.value ? currentTopList.value!.items.filter((item) => item.is_me === 1) : []
  if (filteredItems.length > 0) place = filteredItems[0].place

  return place
})

const myBonus = computed(() => {
  let bonus = 0
  const filteredItems = currentTopList.value ? currentTopList.value!.items.filter((item) => item.is_me === 1) : []
  if (filteredItems.length > 0) bonus = filteredItems[0].Bonus

  return bonus
})

const onTabsClick = (type: TabsType) : void => {
  if (currentTab.value !== type) {
    currentTab.value = type
    loadTopList(type)
  }
}

const loadTopList = (type: TabsType) : void => {
  CoomeetChatInstance.viewTop(type)?.then(({response}) => {
    const topList = getTopListByType(type)
    topList.BallsLeft = response?.data.BallsLeft
    topList.Change = response?.data.Change
    topList.SecondsLeft = response?.data.SecondsLeft
    topList.items = []

    Object.keys(response!.data.top).forEach((key) => {
      const place = parseInt(key)
      topList.items.push({
        place,
        ...response!.data.top[key]
      })
    })

    if (currentTab.value === type) loadedTab.value = type
  })
}

const getTopListByType = (type: TabsType) : TopList => {
  const filtered = topsLists.value.filter((topListItem) => topListItem.type === type)
  if (filtered.length > 0) {
    return filtered[0]
  } else {
    const topList = {
      type,
      BallsLeft: 0,
      Change: 0,
      SecondsLeft: 0,
      items: [],
      congratulated: false
    }

    topsLists.value.push(topList)

    return topList
  }
}

onTabsClick(TabsType.Daily)
loadTopList(TabsType.Weekly)
loadTopList(TabsType.Monthly)

let updateSecondsLeftInterval = setInterval(() => {
  topsLists.value.forEach((topListItem) => {
    topListItem.SecondsLeft--
  })
}, 1000)

onBeforeUnmount(() => {
  clearInterval(updateSecondsLeftInterval)
})


watchEffect(() => {
  if (currentTopList.value && !currentTopList.value!.congratulated && myPlace.value <= 3 && currentTab.value) {
    currentTopList.value!.congratulated = true
  }
})

</script>
