<template lang="pug">
.ui-scroll-bar(:class="{show: viewportPercentsSize < 1}")
  .ui-scroll-bar__area(ref="barArea", @mouseover="makeRailVisible", :class="{captured: capturedMousePosition}")
    .ui-scroll-bar__area--rail(ref="rail",
      :class="{visible: (visible || capturedMousePosition) && viewportPercentsSize < 1}",
      @mousedown.prevent="onRailMouseDown",
      @touchstart.prevent="onRailTouchStart")
</template>
<script lang="ts" setup>
import {ref, onMounted, onBeforeUnmount} from 'vue'

interface Vector2 {
  touchId?: number,
  x: number,
  y: number
}

const emit = defineEmits(['rail:move'])

const barArea = ref<HTMLDivElement | null>(null)
const rail = ref<HTMLDivElement | null>(null)
const visible = ref(false)
const viewportPercentsSize = ref(0)

let railTopPosition = 0
let capturedRailTopPosition: null | number = null
let capturedMousePosition = ref<Vector2 | null>(null)
let hideRailTimeout : NodeJS.Timeout | null

const makeRailVisible = () => {
  if (hideRailTimeout) {
    clearTimeout(hideRailTimeout)
    hideRailTimeout = null
  }

  visible.value = true
  hideRailTimeout = setTimeout(() => {
    visible.value = false
  }, 1000)
}

const getRailHeight = (viewportPercents: number) => {
  viewportPercentsSize.value = viewportPercents
  const barAreaRect = barArea.value!.getBoundingClientRect()
  return barAreaRect.height * viewportPercents
}

const getAvailableDistance = () => {
  const barAreaRect = barArea.value!.getBoundingClientRect()
  const railRect = rail.value!.getBoundingClientRect()

  return barAreaRect.height - railRect.height
}

const setViewportPercents = (percents: number) => {
  rail.value!.style.height = `${getRailHeight(percents)}px`
  makeRailVisible()
}

const setPositionPercents = (percents: number) => {
  setRailTopPosition(getAvailableDistance() * percents)
  makeRailVisible()
}

const emitRailMove = () => {
  emit('rail:move', {
    positionPercents: railTopPosition / getAvailableDistance()
  })
}

const setRailTopPosition = (topPosition: number) => {
  railTopPosition = topPosition

  const barAreaRect = barArea.value!.getBoundingClientRect()
  const railRect = rail.value!.getBoundingClientRect()

  const maxPosition = barAreaRect.height - railRect.height

  if (railTopPosition > maxPosition) railTopPosition = maxPosition
  if (railTopPosition < 0) railTopPosition = 0

  rail.value!.style.top = `${railTopPosition}px`
}

const onRailMouseDown = (e: MouseEvent) => {
  capturedMousePosition.value = {
    x: e.clientX,
    y: e.clientY
  }
  capturedRailTopPosition = railTopPosition
}

const onRailTouchStart = (e: TouchEvent) => {
  const touch = Array.from(e.touches)[0]
  if (touch) {
    capturedMousePosition.value = {
      touchId: touch.identifier,
      x: touch.clientX,
      y: touch.clientY
    }

    capturedRailTopPosition = railTopPosition
  }
}

const onDocumentMouseUp = (e: MouseEvent) => {
  capturedRailTopPosition = null
  capturedMousePosition.value = null
}

const onDocumentTouchEnd = (e: TouchEvent) => {
  capturedRailTopPosition = null
  capturedMousePosition.value = null
}

const onDocumentMouseMove = (e: MouseEvent) => {
  if (capturedMousePosition.value && capturedRailTopPosition !== null) {
    const deltaY = capturedMousePosition.value!.y - e.clientY
    setRailTopPosition(capturedRailTopPosition! - deltaY)
    emitRailMove()
  }
}

const onDocumentTouchMove = (e: TouchEvent) => {
  if (capturedMousePosition.value) {
    const filteredTouches = Array.from(e.touches)
        .filter((touchItem) => touchItem.identifier === capturedMousePosition.value?.touchId)

    if (filteredTouches.length > 0) {
      const touch = filteredTouches[0]
      const deltaY = capturedMousePosition.value!.y - touch.clientY
      setRailTopPosition(capturedRailTopPosition! - deltaY)
      emitRailMove()
    }
  }
}

onMounted(() => {
  document.addEventListener('mouseup', onDocumentMouseUp)
  document.addEventListener('mousemove', onDocumentMouseMove)

  document.addEventListener('touchmove', onDocumentTouchMove)
  document.addEventListener('touchend', onDocumentTouchEnd)
})

onBeforeUnmount(() => {
  document.removeEventListener('mouseup', onDocumentMouseUp)
  document.removeEventListener('mousemove', onDocumentMouseMove)

  document.removeEventListener('touchmove', onDocumentTouchMove)
  document.removeEventListener('touchend', onDocumentTouchEnd)
})

defineExpose({
  setViewportPercents,
  setPositionPercents
})
</script>
