<template lang="pug">
.promo_timer
  .promo_timer__title
    .div(v-html ="t('C_PROMO_FEMALE_GET_BALL', {minutes: `<span class='black'>` + minutesCount + `:` + secondsCount + t('APP_PL_MINUTE_', minutesCount) + `</span>`, balls: `<span class='orange'> +` + currentDialog?.promo?.balls + t('S_BALL_S') + `</span>`})")
    .promo_timer__icon(v-tooltip:top)
      QuestionIcon()
      .v-tooltip-text(style="display:none;")
        .additional-info__item--points__tooltip( v-html = "t('APP_PROMO_FEMALE_MOTIVE', {minutes: `<span class='black'>` + t('APP_PL_MINUTE_', {n:15}) + `</span>`,  premium: `<span class='black'>`+t('S_PREMIUM')+`</span>`,  ball: `<span class='orange'>+` + t('APP_PL_POINT_', {n: 500}) + `</span>`})")
  .promo_timer__subtitle {{t('C_PROMO_FEMALE_TEXT')}}

</template>

<script setup lang="ts">

import {useI18n} from "vue-i18n";
import CoomeetChatInstance from "../../../../../../common/classes/CoomeetChat/CoomeetChat.class";
import {onMounted, onUnmounted, ref, computed} from "vue";
import QuestionIcon from '@/assets/images/icons/question-in-circle-size-16.svg'

const {t} = useI18n()

const currentDialog = CoomeetChatInstance.currentDialog
const user = CoomeetChatInstance.user

const minutesCount = ref('00')
const secondsCount = ref('00')

let interval: NodeJS.Timeout | null = null


const updateTime = () => {
  const time = currentDialog?.value?.Promo?.expired??0
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60;

  secondsCount.value = seconds.toString().padStart(2, '0')
  minutesCount.value = minutes.toString().padStart(2, '0')
}

onMounted(() => {
  updateTime()

  interval = setInterval(() => {
    updateTime()
  }, 1000)
})

onUnmounted(()=> {
  if (interval) {
    clearInterval(interval);
  }
})

</script>
