<template lang="pug">
.webcam-photo
  .webcam-photo-video
    .webcam-photo-video__bg
    .webcam-photo-video__video
      video(ref="video", playsinline, muted, autoplay, controlsList="nodownload", v-show="!photoURL")
      .image(v-if="photoURL", :style="{backgroundImage: `url('${photoURL}')`}")
    CameraList(v-if="!photoURL && cameraListVisible",
      :local-stream="localStream",
      @selected="onCameraSelected",
      @facingMode="onFacingMode")

  .webcam-photo-actions
    template(v-if="!photoURL")
      UiSimpleButton.size-36.color-pink(@click="onMakePhotoClick", :class="{disabled: !localStream}") {{t('A_PHOTO_MAKE')}}
      UiSimpleButton.size-36.color-black(@click="onCancelClick", :class="{disabled: !localStream}") {{t('APP_CANCELLATION')}}
    template(v-else)
      UiSimpleButton.size-36.color-pink(@click="onApplyClick", :class="{disabled: !localStream || saving}") {{t('A_SAVE')}}
      UiSimpleButton.size-36.color-black.desktop(@click="onMakeAnotherClick", :class="{disabled: !localStream || saving}") {{t('APP_RECAPTURE')}}
      UiSimpleButton.size-36.color-black.mobile(@click="onMakeAnotherClick", :class="{disabled: !localStream || saving}") {{t('APP_RECAPTURE')}}
</template>
<script lang="ts" setup>
import UiSimpleButton from '../../../../../components/ui/Buttons/UiSimpleButton.vue'
import {ref, shallowRef, onMounted, onUnmounted, watchEffect, computed} from 'vue'
import {BlobToBase64} from '../../../../../common/utils/BlobUtils'
import CoomeetChatInstance, {ConnectionState} from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {getWebRtcConstrainsSync} from '../../../../../common/utils/WebRtc'
import {useChatStore} from '../../../../../stores/chat'
import {DevicePermissionStatus} from '../../../../../common/classes/CoomeetChat/WebRtc/MediaStreamRequester.class'
import {Popups, usePopupOverlayStore} from '../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'
import type {DeviceItem} from './WebCamPhoto/CameraList.vue'
import CameraList from './WebCamPhoto/CameraList.vue'
import ErrorLoggerInstance from '../../../../../common/classes/ErrorLogger.class'

const emit = defineEmits(['close'])

const {t} = useI18n()
const chatStore = useChatStore()
const popupsStore = usePopupOverlayStore()

const video = ref<HTMLVideoElement | null>()
const localStream = shallowRef<MediaStream | null>(null)
const streamRequested = ref(false)
const photoURL = shallowRef<string>('')
const devicesPermissionState = CoomeetChatInstance.devicesPermissionState

let constraints: MediaStreamConstraints
const canvas = document.createElement('canvas')
const canvasContext = canvas.getContext('2d')
const saving = ref(false)

const cameraListVisible = computed(() => devicesPermissionState.value.camera === DevicePermissionStatus.Granted)

const onCameraSelected = (deviceItem: DeviceItem) => {
  chatStore.videoDeviceId = deviceItem.deviceId
  requestLocalStream()
}


const onFacingMode = (facingMode: boolean) => {
  chatStore.setVideoFacingMode(facingMode)
  requestLocalStream()
}

const requestLocalStream = async () => {
  streamRequested.value = true
  constraints = getWebRtcConstrainsSync({
    faceCamera: chatStore.videoFacingMode,
    echoCancellation: false,
    videoDeviceId: chatStore.videoDeviceId,
    audioDeviceId: chatStore.audioDeviceId
  })

  if (localStream.value) {
    localStream.value!.getTracks().forEach((trackItem) => trackItem.stop())
    localStream.value = null
  }

  try {
    localStream.value = await CoomeetChatInstance.requestMediaStream(constraints)
    video.value!.srcObject = localStream.value
  } catch (e) {
    console.log('MediaStream not received', e)
    ErrorLoggerInstance.error('Error on receive media stream', 'Avatar form', e)
  } finally {
    streamRequested.value = false
  }
}

const onVideoMetaData = () => {
  if (video.value) {
    video.value!.play()
    canvas.width = video.value!.videoWidth
    canvas.height = video.value!.videoHeight
  }
}

const onCancelClick = () => {
  emit('close')
}

const onMakePhotoClick = () => {
  if (canvasContext) {
    canvasContext.save()
    canvasContext.drawImage(video.value!, 0, 0)
    canvasContext.restore()
    canvas!.toBlob((blob) => {
      if (blob) {
        BlobToBase64(blob).then((base64) => {
          photoURL.value = base64
        })
      }
    })
  }
}

const onApplyClick = () => {
  saving.value = true
  const base64Data = photoURL.value.split(',')
  CoomeetChatInstance.makeAvatar(base64Data[base64Data.length - 1])?.then(() => {
    emit('close')
  }).finally(() => {
    saving.value = false
  })
}

const onMakeAnotherClick = () => {
  photoURL.value = ''
}

onMounted(() => {
  requestLocalStream()
  video.value!.addEventListener('loadedmetadata', onVideoMetaData)
})

onUnmounted(() => {
  if (localStream.value) {
    localStream.value!.getTracks().forEach((track) => track.stop())
  }
})

const showPermissionPopup = () => {
  if (constraints && !popupsStore.popupOpened(Popups.CameraDenied)) popupsStore.showPopup(Popups.CameraDenied, {
    constraints,
    component: 'ProfileWebCamPhoto',
    onClose: () => {
      if (devicesPermissionState.value.camera !== DevicePermissionStatus.Granted) {
        emit('close')
      }
    }
  })

  if (localStream.value) {
    localStream.value.getTracks().forEach((track) => track.stop())
    localStream.value = null
  }
}

watchEffect(() => {
  if (devicesPermissionState.value.camera !== DevicePermissionStatus.Granted) {
    showPermissionPopup()
  }
})

watchEffect(() => {
  if (CoomeetChatInstance.connectionState.value === ConnectionState.Connected) {
    if (video.value &&
        !streamRequested.value &&
        !localStream.value &&
        devicesPermissionState.value.camera === DevicePermissionStatus.Granted) {
      requestLocalStream()
    }
  }
})

</script>
