<template lang="pug">
.stickers-and-gifts(ref="root",
  @click="onStickersClick")
  .stickers-and-gifts__icon
    StickerIcon
  teleport(to="body")
    StickersAndGiftsContent(ref="content", v-show="contentVisible", @send="onStickerOrGiftSend")
</template>
<script lang="ts" setup>
import StickerIcon from '@/assets/images/icons/sticker.svg'
import StickersAndGiftsContent from './StickersAndGifts/StickersAndGiftsContent.vue'
import {nextTick, onMounted, onUnmounted, ref} from 'vue'

const emit = defineEmits(['someItemSend'])
const contentVisible = ref(false)

const onStickersClick = () => {
  contentVisible.value = !contentVisible.value
  nextTick(() => {
    updateContentPosition()
  })
}

const root = ref<HTMLElement | null>(null)
const content = ref<InstanceType<typeof StickersAndGiftsContent> | null>(null)

const updateContentPosition = () => {
  const menuEl = root.value!
  const contentEl = content.value!.$el

  const rect = menuEl.getBoundingClientRect()
  const contentRect = contentEl.getBoundingClientRect()

  let leftPosition = rect.left - contentRect.width + rect.width
  if (leftPosition < 10) leftPosition = 10

  contentEl.style.left = `${leftPosition}px`
  contentEl.style.top = `${rect.top - contentRect.height - 5}px`
}

const onWindowResize = () => {
  updateContentPosition()
}

const onDocumentClick = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('.stickers-and-gifts-content') !== content.value!.$el) {
    if ((e.target as HTMLElement).closest('.stickers-and-gifts') !== root.value) {
      contentVisible.value = false
    }
  }
}

const onStickerOrGiftSend = () => {
  contentVisible.value = false
  emit('someItemSend')
}

window.addEventListener('resize', onWindowResize)
window.visualViewport?.addEventListener('resize', onWindowResize)
document.addEventListener('click', onDocumentClick)

onMounted(() => {
  updateContentPosition()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
  window.visualViewport?.removeEventListener('resize', onWindowResize)
  document.removeEventListener('click', onDocumentClick)
})
</script>
