/* eslint-disable  @typescript-eslint/no-explicit-any */
export default class ModelAbstract {
  public id : number = -1
  protected _json?: any;

  public get json () : any {
    return this._json
  }

  public setData (data: any) : void {
    this.id = data['ID']??0
    if (!this.json) {
      this._json = data
    } else {
      this._json = {
        ...this._json,
        ...data
      }
    }
  }

  public static FromJSON<T extends typeof ModelAbstract> (this: T, data: unknown): InstanceType<T> {
    const model = new this() as InstanceType<T>
    model.setData(data)
    return model
  }

  public toJson () : any {
    return JSON.stringify(this._json)
  }
}
