export default class StickerModel {

  public get image() : string {
    return `/pics/stickers/${this.id}@2x.png`
  }

  public static CreateStickerById(stickerNum: number) : StickerModel {
    const sticker = new StickerModel(`sticker-${stickerNum}`)
    return sticker
  }

  constructor(public readonly id: string) {
  }
}

const MaleStickersList: StickerModel[] = []
const FemaleStickersList: StickerModel[] = []

for (let i = 1; i <= 15; i++) {
  MaleStickersList.push(StickerModel.CreateStickerById(i))
}
MaleStickersList.push(StickerModel.CreateStickerById(31))
MaleStickersList.push(StickerModel.CreateStickerById(32))
MaleStickersList.push(StickerModel.CreateStickerById(33))
MaleStickersList.push(StickerModel.CreateStickerById(35))
MaleStickersList.push(StickerModel.CreateStickerById(36))
MaleStickersList.push(StickerModel.CreateStickerById(37))
MaleStickersList.push(StickerModel.CreateStickerById(38))
MaleStickersList.push(StickerModel.CreateStickerById(39))

for (let i = 16; i <= 34; i++) {
  FemaleStickersList.push(StickerModel.CreateStickerById(i))
}

for (let i = 40; i <= 49; i++) {
  FemaleStickersList.push(StickerModel.CreateStickerById(i))
}


export {
  MaleStickersList,
  FemaleStickersList
}

