import { DirectiveBinding } from 'vue'

const numKeys = ['-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const fnKeys = ['Backspace', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab']
let storedValue = ''

interface OnlyNumberDirective {
  onIntKeyDown: (e:KeyboardEvent) => void,
  onIntKeyUp: (e:KeyboardEvent) => void,
  onFloatKeyDown: (e:KeyboardEvent) => void,
  onFloatKeyUp: (e:KeyboardEvent) => void,
  onIntPaste: (e: Event) => void,
  onFloatPaste: (e: Event) => void
}

const onlyNumberDirective = {
  onIntKeyDown: function (e: KeyboardEvent) : void {
    if (!e.metaKey && e.target && !e.ctrlKey) {
      const pressedKey = e.key
      //if (e.ctrlKey && combo.includes(pressedKey))
      if (!numKeys.includes(pressedKey) && !fnKeys.includes(pressedKey)) {
        e.preventDefault()
      }

      storedValue = (e.target as HTMLInputElement).value
    }
  },
  onIntKeyUp: function (e: KeyboardEvent) : void {
    if (e.target) {
      const strValue = (e.target as HTMLInputElement).value
      const newValue = parseFloat(strValue)

      if (strValue !== '' && strValue !== '-') {
        if (isNaN(newValue)) {
          (e.target as HTMLInputElement).value = isNaN(parseInt(storedValue)) ? '' : storedValue
        }
      }
    }
  },
  onFloatKeyDown: function (e: KeyboardEvent) : void {
    if (e.target) {
      const pressedKey = e.key
      const currentValue = (e.target as HTMLInputElement).value

      if (pressedKey === '.' && currentValue.indexOf('.') >= 0) {
        e.preventDefault()
      } else if (!['.', ...numKeys].includes(pressedKey) && !fnKeys.includes(pressedKey)) {
        e.preventDefault()
      }

      storedValue = currentValue
    }
  },

  onFloatKeyUp: function (e: KeyboardEvent) : void {
    if (e.target) {
      const strValue = (e.target as HTMLInputElement).value
      const newValue = parseFloat(strValue)

      if (strValue !== '' && strValue !== '-') {
        if (strValue[strValue.length - 1] === '.') {
        } else if (isNaN(newValue)) {
          (e.target as HTMLInputElement).value = isNaN(parseFloat(storedValue)) ? '' : storedValue
        }
      }
    }
  },

  onIntPaste: function (e: ClipboardEvent) : void {
    const clipboardText = (e.clipboardData || (window as any) ['clipboardData']).getData('text') as string
    if (parseInt(clipboardText).toString() !== clipboardText) {
      e.preventDefault()
    }
  },

  onFloatPaste: function (e: ClipboardEvent) : void {
    const clipboardText = (e.clipboardData || (window as any) ['clipboardData']).getData('text') as string
    if (parseFloat(clipboardText).toString() !== clipboardText) {
      e.preventDefault()
    }
  },

  mounted (el : HTMLElement, binding: DirectiveBinding) : void {
    const { onIntKeyDown, onIntKeyUp, onFloatKeyDown, onFloatKeyUp, onIntPaste, onFloatPaste } = (binding.dir as OnlyNumberDirective)

    if (binding.value === 'int') {
      el.addEventListener('keydown', onIntKeyDown)
      el.addEventListener('keyup', onIntKeyUp)
      el.addEventListener('paste', onIntPaste)
    } else {
      el.addEventListener('keydown', onFloatKeyDown)
      el.addEventListener('keyup', onFloatKeyUp)
      el.addEventListener('paste', onFloatPaste )
    }
  },

  unmounted (el : HTMLElement, binding: DirectiveBinding) : void {
    const { onIntKeyDown, onIntKeyUp, onFloatKeyDown, onFloatKeyUp, onIntPaste, onFloatPaste } = (binding.dir as OnlyNumberDirective)

    if (binding.value === 'int') {
      el.removeEventListener('keydown', onIntKeyDown)
      el.removeEventListener('keyup', onIntKeyUp)
      el.removeEventListener('paste', onIntPaste)
    } else {
      el.removeEventListener('keydown', onFloatKeyDown)
      el.removeEventListener('keyup', onFloatKeyUp)
      el.removeEventListener('paste', onFloatPaste )
    }
  }
}

export default onlyNumberDirective
