<template lang="pug">
.support
  .support-list
    .support-list__item(@click="onSupportClick(moderationId)")
      .icon
        ModerationIcon
      .details
        .details__title {{t('C_SUPPORT_MODER_NAME')}}
        .details__text {{t('C_SUPPORT_MODER_TEXT')}}
      .write-button
        .write-button__label {{t('A_WRITE')}}
        .write-button__arrow
          ArrowRightIcon

    .support-list__item(@click="onSupportClick(financialId)")
      .icon
        FinanceIcon
      .details
        .details__title {{t('C_SUPPORT_FINANCE_NAME')}}
        .details__text {{t('C_SUPPORT_FINANCE_TEXT')}}
      .write-button
        .write-button__label {{t('A_WRITE')}}
        .write-button__arrow
          ArrowRightIcon

    .support-list__item(@click="onSupportClick(generalId)")
      .icon
        CommonIcon
      .details
        .details__title {{t('C_SUPPORT_ISSUES_NAME')}}
        .details__text {{t('C_SUPPORT_ISSUES_TEXT')}}
      .write-button
        .write-button__label {{t('A_WRITE')}}
        .write-button__arrow
          ArrowRightIcon

</template>
<script lang="ts" setup>
import ModerationIcon from '@/assets/images/components/support/moderation-icon.svg'
import FinanceIcon from '@/assets/images/components/support/finance-icon.svg'
import CommonIcon from '@/assets/images/components/support/common-icon.svg'
import ArrowRightIcon from '@/assets/images/icons/arrow-right-size-16.svg'
import {ref} from 'vue'
import DialogModel from '../../../common/classes/CoomeetChat/models/DialogModel.class'
import {SupportType} from '../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {useRouter} from 'vue-router'
import CoomeetChatInstance from '../../../common/classes/CoomeetChat/CoomeetChat.class'
import {Popups, usePopupOverlayStore} from '../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const router = useRouter()
const popupsStore = usePopupOverlayStore()

const moderationId = ref(0)
const financialId = ref(0)
const generalId = ref(0)

DialogModel.supportDataList.forEach((item) => {
  switch (item.type) {
    case SupportType.Financial:
      financialId.value = item.id
      break
    case SupportType.General:
      generalId.value = item.id
      break
    case SupportType.Moderation:
      moderationId.value = item.id
      break
  }
})

const onSupportClick = (dialogId: number) => {
  if (CoomeetChatInstance.user.value.isGuest) {
    popupsStore.showPopup(Popups.RegistrationWithGifts)
  } else {
    router.push(`/dialog/${dialogId}`)
  }
}
</script>
