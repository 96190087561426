<template lang="pug">
.sticker-item(@click="onStickerClick")
  .sticker-item__image(:style="{backgroundImage: `url('${props.sticker.image}')`}")
</template>
<script lang="ts" setup>
import StickerModel from '../../../../../../../../common/classes/CoomeetChat/models/StickerModel.class'
import CoomeetChatInstance from '../../../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {usePopupOverlayStore, Popups} from '../../../../../../../../stores/popupOverlay'

const popupsStore = usePopupOverlayStore()
const user = CoomeetChatInstance.user
const emit = defineEmits(['send'])


const props = defineProps<{
  sticker: StickerModel
}>()

const onStickerClick = () => {
  CoomeetChatInstance.sendSticker(props.sticker)
  emit('send')
}
</script>
