/**
 * Решили не заводить EventBus и пробрасывать все события через window
 * здесь констансты всех событий
 */

const WindowEvent = {
  PopupsStateUpdated: 'PopupsStateUpdated',
  FocusTextChatInput: 'FocusTextChatInput',
  CommunicationHistoryOpened: 'CommunicationHistoryOpened',
  HideUiSelect: 'HideUiSelect',
  ClickOnDialogItemFromLeftMenu: 'ClickOnDialogItemFromLeftMenu'
}
export default WindowEvent
