import Scope from './Scope.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import ScopeVideoCommands from './ScopeVideo/commands'
import DialogModel from '../../models/DialogModel.class'
import CoomeetChatInstance, {ContactsListRule, VideoDialogState} from '../../CoomeetChat.class'
import {TurnServerData} from '../../models/DialogModel/WebRtcConnection.class'
import getVideoChatMessageId, {updateVideoChatMessageIdPosition} from './VideoChatMessageIdGenerator'
import {
  DialogModelCallCancelReason,
  DialogModelMessage,
  DialogModelMessageType, PromoMessageTemplate
} from '../../models/DialogModel/interfaces'
import {Md5} from 'md5-typescript'

export default class ScopeVideo extends Scope {

  private _turnServerData?: TurnServerData;

  public processData(scopeData: WebSocketApiScopeData) : void {
    super.processData(scopeData)

    switch (scopeData.event.Cmd) {
      case ScopeVideoCommands.CancelWaitRestore: {
        this._coomeetChatInstance.videoDialogState.value = VideoDialogState.WaitRestoreFailed
      }

      case ScopeVideoCommands.ChangeScore:
        if (this._coomeetChatInstance.currentVideoDialog.value) {
          this._coomeetChatInstance.currentVideoDialog.value!.score = scopeData.data.score
        }
        break

      case ScopeVideoCommands.WaitRestoreChat: {
        const dialogId = scopeData.data.user.id
        let dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (!dialog) {
          dialog = DialogModel.FromJSON({
            ID: dialogId,
            ...scopeData.data.user
          })

          this._coomeetChatInstance.dialogsList.addModel(dialog)
        }

        this._coomeetChatInstance.videoDialogForRestoreAfterDisconnect.value = dialog
      }
        break

      //{"event":{"Cmd":"UpdateAfterChat","Cid":""},"data":{"paidGroup":4,"paidMinutes":467,"score":24813,"tariffs":{"2":{"Limit":1009,"PaidRate":12,"FreeRate":5},"3":{"Limit":497,"PaidRate":11,"FreeRate":4},"4":{"Limit":0,"PaidRate":10,"FreeRate":3},"1":{"Limit":1134,"PaidRate":24,"FreeRate":7}},"top":{"Position":76318,"BallsLeft":7525,"SecondsLeft":50954},"rateLevel":3.12}}
      case ScopeVideoCommands.UpdateAfterChat: {
        if (scopeData.data.access !== undefined)
          this._coomeetChatInstance.user.value.access = scopeData.data.access

        const premiumSubscription = this._coomeetChatInstance.user.value.bill?.PremiumSubscription
        if (premiumSubscription && premiumSubscription.IsActive) {
          premiumSubscription.bonusMillisecondsDeposit = scopeData.data.bonusMillisecondsDeposit
        }

        if (scopeData.data.timeLeft) this._coomeetChatInstance.user.value.timeLeft = scopeData.data.timeLeft as number
        if (scopeData.data.limitUpdate) this._coomeetChatInstance.user.value.limitUpdate = scopeData.data.limitUpdate as number

        this._coomeetChatInstance.user.value.paidGroup = scopeData.data.paidGroup
        this._coomeetChatInstance.user.value.paidMinutes = scopeData.data.paidMinutes
        this._coomeetChatInstance.user.value.rateLevel = scopeData.data.rateLevel
        this._coomeetChatInstance.user.value.top = scopeData.data.top

        this._coomeetChatInstance.user.value.updateTariffs(scopeData.data.tariffs)
      }
        break

      case ScopeVideoCommands.VideoCallCancel: {
        // {"event":{"Cmd":"VideoCallFinish","Cid":""},"data":{"status":"VideoCallCancel","user":{"face":false,"gender":1,"id":16748337502072,"camera":1,"username":"Alihandro changed name","avatar":{"s":"","m":"","o":""}}}}
        if (this._coomeetChatInstance.currentVideoDialog.value) {
          const dialogId = scopeData.data.user.id as number
          const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)
          if (dialog && this._coomeetChatInstance.currentVideoDialog.value!.id === dialog.id) {
            this._coomeetChatInstance.clearVideoChatState()
          }
        }
      }
        break

      case ScopeVideoCommands.VideoCall: {
        const dialogId = scopeData.data.user.id as number
        let dialog : DialogModel | null = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (!dialog) {
          dialog = DialogModel.FromJSON({
            ID: dialogId,
            ...scopeData.data.user
          })
        } else {
          dialog.setData({
            ID: dialogId,
            ...scopeData.data.user
          })
        }

        dialog.hidden = false

        //{"event":{"Cmd":"VideoCall","Cid":""},"data":{"user":{"face":false,"gender":1,"id":16748337502072,"camera":1,"username":"Alihandro changed name","avatar":{"s":"","m":"","o":""}}}}

        if (this._coomeetChatInstance.videoDialogForRestoreAfterDisconnect.value ||
            this._coomeetChatInstance.videoDialogState.value === VideoDialogState.WaitRestore) {
          this.videoCallDenied(dialog)
        } else {
          if (dialog) {
            this._coomeetChatInstance.dialogsList.addModel(dialog)
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Last)!.addModel(dialog)
            this._coomeetChatInstance.incomingCall.value = dialog
            this._coomeetChatInstance.incomingCall.value!.isVideoCall = true
          }
        }
      }
        break

      case ScopeVideoCommands.VideoCallFinish: {
        //{"event":{"Cmd":"VideoCallFinish","Cid":""},"data":{"status":"VideoCallDenied","user":{"face":false,"gender":1,"id":1936729882240,"camera":1,"username":"Test Woman","avatar":{"s":"5-d0b1fa91-b310-4931-80fc-aef0f19911ce","m":"5-eea41001-f572-4bd2-8857-6e8d1bea2a41","o":""}}}}
        const dialogId = scopeData.data.user.id as number
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        switch (scopeData.data.status as string) {
          case 'LowMinute':
          case 'VideoCallDenied': {
            if (this._coomeetChatInstance.outgoingCall.value && dialog?.id === this._coomeetChatInstance.outgoingCall.value!.id) {
              dialog.callCancelled = true

              if (scopeData.data.status === 'LowMinute') {
                dialog.callCancelReason = DialogModelCallCancelReason.LowMinute
              } else {
                dialog.callCancelReason = DialogModelCallCancelReason.Canceled
              }
            }
          }
            break

          case 'VideoCallCancel': {
            if (this._coomeetChatInstance.incomingCall.value && dialog?.id === this._coomeetChatInstance.incomingCall.value!.id) {
              dialog.callCancelled = false
              this._coomeetChatInstance.incomingCall.value = null
            }
          }
            break

          case 'ContactNowIsOffline': {
            if (this._coomeetChatInstance.incomingCall.value && dialog?.id === this._coomeetChatInstance.incomingCall.value!.id) {
              dialog.callCancelled = false
              this._coomeetChatInstance.incomingCall.value = null
            } else if (dialog) {
              dialog.callCancelled = true
              dialog.callCancelReason = DialogModelCallCancelReason.ContactNowIsOffline
            }
          }
        }

      }
        break

      case ScopeVideoCommands.SearchFind: {
        this._coomeetChatInstance.videoDialogForRestoreAfterDisconnect.value = null

        if (this._coomeetChatInstance.videoDialogState.value !== VideoDialogState.Empty ||
            this._coomeetChatInstance.outgoingCall.value !== null ||
            this._coomeetChatInstance.incomingCall.value !== null
        ) {
          let dialog = this._coomeetChatInstance.dialogsList.getById(scopeData.data.user.id as number)

          if (!dialog) {
            dialog = DialogModel.FromJSON({
              ID: scopeData.data.user.id,
              ...scopeData.data.user
            })

            dialog!.hidden = true
            this._coomeetChatInstance.dialogsList.addModel(dialog)

            dialog = this._coomeetChatInstance.dialogsList.getById(dialog?.id)
          }

          if (dialog) {
            dialog!.hasStory = scopeData.data.hasStory
            dialog!.online = 1
            dialog.moderatorGift = !!scopeData.data.user.moderatorGift
            dialog.proxy = !!scopeData.data.user.proxy
            dialog.score = scopeData.data.user.score
            dialog.face = !!scopeData.data.user.face
            dialog.perverse = !!scopeData.data.user.perverse
            dialog.isModer = scopeData.data.user.isModer??0
            dialog.videoChatStartTime = scopeData.data.timestamp??(Date.now() / 1000)

            if (scopeData.data.user.contact) {
              dialog.delete = scopeData.data.user.contact.Delete
              dialog.status = scopeData.data.user.contact.Status
            }

            if (scopeData.data.user.access !== undefined) dialog.access = scopeData.data.user.access
          }

          this._coomeetChatInstance.setCurrentVideoDialog(dialog)

          this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Connecting
          this._coomeetChatInstance.currentVideoDialog.value!.callMaster = scopeData.data.master
          this._coomeetChatInstance.currentVideoDialog.value!.turnServedData = scopeData.data.server

          if (scopeData.data.user.checkDate) {
            this._coomeetChatInstance.currentVideoDialog.value!.checkDate = scopeData.data.user.checkDate as number
          }

          this._coomeetChatInstance.currentVideoDialog.value!.screenInterval = (scopeData.data.ScreenInterval as number) * 1000

          /**
           * Во время звонка может произойти смена тарифа, потому сохраняем тариф, который был на момент старта звонка
           * в модель, для корректного расчета заработанных минут за прошедший звонок
           */
          this._coomeetChatInstance.user.value.startCallPaidGroup = this._coomeetChatInstance.user.value.paidGroup
        }
      }
        break

      case ScopeVideoCommands.UserOffer: {
        if (this._coomeetChatInstance
          .currentVideoDialog
          .value) {
          this._coomeetChatInstance
            .currentVideoDialog
            .value!.rtcRemoteOffer = scopeData.data.rtc as RTCSessionDescription
        }
      }
        break

      case ScopeVideoCommands.UserAnswer: {
        if (this._coomeetChatInstance
          .currentVideoDialog
          .value) {
          this._coomeetChatInstance
            .currentVideoDialog
            .value!.rtcRemoteOffer = scopeData.data.rtc as RTCSessionDescription
        }
      }
        break

      case ScopeVideoCommands.UserIce: {
        if (this._coomeetChatInstance.currentVideoDialog.value) {
          // console.log('received ice candidate from socket')
          this._coomeetChatInstance.currentVideoDialog.value!.iceCandidates.push(scopeData.data.rtc as RTCIceCandidate)
        }
      }
        break

      case ScopeVideoCommands.ChatClose: {
        //{"event":{"Cmd":"ChatClose","Cid":""},"data":{"reason":"StopChat","searchStatus":0,"isSearchStopped":false,"time":102,"vote":{"id":1936729882240},"promo":{},"ban":{}}}
        if (this._coomeetChatInstance.currentVideoDialog.value) {
          this._coomeetChatInstance.currentVideoDialog.value!.closeWebrtcConnection()
        }

        if (this._coomeetChatInstance.currentVideoDialog.value) {
          this._coomeetChatInstance.currentVideoDialog.value!.rtcRemoteOffer = null
        }

        this._coomeetChatInstance.currentVideoDialog.value!.videoChatTime = scopeData.data.time??0

        if (this._coomeetChatInstance.user.value.exitFromSearchOnCloseChat) {
          this._coomeetChatInstance.closeLocalMediaStream()
          CoomeetChatInstance.clearVideoChatState()
          // this.searchStop()
        } else {
          if (scopeData.data.reason === 'WaitRestore') {
            this._coomeetChatInstance.videoDialogState.value = VideoDialogState.WaitRestore
            this._coomeetChatInstance.currentVideoDialog.value!.needVote = this._coomeetChatInstance.currentVideoDialog.value?.id === scopeData.data.vote.id
          } else if (scopeData.data.reason === 'Ping') {
            this._coomeetChatInstance.videoDialogState.value = VideoDialogState.ClosedByPing
          } else if (scopeData.data.reason === 'UserOut') {
            if (scopeData.data.vote.id) {
              this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Vote
            } else {
              this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Closed
            }
          } else if (
            scopeData.data.reason === 'StopChat' ||
            scopeData.data.reason === 'SetBan' ||
            scopeData.data.reason === 'Abuse' ||
            scopeData.data.reason === 'ScoreRefunded' ||
            scopeData.data.reason === 'ModerationBad' ||
            scopeData.data.reason === 'ModerationGood'
          ) {
            if (!scopeData.data.searchStatus) {
              if (scopeData.data.vote.id) {
                this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Vote
              } else {
                this._coomeetChatInstance.clearVideoChatState()
              }
            } else {
              this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Searching
              this._coomeetChatInstance.currentVideoDialog.value = null
              this._coomeetChatInstance.resetVideoChatStateForSearchNext()
            }
          } else if (scopeData.data.reason === 'SearchNext') {
            if (!scopeData.data.searchStatus) {
              if (scopeData.data.vote.id) {
                this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Vote
              } else {
                this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Closed
              }
            } else {
              if (!scopeData.event.Cid) {
                CoomeetChatInstance.resetVideoChatStateForSearchNext()
              }

              this._coomeetChatInstance.currentVideoDialog.value = null
            }
          } else if (scopeData.data.reason === 'TimeIsOver') {
            //{"event":{"Cmd":"ChatClose","Cid":""},"data":{"reason":"TimeIsOver","searchStatus":0,"isSearchStopped":false,"time":662,"vote":{},"promo":{},"ban":{}}}
            if (scopeData.data.promo.id) {
              this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Promo
            } else {
              this._coomeetChatInstance.videoDialogState.value = VideoDialogState.CompanionOutOfMinutes
            }
            // setTimeout(() => {
            //   //this._coomeetChatInstance.videoDialogState.value = VideoDialogState.TimeOver
            //   this._coomeetChatInstance.closeLocalMediaStream()
            //   CoomeetChatInstance.clearVideoChatState()
            // },0)
          } else if (scopeData.data.reason === 'VideoCallAccept') {
            //{"event":{"Cmd":"ChatClose","Cid":""},"data":{"reason":"VideoCallAccept","searchStatus":0,"isSearchStopped":false,"time":20,"vote":{},"promo":{},"ban":{}}}
            this._coomeetChatInstance.videoDialogState.value = VideoDialogState.VideoCallAccept
          }
        }
      }
        break

      case ScopeVideoCommands.RealtimeMessage: {
        // {"event":{"Cmd":"RealtimeMessage","Cid":""},"data":{"id":1936729882240,"tempId":1,"orderBy":1677752297213,"msg":"geerefed"}}
        const dialogId = scopeData.data.id as number
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        updateVideoChatMessageIdPosition(scopeData.data.tempId + 1)

        if (dialog) {
          const created = scopeData.data.orderBy < dialog.videoChatStartTime
            ? Math.floor(Date.now() / 1000)
            : Math.floor(scopeData.data.orderBy / 1000)

          const messageData : DialogModelMessage = {
            created,
            id: -Date.now(),
            tempId: scopeData.data.tempId,
            creationTime: scopeData.data.orderBy,
            msg: scopeData.data.msg as string,
            type: DialogModelMessageType.Text,
            read: 1,
            inbox: 1,
            translate: '',
            hide: 0,
            ball: 0,
            viewText: scopeData.data.msg as string
          }

          dialog.addMessage(messageData)
          dialog.setLastMessage(messageData)
          this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Unread)!.addModel(dialog)
        }
      }
        break

      case ScopeVideoCommands.OnlineMessageTranslate: {
        //{"event":{"Cmd":"OnlineMessageTranslate","Cid":"17"},"data":{"id":16748337502072,"temp":2,"tempId":2,"msg":{"ball":0,"type":0,"read":1,"inbox":0,"creationTime":1677752719624,"id":16777527196240,"created":1677752719,"msg":"Привет мир!","translate":"Hello world!"}}}
        const dialogId = scopeData.data.id
        const messageData = scopeData.data.msg

        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (dialog) {
          const created = messageData.created < dialog.videoChatStartTime ? Date.now() / 1000 : messageData.created
          messageData.created = created
          dialog.updateMessageWithTempId(scopeData.data.tempId, messageData)
        }
      }
        break

      case ScopeVideoCommands.SearchQueue: {
        this._coomeetChatInstance.videoChatSearchQueue.value = scopeData.data.position as number
      }
        break

      case ScopeVideoCommands.History: {
        this._coomeetChatInstance.dialogsHistory.clear()
        for (let dialogId in scopeData.data.connects) {
          const id = parseInt(dialogId)
          const dialog = this._coomeetChatInstance.dialogsHistory.getById(id)

          if (dialog) {
            dialog.setData({
              ID: id,
              ...scopeData.data.connects[dialogId]
            })
          } else {
            const newDialogModel = DialogModel.FromJSON({
              ID: id,
              ...scopeData.data.connects[dialogId]
            }) as DialogModel

            this._coomeetChatInstance.dialogsHistory.addModel(newDialogModel)
          }
        }
      }
        break

      case ScopeVideoCommands.UserFaceDetect: {
        if (this._coomeetChatInstance.currentVideoDialog.value) {
          this._coomeetChatInstance.currentVideoDialog.value!.face = !!scopeData.data.face
        }
      }
        break
    }
  }

  /**
   * @deprecated
   * @param dialog
   */
  public videoCall(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.VideoCall, {
      id: dialog.id
    }).then(({command, response}) => {
      this._coomeetChatInstance.outgoingCall.value = dialog
      this._coomeetChatInstance.outgoingCall.value!.isVideoCall = true
      console.log('videoCall', this._coomeetChatInstance.outgoingCall.value!.isVideoCall)
      return {command, response}
    })
  }

  public videoCallCancel(dialog:DialogModel) : Promise<CommandPromiseArguments> {
    if (dialog.callRequested) {
      dialog.callRequested = false
      return this.sendCommand(ScopeVideoCommands.VideoCallCancel, {
        id: dialog.id
      }).then(({command, response}) => {
        // this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Empty
        this._coomeetChatInstance.outgoingCall.value = null
        this._coomeetChatInstance.closeLocalMediaStream()
        return {command, response}
      })
    } else {
      this._coomeetChatInstance.outgoingCall.value = null
    }

    return Promise.resolve({command: null, response: null})
  }

  public videoCallAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    dialog.isVideoCall = true
    return this.sendCommand(ScopeVideoCommands.VideoCallAccept, {
      id: dialog.id
    }).then(({command, response}) => {
      if (this._coomeetChatInstance.outgoingCall.value) {
        this._coomeetChatInstance.outgoingCall.value!.callCancelled = false
        this._coomeetChatInstance.outgoingCall.value = null
      }

      if (response?.event.Cmd !== ScopeVideoCommands.VideoCallFinish) {
        this._coomeetChatInstance.videoDialogState.value = VideoDialogState.Connecting
      } else {
        this._coomeetChatInstance.videoCallDenied(dialog)
      }
      return {command, response}
    })
  }

  public videoCallRequest(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.outgoingCall.value = dialog
    this._coomeetChatInstance.outgoingCall.value!.isVideoCall = true
    dialog.callRequested = true

    return this.sendCommand(ScopeVideoCommands.VideoCallRequest, {
      id: dialog.id
    }).then(({command, response}) => {
      if (response?.data.status === 'approve') {
        dialog.isVideoCall = true
        this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Last)?.addModel(dialog)
      } else if (this._coomeetChatInstance.outgoingCall.value) {
        if (response?.data.reason === DialogModelCallCancelReason.LowMinute) {
          this._coomeetChatInstance.outgoingCall.value = null
          this._coomeetChatInstance.closeLocalMediaStream()
        } else if (response?.data.reason === DialogModelCallCancelReason.VideoCallUnavailable) {
          this._coomeetChatInstance.outgoingCall.value!.callCancelled = true
          this._coomeetChatInstance.outgoingCall.value!.callCancelReason = DialogModelCallCancelReason.VideoCallUnavailable
        } else {
          this._coomeetChatInstance.outgoingCall.value!.callCancelled = true
        }
      }
      return {command, response}
    }).catch(({command, response}) => {
      this._coomeetChatInstance.outgoingCall.value = null
      return {command, response}
    })

    // return Promise.resolve({command: null, response: null})
  }

  public videoCallDenied(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.incomingCall.value = null

    return this.sendCommand(ScopeVideoCommands.VideoCallDenied, {
      id: dialog.id
    })
  }

  public userOffer(description: RTCSessionDescriptionInit) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.UserOffer, {
      rtc: JSON.parse(JSON.stringify(description))
    })
  }

  public userAnswer(description: RTCSessionDescriptionInit) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.UserAnswer, {
      rtc: JSON.parse(JSON.stringify(description))
    })
  }

  public userIce(ice: RTCIceCandidate) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.UserIce, {
      rtc: JSON.parse(JSON.stringify(ice))
    })
  }

  public stopChat() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.StopChat, {})
      .then(({command, response}) => {
        // this._coomeetChatInstance.clearVideoChatState()
        setTimeout(() => {
          this.history()
        }, 5000)
        return {command, response}
      })
  }

  public sendTextMessage(message: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.ChatSendMessage, {
      id: getVideoChatMessageId(),
      msg: message
    }).then(({response, command}) => {
      //{"event":{"Cmd":"NeedUpgrade","Cid":"11"},"data":{}}
      if (!response?.data.id) {
        this._coomeetChatInstance.currentVideoDialog.value!.markVideoMessagesForResend()
      }
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public chatGift(dialog: DialogModel, giftId: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.ChatGift, {
      id: dialog.id,
      present: giftId
    }).then(({command, response}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public sendVote(dialog: DialogModel, score: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SendVote, {
      id: dialog.id,
      type: score
    })
  }

  public sendComment(dialog: DialogModel, type: number, text: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SendComment, {
      id: dialog.id,
      type,
      msg: text
    })
  }

  public searchSetting(anyContent: boolean, filter?: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SearchSetting, {
      ShowAnyContent: anyContent ? 1 : 2,
      filter
    })
  }

  public searchFilterOnlyBest(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SearchFilterOnlyBest, {
      type: enabled ? 1 : 0
    })
  }

  /**
   * @deprecated
   */
  public searchStart() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SearchStart, {})
  }

  public videoSearchRequest() : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.videoChatSearchQueue.value = 0
    return this.sendCommand(ScopeVideoCommands.VideoSearchRequest, {})
      .then(({command, response}) => {
        return {command, response}
      })
  }

  public searchNext() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.SearchNext, {})
  }

  public searchStop() : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.clearVideoChatState()

    return this.sendCommand(ScopeVideoCommands.SearchStop, {}).then(({command, response}) => {
      return {command, response}
    })
  }

  public faceDetect(base64: string) : Promise<CommandPromiseArguments> {
    const md5 = Md5.init(base64)
    return this.sendCommand(ScopeVideoCommands.FaceDetect, {
      byte: base64,
      md5
    }, {
      type: 'image/png'
    })
  }

  public historyDeleteUser(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.HistoryDeleteUser, {
      id: dialog.id
    }).then(({command, response}) => {
      this._coomeetChatInstance.dialogsHistory.removeModel(dialog)
      return {command, response}
    })
  }

  public historyClear() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.HistoryClear, {})
      .then(({command, response}) => {
        this._coomeetChatInstance.dialogsHistory.clear()
        return {command, response}
      })
  }

  public history() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.History, {})
      .then(({command, response}) => {
        return {command, response}
      })
  }

  public loadedData() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.LoadedData, {}).then(({command, response}) => {
      return {command, response}
    })
  }

  public routeChange(toScope: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.RouteChange, {
      toScope
    })
  }

  public promoStart() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.PromoStart, null)
  }

  public getTemplate() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.GetTemplate, null)
  }

  public removeTemplate(template: PromoMessageTemplate) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.promoMessageTemplates.value = this._coomeetChatInstance
      .promoMessageTemplates
      .value
      .filter((item) => item.id !== template.id)

    return this.sendCommand(ScopeVideoCommands.RemoveTemplate, {
      id: template.id
    })
  }

  public sendPromoMessage(dialog: DialogModel, msg: string, template: boolean) : Promise<CommandPromiseArguments> {
    if (dialog.friendStatus === 'None') {
      this._coomeetChatInstance.invite(dialog)
    }

    return this.sendCommand(ScopeVideoCommands.SendPromoMessage, {
      id: dialog.id,
      msg,
      template: template ? 1 : 0
    })
  }

  public cancelWaitRestore() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.CancelWaitRestore, null).then(({command, response}) => {
      return {command, response}
    })
  }

  public cancelRestore() : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.videoDialogForRestoreAfterDisconnect.value = null

    return this.sendCommand(ScopeVideoCommands.CancelRestore, null).then(({command, response}) => {
      return {command, response}
    })
  }

  public restoreChat() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeVideoCommands.RestoreChat, null).then(({command, response}) => {
      return {command, response}
    })
  }
}
