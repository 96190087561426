<template lang="pug">
.language-select
  .selected-language-indicator(@click="toggleListVisibility")
    .selected-language-indicator__image
      img(:src="`/pics/languages/${selectedLocale}.svg`")
  .languages-list(v-show="listVisible")
    .languages-list__corner
    .list-container
      .language-item(v-for="(languageItem, index) in languagesList",
        :key="languageItem.lang",
        @click="onLanguageItemClick(languageItem)")
        .language-item__border
        .language-item__bg
        .language-item__image
          img(:src="`/pics/languages/${languageItem.lang}.svg`")
        .language-item__label {{languageItem.title}}

</template>
<script lang="ts" setup>
import {ref, computed, onMounted, onUnmounted} from 'vue'
import locales from '../../common/lists/locales'
import LocalizationInstance from '../../common/classes/Localization.class'

interface LanguageItem {
  lang: string,
  title: string
}

const languagesList = ref<LanguageItem[]>(locales.map((item) => {
  return {
    lang: item.code.toLowerCase(),
    title: item.label,
  }
}))

const selectedLocale = computed(() => LocalizationInstance.locale.value)

const listVisible = ref(false)

const toggleListVisibility = () => {
  listVisible.value = !listVisible.value
}

const onLanguageItemClick = (item: LanguageItem) => {
  listVisible.value = false
  LocalizationInstance.setLocale(item.lang)
}

const onDocumentClick = (e: MouseEvent) => {
  if (!(e.target as HTMLElement).closest('.language-select')) {
    listVisible.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', onDocumentClick)
})

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick)
})
</script>
