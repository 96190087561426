import type {
  DialogModelMessage,
  DialogModelMessageImage,
  DialogModelMessageVideo
} from '../classes/CoomeetChat/models/DialogModel/interfaces'
import {DialogModelMessageType} from '../classes/CoomeetChat/models/DialogModel/interfaces'
import type {UserModelRecordedStory} from '../classes/CoomeetChat/models/UserModel/interfaces'

const HOST = '//n.coomeet.com'

export function GetMessageImageUrl(message: DialogModelMessage) : string {
  if (message.type !== DialogModelMessageType.Picture) return ''
  const {m} = message.msg as DialogModelMessageImage
  const url = `${HOST}/${m}`
  return url
}

export function GetMessageVideoUrl(message: DialogModelMessage) : string {
  if (message.type !== DialogModelMessageType.NewFormatVideo) return ''
  const {mp4} = message.msg as DialogModelMessageVideo
  const url = `${HOST}/${mp4}`
  return url
}

export function GetMessageVideoPreviewUrl(message: DialogModelMessage) : string {
  if (message.type !== DialogModelMessageType.NewFormatVideo) return ''
  const {preview} = message.msg as DialogModelMessageVideo
  const url = `${HOST}/${preview}`
  return url
}

export function GetMediaVideoUrl(message: DialogModelMessageVideo) : string {
  const {mp4} = message
  const url = `${HOST}/${mp4}`
  return url
}

export function GetRecordedStoryVideoUrl(recordedStory: UserModelRecordedStory) : string {
  const {mp4} = recordedStory.media
  const url = `${HOST}/${mp4}`
  return url
}

export function GetRecordedStoryPreviewUrl(recordedStory: UserModelRecordedStory) : string {
  const {preview} = recordedStory.media
  const url = `${HOST}/${preview}`
  return url
}
