<template lang="pug">
.file-photo
  .file-photo-avatar
    .file-photo-avatar__bg
    .file-photo-avatar__image(v-if="props.fileUrlData")
      .image(:style="{backgroundImage: `url('${props.fileUrlData}')`}")
    UiUserAvatar(v-else, :model="currentUser", :size="191")
  .file-photo-actions
    UiSimpleButton.size-36.color-pink(@click="onApplyClick", :class="{disabled: saving}") {{t('A_SAVE')}}
    UiSimpleButton.size-36.color-black.desktop(@click="onMakeAnotherClick", :class="{disabled: saving}") {{t('C_CAPTURE_IMAGE_ANOTHER')}}
    UiSimpleButton.size-36.color-black.mobile(@click="onMakeAnotherClick", :class="{disabled: saving}") {{t('C_RATELOW_OTHER')}}
</template>
<script lang="ts" setup>
import UiSimpleButton from '../../../../../components/ui/Buttons/UiSimpleButton.vue'
import UiUserAvatar from '../../../../../components/ui/UiUserAvatar.vue'
import CoomeetChatIsntance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {shallowRef, ref, onMounted, nextTick, onBeforeUnmount} from 'vue'
import CoomeetChatInstance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {useI18n} from 'vue-i18n'
const props = defineProps<{
  fileUrlData: string
}>()

const emit = defineEmits(['close', 'makeAnother'])

const {t} = useI18n()

const currentUser = CoomeetChatIsntance.user
const saving = ref(false)

const onCancelClick = () => {
  emit('close')
}

const onMakeAnotherClick = () => {
  emit('makeAnother')
}

const onApplyClick = () => {
  const base64Data = props.fileUrlData.split(',')
  saving.value = true
  CoomeetChatInstance.makeAvatar(base64Data[base64Data.length - 1])?.then(() => {
    emit('close')
  }).finally(() => {
    saving.value = false
  })
}
</script>
