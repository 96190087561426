<template lang="pug">
.intro
  Lines
  .intro__logo(v-if="!isRusvideodatingOrigin")
    BirdIcon
  .intro__text(v-else) {{t('A_WAITING')}}
</template>
<script lang="ts" setup>
import BirdIcon from '@/assets/images/components/ChatHeader/logo.svg'
import Lines from './Intro/Lines.vue'
import iframeMessenger from '../common/classes/IframeMessenger.class'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const isRusvideodatingOrigin = iframeMessenger.isRusvideodatingOrigin
</script>
