<template lang="pug">
.support-alert
  .support-alert__title {{message.title}}
  .support-alert__text {{message.text}}

  .support-alert__close-button(@click="onCloseClick")
</template>
<script lang="ts" setup>
import DialogModel from '../../../../common/classes/CoomeetChat/models/DialogModel.class'

const props = defineProps<{
  dialog: DialogModel
}>()

const message = props.dialog.supportSystemInfoMessage

const onCloseClick = () => {
  props.dialog.supportSystemInfoMessageHidden = true
}
</script>
