import Application from './Application.class'
import UserModel from './CoomeetChat/models/UserModel.class'
import CoomeetChatInstance from './CoomeetChat/CoomeetChat.class'

export enum SocialNetwork {
  MailRu = 'mailru',
  Vkontakte = 'vk',
  Yandex = 'ya',
  Google = 'google',
  Facebook = 'fb',
  MSN = 'msn'
}

interface SocialUrl {
  url: string,
  network: SocialNetwork
}

class SocialNetworks {
  private _urls: SocialUrl[] = []
  private _googleOauthClientId = '1031692585547-8ejnt3d1b0jltts8fp494ag699imhaji.apps.googleusercontent.com' //'885818793346-ao5hjkrj7vvhrnu50s6shtq805eptp07.apps.googleusercontent.com' //'640979427252-s1vq68rr3egdf6iag60g7c5lth69cqd4.apps.googleusercontent.com'

  public get networksList () {
    return Object.keys(SocialNetwork).map((key) => (SocialNetwork as {[i: string] : string})[key])
  }

  constructor() {
    this._createUrls()
  }

  private _createUrls() : void {
    this._urls = [
      {
        url: 'user/soc/google.redirect',
        network: SocialNetwork.Google
      },
      {
        url: 'user/soc/ya.redirect',
        network: SocialNetwork.Yandex
      },
      {
        url: 'user/soc/msn.redirect',
        network: SocialNetwork.MSN
      },
      {
        url: 'user/soc/mailru.redirect',
        network: SocialNetwork.MailRu
      },
      {
        url: 'user/soc/vk.redirect',
        network: SocialNetwork.Vkontakte
      },
      {
        url: 'user/soc/fb.redirect',
        network: SocialNetwork.Facebook
      }
    ]
  }

  public getUrl(network: SocialNetwork) : string {
    const filteredList = this._urls.filter((urlItem) => urlItem.network === network)

    if (filteredList.length > 0) {
      const socialUrl = filteredList[0]
      return `${Application.config.api.url}${Application.config.api.v}/${socialUrl.url}`
    }

    return ''
  }

  public auth(network: SocialNetwork, user: UserModel) : void {
    const url = `${this.getUrl(network)}?hash=${user.hash}`
    window.open(url, '_blank', 'width=510,height=650')
  }

  public initGoogleAuth() : void {
    //@ts-ignore
    if (window.google) {
      //@ts-ignore
      window.google.accounts.id.initialize({
        client_id: this._googleOauthClientId,
        auto_select: false,
        //@ts-ignore
        callback: (data) => {
          CoomeetChatInstance.oneTap(data['credential'] as string)
        }
      })
      //@ts-ignore
      window.google.accounts.id.prompt()
    }
  }
}

const socialNetworks = new SocialNetworks()

export default socialNetworks
