<template lang="pug">
.avatar-form(:class="{error: !!error}")
  input(ref="fileInput", type="file", accept="image/*", @change="onFileInputChange", v-if="fileInputActive")
  WebCamPhoto(v-if="webCamPhotoVisible", @close="onWebCamPhotoClose")
  FilePhoto(v-else-if="fileUrlData",
    :fileUrlData="fileUrlData",
    @close="onFilePhotoClose",
    @makeAnother="onUploadPhotoClick")
  template(v-else)
    .user-avatar(v-if="currentUser.hasAvatar")
      .user-avatar__bg
      UiUserAvatar(:model="currentUser", :size="191")
    .user-avatar.add-photo(v-else, @click="onMakePhotoClick")
      .user-avatar__bg
        .user-avatar__bg--image
          AddPhotoIcon

    .user-avatar__description(v-if="!currentUser.hasAvatar") {{t('APP_TAKE_OR_UPLOAD_YOUR_PHOTO')}}
    .form-actions
      .form-actions__item.photo(v-tooltip:bottom="t('A_PHOTO_MAKE')", @click="onMakePhotoClick")
        CameraIcon
      .form-actions__item.upload-file(v-tooltip:bottom="t('A_PHOTO_UPLOAD')", @click="onUploadPhotoClick")
        ClipIcon
      .form-actions__item.delete-photo(
        v-if="currentUser.hasAvatar",
        v-tooltip:bottom="t('A_PHOTO_DELETE')",
        @click="onDeleteAvatarClick")
        TrashIcon
  .avatar-form__error(v-if="error", :class="{'has-avatar': currentUser.hasAvatar || fileUrlData}") {{error}}
</template>
<script lang="ts" setup>
import AddPhotoIcon from '@/assets/images/pics/add-photo.svg'
import CameraIcon from '@/assets/images/icons/camera-size-18.svg'
import ClipIcon from '@/assets/images/icons/clip-size-20.svg'
import TrashIcon from '@/assets/images/icons/trash-size-18.svg'
import UiUserAvatar from '../../../../components/ui/UiUserAvatar.vue'
import WebCamPhoto from './AvatarForm/WebCamPhoto.vue'
import FilePhoto from './AvatarForm/FilePhoto.vue'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import Application from '../../../../common/classes/Application.class'
import {ref, shallowRef, onMounted, onBeforeUnmount} from 'vue'
import {usePopupOverlayStore, Popups} from '../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const currentUser = CoomeetChatInstance.user
const webCamPhotoVisible = ref(false)
const uploadPhotoVisible = ref(false)
const fileInput = ref<HTMLInputElement | null>(null)
const fileUrlData = shallowRef<string>('')
const fileInputActive = ref(true)
const error = ref<string>('')

const onMakePhotoClick = () => {
  if (!Application.hasVideoDevices) {
    popupsStore.showPopup(Popups.NoCameraAccess)
  } else {
    error.value = ''
    webCamPhotoVisible.value = true
  }
}

const onWebCamPhotoClose = () => {
  webCamPhotoVisible.value = false
}

const onUploadPhotoClick = () => {
  fileInput.value!.click()
}

const onFilePhotoClose = () => {
  uploadPhotoVisible.value = false
  fileUrlData.value = ''
  error.value = ''
}

const onDeleteAvatarClick = () => {
  //popupsStore.showPopup(Popups.DeleteAvatar)
  error.value = ''
  CoomeetChatInstance.removeAvatar()?.then(() => {
    popupsStore.hidePopup()
  })
}

const onFileInputChange = () => {
  error.value = ''

  if (fileInput.value!.files?.length??0 > 0) {
    const file = fileInput.value!.files![0]
    const fileReader = new FileReader()

    if (file.size > 1024 * 1024 * 5 ) {
      error.value = t('APP_FILE_MUST_NOT_EXCEED')
    } else {
      fileReader.addEventListener('load', () => {
        const inputImage = new Image()
        inputImage.addEventListener('error', () => {
          error.value = t('APP_MAXIMUM_FILE_SIZE_EXCEEDED')
        })
        inputImage.addEventListener('load', () => {
          const inputWidth = inputImage.naturalWidth
          const inputHeight = inputImage.naturalHeight

          const aspectRatio = 4 / 3
          const inputImageAspectRatio = inputWidth / inputHeight

          let outputWidth = inputWidth
          let outputHeight = inputHeight
          if (inputImageAspectRatio > aspectRatio) {
            outputWidth = inputHeight * aspectRatio
          } else if (inputImageAspectRatio < aspectRatio) {
            outputHeight = inputWidth / aspectRatio
          }

          const outputX = (outputWidth - inputWidth) * 0.5
          const outputY = (outputHeight - inputHeight) * 0.5

          const outputImage = document.createElement('canvas')

          outputImage.width = outputWidth
          outputImage.height = outputHeight

          const ctx = outputImage.getContext('2d')!
          ctx.drawImage(inputImage, outputX, outputY)

          fileUrlData.value = outputImage.toDataURL()

        })

        inputImage.src = fileReader.result as string
      })

      fileReader.readAsDataURL(file)
    }

    fileInputActive.value = false

    setTimeout(() => {
      fileInputActive.value = true
    }, 0)
  }
}
</script>
