<template lang="pug">
.stickers-and-gifts-content
  .gifts-list(v-if="selectedContent === 'gifts'")
    UiScroll
      .gifts-list__content
        GiftItem(v-for="(giftItem, index) in giftsList",
          :key="giftItem.id",
          :gift="giftItem",
          @send="onGiftSend")
  .stickers-list(v-if="selectedContent === 'stickers'", :class="{disabled: stickersDisabled}")
    UiScroll
      .stickers-list__content
        StickerItem(v-for="(stickerItem, index) in stickersList",
          :key="stickerItem.id",
          :sticker="stickerItem",
          @send="onStickerSend")
  //.bottom-menu
  //  .bottom-menu__item(:class="{selected: selectedContent === 'gifts'}", @click="onSelectContent('gifts')")
  //    GiftIcon
  //  .bottom-menu__item(:class="{selected: selectedContent === 'stickers'}", @click="onSelectContent('stickers')")
  //    StickerIcon
</template>
<script lang="ts" setup>
import GiftIcon from '@/assets/images/icons/gift-size-22.svg'
import StickerIcon from '@/assets/images/icons/sticker-size-22.svg'
import { ref, computed } from 'vue'
import GiftItem from './StickersAndGiftsContent/GiftItem.vue'
import StickerItem from './StickersAndGiftsContent/StickerItem.vue'
import UiScroll from '../../../../../../../components/ui/Scroll/UiScroll.vue'
import GiftModel, {GiftsListModels} from '../../../../../../../common/classes/CoomeetChat/models/GiftModel.class'
import StickerModel, {
  FemaleStickersList,
  MaleStickersList
} from '../../../../../../../common/classes/CoomeetChat/models/StickerModel.class'
import CoomeetChatInstance, {VideoDialogState} from '../../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {UserModelGender} from '../../../../../../../common/classes/CoomeetChat/models/UserModel/interfaces'
const emit = defineEmits(['send'])

const selectedContent = ref('stickers')
const giftsList = ref<GiftModel[]>(GiftsListModels)

const currentVideoDialog = CoomeetChatInstance.currentVideoDialog
const currentDialog = CoomeetChatInstance.currentDialog

const stickersDisabled = computed(() => {
  return currentDialog.value && currentVideoDialog.value && currentVideoDialog.value.id === currentDialog.value.id && !currentDialog.value?.Promo && CoomeetChatInstance.videoDialogState.value !== VideoDialogState.VideoCallAccept && CoomeetChatInstance.videoDialogState.value !== VideoDialogState.Vote
})

const onSelectContent = (contentType: string) => {
  selectedContent.value = contentType
}

const stickersList = ref<StickerModel[]>(CoomeetChatInstance.user.value?.gender === UserModelGender.Male
    ? MaleStickersList : FemaleStickersList)

const onGiftSend = () => {
  emit('send')
}

const onStickerSend = () => {
  emit('send')
}
</script>
