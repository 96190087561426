<template lang="pug">
.ui-scroll-area(
  ref="scroll"
  @scroll="onScroll")
  .ui-scroll-area__content(
    ref="scrollContent")
    slot
</template>
<script lang="ts" setup>
import {ref, onMounted, onBeforeUnmount, nextTick} from 'vue'
import isMobile from 'is-mobile'
import Application from '../../../../common/classes/Application.class'


interface Vector2 {
  touchId?: number,
  x: number,
  y: number
}

const props = withDefaults(defineProps<{
  nailToBottom?: boolean,
  videochat?: boolean
}>(), {
  nailToBottom: false,
  videochat: false
})
const emit = defineEmits(['scroll:reached-top', 'scroll:reached-bottom', 'scroll:move', 'scroll:size'])

const scroll = ref<HTMLDivElement | null>(null)
const scrollContent = ref<HTMLDivElement | null>(null)

let debug = false
let animationEnabled = true
let resized = false
let resizedTo: NodeJS.Timeout | number | null = null
let animateToBottomInProcess = false
let contentPercentsPosition = props.nailToBottom ? 1 : 0
let borderReached = false
let lastScrollHeight = 0
let lastScrollTop = 0
let scrollContentHeight = 0
let scrollTo: NodeJS.Timeout | number | null = null

const getScrollHeight = () => {
  if (scroll.value) {
    const scrollRect = scroll.value!.getBoundingClientRect()
    return Math.floor(scroll.value!.scrollHeight - scrollRect.height)
  }

  return 0
}

const getViewportPercentSize = () => {
  const scrollRect = scroll.value!.getBoundingClientRect()
  const scrollContentRect = scrollContent.value!.getBoundingClientRect()

  if (scrollContentRect.height <= scrollRect.height) {
    return 1
  } else {
    return scrollRect.height / scrollContentRect.height
  }
}

const animateToBottom = () => {
  borderReached = false
  animateToBottomInProcess = true

  // console.log(getScrollHeight(), scroll.value!.scrollHeight)

  scroll.value!.scrollTo({
    top: getScrollHeight(),
    left: 0,
    //@ts-ignore
    behavior: 'smooth'
  })
}

const emitScrollMove = () => {
  if (scroll.value) {
    const scrollRect = scroll.value!.getBoundingClientRect()
    const scrollContentRect = scrollContent.value!.getBoundingClientRect()

    const availableDistance = getScrollHeight()
    const currentPosition = scroll.value!.scrollTop

    const scrollPosition = props.nailToBottom ? availableDistance - Math.abs(currentPosition) : Math.abs(currentPosition)
    const positionPercents = availableDistance > 0 ? currentPosition / availableDistance : 0

    if (scroll.value) scroll.value.dispatchEvent(new CustomEvent('scroll-moved'))

    emit('scroll:move', {
      positionPercents,
      animationEvent: animateToBottomInProcess,
      scrollPosition,
      scrollPositionInverted: 0
    })
  }
}

const emitScrollSize = () => {
  emit('scroll:size', {
    viewportPercents: getViewportPercentSize()
  })
}

const setPositionPercents = (percents: number) => {
  // contentToPosition = getMinPosition() * percents
  const toPosition = getScrollHeight() * percents
  if (scroll.value) {
    scroll.value!.scrollTo({
      top: toPosition,
      //@ts-ignore
      behavior: 'instant'
    })
  }
}

const update = () => {
  if (animationEnabled && scroll.value) {
    const currentScrollHeight = getScrollHeight()

    const scrollRect = scroll.value!.getBoundingClientRect()
    const contentRect = scrollContent.value!.getBoundingClientRect()

    if (contentRect.height > 0) {

      if (props.nailToBottom) {
        if (contentRect.height < scrollRect.height) {
          scrollContent.value!.style.bottom = '0'
          contentPercentsPosition = 1
        } else {
          scrollContent.value!.style.bottom = 'auto'
        }
      }

      if (scrollContentHeight !== contentRect.height) {
        scrollContentHeight = contentRect.height
        resized = true

        if (resizedTo) {
          clearTimeout(resizedTo)
          resizedTo = null
        }

        resizedTo = setTimeout(() => {
          resized = false
        }, 1000)


        emitScrollMove()
        emitScrollSize()
      }

      if (currentScrollHeight !== lastScrollHeight) {
        const delta = currentScrollHeight - lastScrollHeight

        if (props.nailToBottom) {
          let toPosition = null as number | null

          if (Math.round(contentPercentsPosition * 100) >= 100) {

            // toPosition = currentScrollHeight + delta

            contentPercentsPosition = 1

            emitScrollMove()

            if (Application.ios) {
              scroll.value!.scrollTo({
                top: currentScrollHeight - 1,
                left: 0,
                //@ts-ignore
                behavior: 'instant'
              })

              if (scrollTo) {
                clearInterval(scrollTo!)
                scrollTo = null
              }

              scrollTo = setInterval(() => {
                if (Math.round(currentScrollHeight) === scroll.value!.scrollTop ||
                    Math.floor(currentScrollHeight) === scroll.value!.scrollTop ||
                    Math.ceil(currentScrollHeight) === scroll.value!.scrollTop) {
                  clearInterval(scrollTo!)
                  scrollTo = null
                }

                scroll.value!.scrollTo({
                  top: currentScrollHeight,
                  left: 0,
                  //@ts-ignore
                  behavior: 'instant'
                })

                emitScrollMove()
              }, 0)
            } else {
              scroll.value!.scrollTo({
                top: currentScrollHeight,
                left: 0,
                //@ts-ignore
                behavior: 'instant'
              })
            }
          } else if (contentPercentsPosition <= 0 && !props.videochat) {
            toPosition = scroll.value!.scrollTop + delta
            if (toPosition > currentScrollHeight) toPosition = currentScrollHeight

            if (toPosition) {
              scroll.value!.scrollTo({
                top: toPosition,
                left: 0,
                //@ts-ignore
                behavior: 'instant'
              })

              emitScrollMove()

              if (Application.ios) {
                if (scrollTo) {
                  clearInterval(scrollTo!)
                  scrollTo = null
                }

                scrollTo = setInterval(() => {
                  if (Math.round(toPosition!) === scroll.value!.scrollTop ||
                      Math.floor(toPosition!) === scroll.value!.scrollTop ||
                      Math.ceil(toPosition!) === scroll.value!.scrollTop) {
                    clearInterval(scrollTo!)
                    scrollTo = null
                  }

                  scroll.value!.scrollTo({
                    top: toPosition!,
                    left: 0,
                    //@ts-ignore
                    behavior: 'instant'
                  })
                  emitScrollMove()
                }, 0)
              }
            }
          }
        }

        lastScrollHeight = currentScrollHeight

        emitScrollSize()
      } else {
        if (!scrollTo && !resized) {
          contentPercentsPosition = currentScrollHeight > 0 ? Math.ceil(scroll.value!.scrollTop) / currentScrollHeight : (props.nailToBottom ? 1 : 0)
        }
      }

      if (animateToBottomInProcess && lastScrollTop === scroll.value!.scrollTop) {
        animateToBottomInProcess = false
      }
    }

    lastScrollTop = scroll.value!.scrollTop

    requestAnimationFrame(() => {
      update()
    })
  }
}

const onScroll = (e: Event) => {
  const scrollTop = (e.target as HTMLDivElement).scrollTop
  const availableScrollHeight = getScrollHeight()
  const percentsPosition = (e.target as HTMLDivElement).scrollTop / availableScrollHeight

  const topDelta = scrollTop
  const bottomDelta = availableScrollHeight - scrollTop


  if (topDelta <= 1 && !borderReached) {
    borderReached = true
    emit('scroll:reached-top')
  }

  if (bottomDelta <= 1 && !borderReached) {
    borderReached = true
    emit('scroll:reached-bottom')
  }

  if (borderReached && !scrollTo && percentsPosition > 0 && percentsPosition < 1) {
    borderReached = false
  }

  emitScrollMove()
}

onMounted(() => {
  if (props.nailToBottom) {
    contentPercentsPosition = 1
  }

  update()
})

onBeforeUnmount(() => {
  animationEnabled = false

  if (scrollTo) clearInterval(scrollTo)
})

defineExpose({
  setPositionPercents,
  animateToBottom
})
</script>
