import {createI18n, I18n} from 'vue-i18n'
import {ref, WritableComputedRef} from 'vue'
import {HttpApi} from './CoomeetChat/HttpApi/HttpApi.class'
import locales from '../lists/locales'
import {
  choiceFrenchIndex,
  choicePolishIndex,
  choiceRomanianIndex,
  choiceRussianIndex,
  choiceTurkishIndex,
  choiceUkrainianIndex
} from './Localization/pluralRules'
import browserStorage, {BrowserStorageKeys} from './CoomeetChat/BrowserStorage.class'
import iframeMessenger, {IFrameCommand} from './IframeMessenger.class'
import ErrorLoggerInstance from './ErrorLogger.class'

/*
  {
    code: 'NO',
    label: 'Norsk',
  },
  {
    code: 'SV',
    label: 'Svenska',
  },
  {
    code: 'HI',
    label: 'हिन्दी',
  }

 */
const defaultFormats = {
  'date-with-weekday': {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },

  'date-with-weekday-without-year': {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  },

  'date-time': {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },

  'date-without-year': {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },

  time: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },

  date: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },

  'date-numeric': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },

  'day-month': {
    day: 'numeric',
    month: 'numeric',
  },

  'day-month-long': {
    day: 'numeric',
    month: 'long',
  },
};

class Localization {
  public readonly i18n: I18n
  public inited = ref(false)
  public locale = ref('')
  private _messages: Map<string, any> = new Map<string, any>()
  private _httpApi!: HttpApi
  private _currentLocale: string | null = null
  private _errorRequestTimeout: NodeJS.Timeout | null = null

  constructor() {
    this.i18n = createI18n({
      datetimeFormats: this._getDateTimeFormats(),
      pluralRules: {
        ru: choiceRussianIndex,
        fr: choiceFrenchIndex,
        tr: choiceTurkishIndex,
        pl: choicePolishIndex,
        ro: choiceRomanianIndex,
        uk: choiceUkrainianIndex,
      },
      fallbackLocale: 'en',
      legacy: false,
      locale: ''
    })

    this.init()
  }

  private _getDateTimeFormats() : any {
    const dateTimeFormats = {} as {[index: string] : any}

    locales.forEach((localeItem) => {
      const localeCode = localeItem.code.toLowerCase().trim()

      if (localeCode === 'en') {
        dateTimeFormats[localeCode] = {
          ...defaultFormats,
          'date-time': {
            ...defaultFormats['date-time'],
            hour12: true,
          },
          time: {
            ...defaultFormats.time,
            hour12: true,
          }
        }
      } else {
        dateTimeFormats[localeCode] = defaultFormats
      }
    })

    return dateTimeFormats
  }

  public async setLocale(locale: string, updateStorage = true) : Promise<void> {
    if (this._errorRequestTimeout) {
      clearTimeout(this._errorRequestTimeout)
      this._errorRequestTimeout = null
    }

    let formattedLocale = locale.toLowerCase().trim()
    if (!this._localeSupported(formattedLocale)) formattedLocale = 'en'

    if (this.locale.value !== formattedLocale) {
      try {
        if (updateStorage) {
          browserStorage.setItem(BrowserStorageKeys.CoomeetLanguage, formattedLocale)
          iframeMessenger.sendMessage({
            cmd: IFrameCommand.LocaleChange,
            data: formattedLocale
          })
        }

        this.locale.value = formattedLocale

        if (this.i18n.global.availableLocales.indexOf(formattedLocale) < 0) {
          const messagesData = await this._httpApi.getRequest(`/i18n/web/${formattedLocale}`)
          this._replaceSpecialCharacters(messagesData.i18n)
          this.i18n.global.setLocaleMessage(formattedLocale, messagesData.i18n)

          if (this.locale.value === formattedLocale) {
            if (this.i18n.mode === 'legacy') {
              this.i18n.global.locale = formattedLocale
            } else {
              (this.i18n.global.locale as WritableComputedRef<string>).value = formattedLocale
            }
          }
        } else {
          if (this.i18n.mode === 'legacy') {
            this.i18n.global.locale = formattedLocale
          } else {
            (this.i18n.global.locale as WritableComputedRef<string>).value = formattedLocale
          }
        }

        setTimeout(() => {
          this.inited.value = true
        }, 0)
      } catch (e) {
        console.log('An error occurred while requesting i18n, next request after 2 seconds')
        this._errorRequestTimeout = setTimeout(() => {
          this.setLocale(locale)
        }, 2000)
      }
    }
  }


  private _localeSupported(locale: string) {
    return locales
      .filter((item) => item.code.trim().toLowerCase() === locale)
      .length > 0
  }

  private async loadMessages(code: string) : Promise<any> {
    const loadedLocales = Array.from(this._messages.keys())

    if (loadedLocales.indexOf(code) < 0) {
      const response = await fetch('/i18n/web.json')
      const messages = await response.json()
      this._messages.set(code, messages)
    }

    return this._messages.get(code)
  }

  private _replaceSpecialCharacters(messages: any) : void {
    Object.keys(messages).forEach((key) => {
      messages[key] = (messages[key] as string).replaceAll('support@coomeet.com', 'support{\'@\'}coomeet.com')
    })
  }

  public async init() {
    this._httpApi = new HttpApi()
    this._httpApi.setApiURL('https://ap1.coomeet.com/v20')

    const initialLocale = this.getNavigatorLanguage().toLowerCase()

    if (initialLocale !== 'en') {
      const messagesData = await this._httpApi.getRequest(`/i18n/web/en`)
      this._replaceSpecialCharacters(messagesData.i18n)
      this.i18n.global.setLocaleMessage('en', messagesData.i18n)
    }

    return this.setLocale(initialLocale, false)
  }

  private getNavigatorLanguage () : string {
    let locale = browserStorage.getItem(BrowserStorageKeys.CoomeetLanguage)

    const urlParams = new URLSearchParams(window.location.search);
    const queryLocale = urlParams.get('locale');
    if (queryLocale) {
      locale = queryLocale
    }

    if (!locale) {
      //@ts-ignore
      const navigatorLang = (navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en') as string

      const split = navigatorLang.split('-')

      locale =  split.length > 1 ? split[0].trim().toLowerCase() : navigatorLang.trim().toLowerCase()
    }

    return this._localeSupported(locale) ? locale : 'en'
  }
}

const LocalizationInstance = new Localization()

export default LocalizationInstance
