import DialogModel from '../DialogModel.class'
import adapter from 'webrtc-adapter'
import Application from '../../../Application.class'
import ErrorLoggerInstance from '../../../ErrorLogger.class'

export interface TurnServerData {
  User: string,
  Credential: string,
  Urls: string[],
  Policy: string
}

const unreachableIceServers: TurnServerData[] = []
let lastSuccessFullIceServer: TurnServerData | null = null

export default class WebRtcConnection {
  private _id = Math.round(Math.random() * 100000000)
  private _rtcPeerConnection!: RTCPeerConnection
  private _rtcConfiguration!: RTCConfiguration
  private _localStream: MediaStream
  private _server: TurnServerData
  private _onIceCandidateCallback?: (e: RTCIceCandidate) => void

  private _dialog: DialogModel

  public readonly tracks: MediaStreamTrack[] = []
  private _udpateStreamTimeout!: NodeJS.Timeout

  private _closingStateCallback?: (state: RTCPeerConnectionState) => void
  private _connectedCallback?: () => void

  private _connectionTimeoutMilliseconds = 4000
  private _connectionTimeout: NodeJS.Timeout | null = null

  private _debugData = {
    createPeerConnectionTime: 0,
    receiveFirstFrameTime: 0
  }

  public get localDescription() {
    return this._rtcPeerConnection?.localDescription
  }

  public get remoteDescription() {
    return this._rtcPeerConnection?.remoteDescription
  }

  constructor({dialog, localStream, server, onIceCandidate, closingStateCallback, connectedCallback} :
                {
                  dialog: DialogModel,
                  localStream: MediaStream,
                  server: TurnServerData,
                  onIceCandidate: (e: RTCIceCandidate) => void,
                  closingStateCallback?: (state: RTCPeerConnectionState) => void,
                  connectedCallback?: () => void
                }) {
    this._dialog = dialog
    this._server = server
    this._localStream = localStream
    this._onIceCandidateCallback = onIceCandidate
    this._closingStateCallback = (closingStateCallback??undefined)
    this._connectedCallback = (connectedCallback??undefined)

    this._createRtcConfiguration()
    this._createPeerConnection()

    this._localStream.addEventListener('streamchanged', this._onMediaStreamTracksChanged)
  }

  private _onMediaStreamTracksChanged = () => {
    this.setNewMediaStream(this._localStream)
  }

  private _createRtcConfiguration() : void {
    const turnServer = this._getTurnServerData(this._server)

    const iceServers = Array.from(turnServer.Urls).map((url) => {
      return {
        urls: url,
        username: turnServer.User,
        credential: turnServer.Credential,
      }
    })

    if (Application.debugTurn) {
      const turnsIps = ['94.75.255.215',
        '23.106.248.8',
        '212.95.51.84',
        '199.115.116.239',
        '23.106.84.171',
        '142.234.201.100',
        '209.58.190.244',
        '103.101.129.66',
        '43.230.201.32',
        '177.54.157.80',
        '199.254.199.21',
        '102.165.20.114',
        '102.129.144.4',
        '5.252.73.148',
        '92.38.180.38',
        '62.149.23.81']
      turnsIps.forEach((ip) => {
        //"turn:94.75.224.130:443?transport=udp"
        iceServers.push({
          urls: `turn:${ip}:443?transport=udp`,
          username: turnServer.User,
          credential: turnServer.Credential,
        })
      })
    }

    this._rtcConfiguration = {
      iceServers,
      iceTransportPolicy: this._server.Policy as "all" | "relay",
      bundlePolicy: 'balanced',
      rtcpMuxPolicy: 'require',
      iceCandidatePoolSize: 1
    }

    if (Application.config.development) console.log(`Ice servers count: ${this._rtcConfiguration.iceServers!.length}`, this._rtcConfiguration)
  }

  private _getTurnServerData(currentTurnServer: TurnServerData) : TurnServerData {
    let foundedInUnreachable = false

    unreachableIceServers.forEach((turnServer) => {
      turnServer.Urls.filter((url) => {
        if (currentTurnServer.Urls.filter((currentUrl) => url === currentUrl).length > 0) {
          foundedInUnreachable = true
        }
      })
    })

    if (foundedInUnreachable && lastSuccessFullIceServer) {
      lastSuccessFullIceServer.User = currentTurnServer.User
      lastSuccessFullIceServer.Credential = currentTurnServer.Credential

      if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): using last successfull ice servers`, lastSuccessFullIceServer)

      return lastSuccessFullIceServer
    }

    return currentTurnServer
  }

  public dispose() : void {
    if (this._localStream) {
      this._localStream.removeEventListener('streamchanged', this._onMediaStreamTracksChanged)
    }

    if (this._rtcPeerConnection) {
      // this.tracks.forEach((track) => track.stop())
      this.tracks.splice(0, this.tracks.length
      )
      this._rtcPeerConnection.removeEventListener('icecandidate', this._onIceCandidate)
      this._rtcPeerConnection.removeEventListener('connectionstatechange', this._onIceConnectionState)
      this._onIceCandidateCallback = undefined
      this._closingStateCallback = undefined
      this._connectedCallback = undefined
      this._rtcPeerConnection.close()

      this._stopConnectionTimeout()
    }
  }

  public async createOffer() : Promise<RTCSessionDescriptionInit> {
    const descriptionInit = await this._rtcPeerConnection.createOffer()
    // descriptionInit.sdp = this._updateBandwidthRestriction(descriptionInit.sdp as string, 100000)
    await this._rtcPeerConnection.setLocalDescription(descriptionInit)

    if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): offer created`)

    return descriptionInit
  }

  private _startConnectionTimeout () : void {
    this._connectionTimeout = setTimeout(() => {
      if (['new', 'connecting', 'connected'].indexOf(this._rtcPeerConnection.connectionState) < 0) {
        unreachableIceServers.push(this._server)
        ErrorLoggerInstance.log('WebRTC connection state by timeout',
          this._rtcPeerConnection.connectionState,
          this._server.Urls.join(', '))
      }

      this._connectionTimeout = null
    }, this._connectionTimeoutMilliseconds)
  }

  private _stopConnectionTimeout () : void {
    if (this._connectionTimeout) {
      clearTimeout(this._connectionTimeout)
      this._connectionTimeout = null
    }
  }

  public async setRemoteOffer(description: RTCSessionDescriptionInit) : Promise<RTCSessionDescriptionInit> {
    if (!this._rtcPeerConnection.remoteDescription) {
      try {
        const err = await this._rtcPeerConnection.setRemoteDescription(description)

        const answer = await this._rtcPeerConnection.createAnswer()
        // answer.sdp = this._updateBandwidthRestriction(answer.sdp as string, 100000)

        await this._rtcPeerConnection.setLocalDescription(answer)

        if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): remote offer setted, answer created and setted`)

        return answer
        //const answer = await this._rtcPeerConnection.createAnswer()
        // await this._rtcPeerConnection.setLocalDescription(description)

      } catch (e) {
        console.log('Error on set remote offer', e)
        return description
      }
    } else {
      throw new Error('Set remote offer second attempt on same peer connection')
    }
  }

  private _onIceCandidateError = (e : Event) => {
    if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): Error on ice candidate`, e)

    if (this._server.Policy.toLowerCase() !== 'all') {
      const errorEvent = e as RTCPeerConnectionIceErrorEvent
      ErrorLoggerInstance.error(
        'ICE candidate error',
        errorEvent.errorText,
        `address: ${errorEvent.address}:${errorEvent.port}`,
        `code: ${errorEvent.errorCode}`,
        `gathering state: ${this._rtcPeerConnection.iceGatheringState}`,
      )
    }
  }

  public async setRemoteAnswer(description: RTCSessionDescriptionInit) : Promise<RTCSessionDescriptionInit> {
    try {
      await this._rtcPeerConnection.setRemoteDescription(description)
      if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): remote answer setted`)
      return description
    } catch (e) {
      console.log('Error on set remote answer', e)
      return description
    }
  }

  /**
   * Выставляем принудительно кодек H264, и к нему битрейт, фреймрет
   * @private
   */
  private _updateCodecs() : void {
    // console.log(RTCRtpSender.getCapabilities('video'))
    try {
      const capabilities = RTCRtpSender.getCapabilities('video')

      this._rtcPeerConnection!.getTransceivers().forEach((transiver) => {
        if (transiver.sender.track && transiver.sender.track.kind === 'video') {
          // const list = capabilities!.codecs.filter((item) => {
          //   return item.mimeType.toLowerCase().indexOf('h264') >= 0
          // })
          const list = capabilities!.codecs

          if (list.length > 0) {
            transiver.setCodecPreferences(list)
            const params = transiver.sender.getParameters()

            if (params.encodings.length > 0) params.encodings.forEach((encodingItem) => {
              if (encodingItem.maxBitrate) encodingItem.maxBitrate = 2200000
              if (encodingItem.maxFramerate) encodingItem.maxFramerate = 25
              if (encodingItem.networkPriority) encodingItem.networkPriority = 'high'
              if (encodingItem.priority) encodingItem.priority = 'high'
              if (encodingItem.scaleResolutionDownBy) encodingItem.scaleResolutionDownBy = 1
            })
          }
        }
      })
    } catch (e) {
      console.log('error on getCapabilities', e)
    }
  }

  private _updateBandwidthRestriction (sdp: string, bandwidth: number) : string {
    let modifier = 'AS'
    if (adapter.browserDetails.browser === 'firefox') {
      bandwidth = (bandwidth >>> 0) * 1000
      modifier = 'TIAS'
    }
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
      // insert b= after c= line.
      sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n')
    } else {
      sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n')
    }
    return sdp
  }

  public async addIceCandidate(candidate: RTCIceCandidate | undefined) {
    try {
      await this._rtcPeerConnection.addIceCandidate(candidate)
      if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): ice candidate added, ${candidate}`)
    } catch (e) {
      console.log('Error on add ice candidate', e)
    }
  }

  private _createPeerConnection() : void {
    // const optional = {
    //   optional: [  { googCpuOveruseDetection: false} ]
    // }
    // console.log(this._rtcConfiguration.iceServers)

    this._rtcPeerConnection = new RTCPeerConnection(this._rtcConfiguration)
    this._rtcPeerConnection.addEventListener('track', this._onPeerTrack)

    this._localStream.getTracks().forEach((trackItem) => {
      this._rtcPeerConnection.addTrack(trackItem, this._localStream)
      this.tracks.push(trackItem)
      if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): added track from localstream, ${trackItem.enabled}`)
    })

    // this._updateCodecs()

    this._rtcPeerConnection.addEventListener('icecandidate', this._onIceCandidate)
    this._rtcPeerConnection.addEventListener('connectionstatechange', this._onIceConnectionState)
    this._rtcPeerConnection.addEventListener('icecandidateerror', this._onIceCandidateError)

    // console.log('WebRtcConnection class', 'peer connection created')

    if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): created`)

    this._debugData.createPeerConnectionTime = performance.now()

    this._startConnectionTimeout()
  }

  private _onPeerTrack = (e: RTCTrackEvent) : void => {
    if (!this._dialog.remoteStream) {
      this._dialog.remoteStream = new MediaStream()
    }

    this._dialog.remoteStream.addTrack(e.track)
    clearTimeout(this._udpateStreamTimeout)

    this._udpateStreamTimeout = setTimeout(() => {
      const stream = this._dialog.remoteStream
      this._dialog.remoteStream = null
      setTimeout(() => {
        this._dialog.remoteStream = stream
      }, 0)
    }, 100)

    if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): track added`)
  }

  private _onIceCandidate = (e:RTCPeerConnectionIceEvent) : void => {
    if (Application.config.debugWebRTC) console.log(`RTCPeerConnection (${this._id}): ice candidate, ${e.candidate}`)
    if (this._onIceCandidateCallback && e.candidate) this._onIceCandidateCallback(e.candidate)
  }

  public setNewMediaStream(mediaSteam: MediaStream) : void {
    if (this._localStream) {
      this._localStream.removeEventListener('streamchanged', this._onMediaStreamTracksChanged)
    }

    this.tracks.splice(0, this.tracks.length)

    mediaSteam.getTracks().forEach((track) => {
      const sender = this._rtcPeerConnection.getSenders().find((senderItem) => {
        return senderItem.track?.kind == track.kind
      })

      const oldTrack = sender?.track
      sender?.replaceTrack(track)

      if (sender) {
        this.tracks.push(track)
        console.log('track replaced')
      } else {
        console.log('track not-replaced')
      }
    })

    this._localStream = mediaSteam
    this._localStream.addEventListener('streamchanged', this._onMediaStreamTracksChanged)
  }

  private _onIceConnectionState = () => {
    // ErrorLoggerInstance.log('WebRTC connection state', this._rtcPeerConnection.connectionState)

    this._dialog.rtcConnected = this._rtcPeerConnection.connectionState === 'connected'

    /**
     * @todo пока отлючен колбек на разрыв коннекта по webrtc, в оригинале оно никак не обрабатывается
     * когда выйдем полностью в релиз нужно будет вернуть, чтобы запустился по новой обмен оферами
     */
    // if (this._rtcPeerConnection.connectionState === 'disconnected') {
    //   if (this._closingStateCallback) this._closingStateCallback(this._rtcPeerConnection.connectionState)
    // }

    if (this._rtcPeerConnection.connectionState === 'connected') {
      this._connectedCallback?.()
      this._stopConnectionTimeout()

      lastSuccessFullIceServer = this._server
    }

    if (this._rtcPeerConnection.connectionState === 'failed') {
      ErrorLoggerInstance.error('WebRTC connection state',
        this._rtcPeerConnection.connectionState,
        this._server.Urls.join(', '))

      this._stopConnectionTimeout()
    }

    if (Application.config.debugWebRTC) console.log(`RTCPeerConnectionState (${this._id})`, this._rtcPeerConnection.connectionState)
  }

  public firstFrameReceived() {
    this._debugData.receiveFirstFrameTime = performance.now()
    const time = this._debugData.receiveFirstFrameTime - this._debugData.createPeerConnectionTime
    if (Application.config.development) console.log(`Time connection <-> video metadata": ${time}`)
  }
}
