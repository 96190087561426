import {CoomeetChat} from '../../CoomeetChat.class'
import WebSocketApi, {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'

export default class Scope {
  constructor(protected _coomeetChatInstance: CoomeetChat, protected _webSocketApi: WebSocketApi) {
    setTimeout(() => {
      this._init()
    }, 0)
  }

  protected _init() {}

  public sendCommand(command: any, data: any, blobOptions: {type: string} | null = null) : Promise<CommandPromiseArguments> {
    return this._webSocketApi.sendCommand(command, data, blobOptions)
  }

  public processData(scopeData: WebSocketApiScopeData) : void {}
}
