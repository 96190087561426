enum ScopeVideoCommands {
  VideoCall = 'VideoCall',
  VideoCallCancel = 'VideoCallCancel',
  VideoCallFinish = 'VideoCallFinish',
  VideoCallAccept = 'VideoCallAccept',
  VideoCallRequest = 'VideoCallRequest',
  VideoCallDenied = 'VideoCallDenied',
  SearchFind = 'SearchFind',
  SearchNext = 'SearchNext',
  SearchStop = 'SearchStop',
  UserOffer = 'UserOffer',
  UserAnswer = 'UserAnswer',
  UserIce = 'UserIce',
  StopChat = 'StopChat',
  ChatClose = 'ChatClose',
  ChatSendMessage = 'ChatSendMessage',
  RealtimeMessage = 'RealtimeMessage',
  OnlineMessageTranslate = 'OnlineMessageTranslate',
  ChatGift = 'ChatGift',
  SendVote = 'SendVote',
  SendComment = 'SendComment',
  SearchSetting = 'SearchSetting',
  SearchFilterOnlyBest = 'SearchFilterOnlyBest',
  SearchStart = 'SearchStart',
  VideoSearchRequest = 'VideoSearchRequest',
  SearchQueue = 'SearchQueue',
  FaceDetect = 'FaceDetect',
  History = 'History',
  HistoryDeleteUser = 'HistoryDeleteUser',
  HistoryClear = 'HistoryClear',
  UpdateAfterChat = 'UpdateAfterChat',
  LoadedData = 'LoadedData',
  RouteChange = 'RouteChange',
  GiftInfo = 'GiftInfo',
  UserFaceDetect = 'UserFaceDetect',
  PromoStart = 'PromoStart',
  GetTemplate = 'GetTemplate',
  RemoveTemplate = 'RemoveTemplate',
  SendPromoMessage = 'SendPromoMessage',
  WaitRestore = 'WaitRestore',
  CancelWaitRestore = 'CancelWaitRestore',
  WaitRestoreChat = 'WaitRestoreChat',
  CancelRestore = 'CancelRestore',
  RestoreChat = 'RestoreChat',
  WaitRestoreFailed = 'WaitRestoreFailed',
  ChangeScore = 'ChangeScore'
}

export default ScopeVideoCommands
