<template lang="pug">
.auth-social-networks
  .auth-social-networks__item(
    v-for="(network, index) in socialNetworksList",
    :class="getItemCssClass(network, index)",
    @click="onSocialNetworkClick(network, index)")
</template>
<script lang="ts" setup>
import socialNetworks, {SocialNetwork} from '../../../../common/classes/SocialNetworks.class'
import {computed} from 'vue'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'

const user = CoomeetChatInstance.user

const socialNetworksList = computed(() => {
  return user.value.settings?.NetWork
})

const onSocialNetworkClick = (network: SocialNetwork) => {
  socialNetworks.auth(network, user.value)
}

const getItemCssClass = (network: SocialNetwork, index: number) => {
  const classesList = [network.toString()]

  if (index < 2) classesList.push('big')
  if (socialNetworksList.value!.length - 1 === index) classesList.push('last-child')
  return classesList
}

</script>
