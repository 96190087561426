import Scope from "./Scope.class";
import {CommandPromiseArguments, WebSocketApiScopeData} from "../WebSocketApi.class";
import DialogModel from "../../models/DialogModel.class";
import ScopeModerationCommands from "./ScopeModeration/commands";
import {DialogModelModerationStatus} from "../../models/DialogModel/interfaces";

export default class ScopeModeration extends Scope {
  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      default:
        break
    }
  }

  public moderationGood(dialog?: DialogModel) : Promise<CommandPromiseArguments> {
    const data = dialog ? {id: dialog.id} : undefined
    return this.sendCommand(ScopeModerationCommands.ModerationGood, data).then(({command, response}) => {
      if (dialog) {
        dialog.moderation_status = DialogModelModerationStatus.Good
      } else if (this._coomeetChatInstance.currentVideoDialog.value) {
        this._coomeetChatInstance.currentVideoDialog.value!.moderation_status = DialogModelModerationStatus.Good
        this._coomeetChatInstance.currentVideoDialog.value!.checkDate = Math.round(Date.now() / 1000)
      }
      return {command, response}
    })
  }

  public moderationBad(dialog?: DialogModel) : Promise<CommandPromiseArguments> {
    const data = dialog ? {id: dialog.id} : undefined
    return this.sendCommand(ScopeModerationCommands.ModerationBad, data).then(({command, response}) => {
      if (dialog) {
        dialog.moderation_status = DialogModelModerationStatus.Bad
      } else if (this._coomeetChatInstance.currentVideoDialog.value) {
        this._coomeetChatInstance.currentVideoDialog.value!.moderation_status = DialogModelModerationStatus.Bad
        this._coomeetChatInstance.currentVideoDialog.value!.checkDate = null
      }
      return {command, response}
    })
  }
}
