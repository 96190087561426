<template lang="pug">
.ui-select(:class="{opened: optionsVisible, error: !!slots.error}", ref="selectEl")
  .ui-select-selected-value(@click="toggleVisibilty", :class="{disabled: props.modelValue.disabled}")
    .ui-select-selected-value__icon(v-if="slots.icon")
      slot(name="icon")
    .ui-select-selected-value__label {{props.modelValue.label}}
    .ui-select-selected-value__arrow
      SelectArrow
  .placeholder.top(:class="{top: !!props.modelValue}", v-if="props.placeholder")
    .placeholder__line
    .placeholder__label(v-html="props.placeholder")
  .error-text(:class="{visible: !!slots.error}")
    .error-text__line
    .error-text__content
      slot(name="error")
  teleport(to="body")
    .ui-select-options-list(v-show="optionsVisible", ref="optionsListEl")
      UiScroll(:style="{height: scrollHeight}")
        .ui-select-option-item(v-for="(optionItem, index) in props.options.filter((item) => !item.disabled)",
          :class="{active: props.modelValue?.id === optionItem.id}",
          :key="index",
          @click="onOptionItemClick(optionItem)")
          .ui-select-option-item__bg
          .ui-select-option-item__label {{optionItem.label}}
</template>
<script lang="ts" setup>
import SelectArrow from '@/assets/images/icons/select-arrow.svg'
import {ref, nextTick, computed, onMounted, onUnmounted, useSlots} from 'vue'
import UiScroll from '../../../components/ui/Scroll/UiScroll.vue'
import router from '../../../router'
import WindowEvent from '../../../common/events/window'

export interface UISelectOption {
  id: string | number,
  label: string,
  data?: any,
  disabled?: boolean
}

const props = defineProps<{
  placeholder?: string,
  modelValue: UISelectOption,
  options: UISelectOption[],
}>()

const slots = useSlots()

const emit = defineEmits(['update:modelValue', 'select'])

const optionsVisible = ref(false)
const selectEl = ref<HTMLDivElement | null>(null)
const optionsListEl = ref<HTMLDivElement | null>(null)

const scrollHeight = computed(() => {
  const optionsCount = props.options.filter((item) => !item.disabled).length
  return optionsCount < 5 ? `${optionsCount * 46}px` : `${5 * 46}px`
})

const updatePosition = () => {
  if (optionsVisible && selectEl.value && optionsListEl.value) {
    const selectElement = selectEl.value!
    const optionsElement = optionsListEl.value!

    const selectRect = selectElement.getBoundingClientRect()
    const optionsRect = optionsElement.getBoundingClientRect()

    let topPosition = selectRect.top + selectRect.height
    if (topPosition + optionsRect.height > window.innerHeight) {
      topPosition = selectRect.top - optionsRect.height
    }
    optionsElement.style.top = `${topPosition}px`
    optionsElement.style.left = `${selectRect.left}px`
    optionsElement.style.width = `${selectRect.width}px`
  }
}

const onOptionItemClick = (optionItem: UISelectOption) => {
  optionsVisible.value = false
  if (optionItem.id !== props.modelValue.id) emit('select', optionItem)
  emit('update:modelValue', optionItem)
}

const toggleVisibilty = () => {
  optionsVisible.value = !optionsVisible.value
  if (optionsVisible.value) {
    nextTick(() => {
      updatePosition()
    })
  }
}

const onDocumentClick = (e: MouseEvent) => {
  const target = e.target as HTMLElement
  if (target.closest('.ui-select') !== selectEl.value! as HTMLElement) {
    optionsVisible.value = false
  }
}

const onHideUiSelect = () => {
  optionsVisible.value = false
}


onMounted(() => {
  window.addEventListener('resize', updatePosition)
  document.addEventListener('click', onDocumentClick)
  window.addEventListener(WindowEvent.HideUiSelect, onHideUiSelect)
})

onUnmounted(() => {
  window.removeEventListener('resize', updatePosition)
  document.removeEventListener('click', onDocumentClick)
  window.removeEventListener(WindowEvent.HideUiSelect, onHideUiSelect)
})

</script>
