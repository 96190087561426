<template lang="pug">
.push-notifications(v-show="localesInited")
  template(v-if="requiredUserContext")
    .push-notifications__title {{t('APP_DESKTOP_NOTIFICATIONS')}}
    .push-notifications__description {{t('APP_NOTIFICATIONS_PERMISSION_REQUIRED')}}
    .push-notifications__actions
      UiSimpleButton.size-46.color-pink(@click="onEnableNotificationsClick") {{t('APP_TURN_ON')}}
  template(v-else-if="receivingSubscription")
    .push-notifications__description {{t('A_WAITING')}}
  template(v-else-if="!notificationsEnabled")
    .push-notifications__title {{t('APP_DESKTOP_NOTIFICATIONS')}}
    .push-notifications__description {{t('APP_NOTIFICATIONS_PERMISSION_REQUIRED')}}
    .push-notifications__actions
      UiSimpleButton.size-46.color-pink(@click="onCloseClick") {{t('A_CLOSE')}}
  template(v-else)
    .push-notifications__icon
      BellIcon
    .push-notifications__title {{t('APP_NOTIFICATIONS_ENABLED')}}
    .push-notifications__description {{t('APP_CAN_CLOSE_WINDOW')}}
    .push-notifications__actions
      UiSimpleButton.size-46.color-pink(@click="onCloseClick") {{t('A_CLOSE')}}
</template>
<script lang="ts" setup>
import BellIcon from '@/assets/images/icons/bell-with-check-size-60.svg'
import pushNotification from '../common/classes/PushNotification.class'
import UiSimpleButton from '../components/ui/Buttons/UiSimpleButton.vue'
import {ref, computed, onUnmounted, watchEffect} from 'vue'
import {detect} from 'detect-browser'
import {useI18n} from 'vue-i18n'
import LocalizationInstance from '../common/classes/Localization.class'
import {useRoute} from "vue-router";

const {t} = useI18n()

const browser = detect()
const route = useRoute()

let parentWindow : Window | null = null
const notificationsEnabled = ref(false)
const receivingSubscription = ref (false)
const requiredUserContext = ref(['ios', 'safari', 'firefox'].indexOf(browser?.name as string) >= 0)

let vapidValue = route.query.vapid as string??''
let withBonus = route.query.withBonus === 'true';

const localesInited = computed(() => LocalizationInstance.inited.value)

const initNotifications = async (vapid: string) => {
  vapidValue = vapid
  if (!requiredUserContext.value) {
    pushNotification.setVapid(vapid)
    const permission = await pushNotification.requestPermission()

    if (permission === 'granted') {
      try {
        receivingSubscription.value = true
        await pushNotification.registerServiceWorker()
        if (pushNotification.subscription) {
          (parentWindow || window.opener)!.postMessage({
            cmd: 'subscription',
            data: {
              code: JSON.stringify(pushNotification.subscription),
              withBonus
            }
          }, '*')
        }

        receivingSubscription.value = false
        notificationsEnabled.value = true
      } catch (e) {
        receivingSubscription.value = false
        notificationsEnabled.value = false
      }
    }
  } else {

  }
}

const onEnableNotificationsClick = () => {
  requiredUserContext.value = false
  initNotifications(vapidValue)
}

const onCloseClick = () => {
  window.close()
}

const onMessage = (e: MessageEvent) => {
  parentWindow = e.source as Window
  const {cmd, data} = e.data

  switch (cmd) {
    case 'vapid':
      initNotifications(data.vapid)
      withBonus = data.withBonus
      break

    case 'subscribed':
      notificationsEnabled.value = true
      break
  }
}

if (vapidValue !== '') {
  initNotifications(vapidValue)
}

window.addEventListener('message', onMessage)

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
})
</script>
