import UserModel from '../../models/UserModel.class'
import ScopeUserCommands from './ScopeUser/commands'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import Scope from './Scope.class'
import DialogModel from '../../models/DialogModel.class'
import {DialogModelDelete} from '../../models/DialogModel/interfaces'
import Application from '../../../Application.class'
import {ConnectionState, ContactsListRule} from '../../CoomeetChat.class'
import {UserModelAvatar, UserModelGender} from '../../models/UserModel/interfaces'
import StoryModel from '../../models/StoryModel.class'
import FingerPrintInstance from '../../../Fingerprint.class'
import ErrorLoggerInstance from '../../../ErrorLogger.class'
import iframeMessenger, {IFrameCommand} from '../../../IframeMessenger.class'
import LocalizationInstance from '../../../Localization.class'
import applicationCache from '../../../ApplicationCache.class'

export default class ScopeUser extends Scope {
  private get _userModel () { return this._coomeetChatInstance.user }

  //{"event":{"Cmd":"AccountValidationStatus","Cid":""},"data":{"ban":{"AccountCheck":"AccountValidationNoFace","Ban":6,"BanExpired":1994936787,"Duration":315359444}}}
  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      case ScopeUserCommands.Authorization:
      case ScopeUserCommands.Update:
        const userModel = UserModel.FromJSON(scopeData.data)

        if (userModel.gender === UserModelGender.Female && Application.config.appVersion === 'male') {
          if (iframeMessenger.active) {
            Application.hash = scopeData.data.hash
            iframeMessenger.sendMessage({
              cmd: IFrameCommand.SwitchApp,
              data: {
                maleVersion: false
              }
            })
          } else {
            Application.clearHash()
            const params = new URLSearchParams(window.location.search)
            //params.set('hash', scopeData.data.hash)
            params.set('locale', LocalizationInstance.locale.value)
            //@ts-ignore
            const url = `${Application.config.webApps.female}?${params.toString()}`
            Application.replaceLocation(url)
          }
          return
        } else if (userModel.gender === UserModelGender.Male && Application.config.appVersion === 'female') {
          if (iframeMessenger.active) {
            Application.hash = scopeData.data.hash
            iframeMessenger.sendMessage({
              cmd: IFrameCommand.SwitchApp,
              data: {
                maleVersion: true
              }
            })
          } else {
            Application.clearHash()
            const params = new URLSearchParams(window.location.search)
            //params.set('hash', scopeData.data.hash)
            params.set('locale', LocalizationInstance.locale.value)
            //@ts-ignore
            const url = `${Application.config.webApps.male}?${params.toString()}`
            Application.replaceLocation(url)
          }
          return
        } else {
          if (scopeData.data.hash) Application.hash = scopeData.data.hash
        }

        if (scopeData.event.Cmd === ScopeUserCommands.Authorization) {
          applicationCache.init()
          this._coomeetChatInstance.initialContactsReceived.value = true

          this._coomeetChatInstance.connectionState.value = ConnectionState.Connected
          this._coomeetChatInstance.info()

          this._coomeetChatInstance.clearVideoChatState()
          this._coomeetChatInstance.closeLocalMediaStream()

          this._coomeetChatInstance.removeAllDialogsFromAllLists()
          this._coomeetChatInstance.dialogsHistory.clear()

          this._coomeetChatInstance.outgoingCall.value = null
          this._coomeetChatInstance.incomingCall.value = null

          if (userModel.id !== this._userModel.value?.id) {
            if (this._userModel.value.id >= 0) applicationCache.clear()
            this._webSocketApi.clearPendingCommands()
            this._coomeetChatInstance.currentContactsListRule.value = ContactsListRule.Last
          } else {
            setTimeout(() => {
              this._webSocketApi.resendPendingCommands()
            }, 1000)
          }
        }

        this._userModel.value = userModel
        ErrorLoggerInstance.setUser(userModel)

        if (scopeData.data.isFingerprintRequired) {
          FingerPrintInstance.getToken().then((token) => {
            if (token) {
              this._coomeetChatInstance.fingerPrint(token)
            } else {
              console.log('Fingerprint failed')
              ErrorLoggerInstance.error('Failed to get fingerprint', token)
            }
          })
        }

        break

      case ScopeUserCommands.AccountValidationStatus:
      case ScopeUserCommands.SetBan:
        if (scopeData.data.ban && scopeData.data.ban.Ban === 0) {
          this._userModel.value.ban = null
        } else {
          this._userModel.value.ban = scopeData.data.ban
        }
        break

      case ScopeUserCommands.Abuse:
        const dialogId = scopeData!.data.id
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)
        if (dialog) {
          this._coomeetChatInstance.historyDeleteUser(dialog)
          this._coomeetChatInstance.removeDialogFromAllLists(dialog)
        }
        break

      case ScopeUserCommands.RemoveBan:
        this._userModel.value.ban = null
        break

      //{"event":{"Cmd":"UserCardCheck","Cid":""},"data":{"BlockCard":{"Status":"waite","Number":"553691******8873","Currency":"RUB","CaptureId":139579,"Digit":1}}}
      case ScopeUserCommands.UserCardCheck:
        if (scopeData.data.BlockCard) {
          this._coomeetChatInstance.user.value.BlockCard = scopeData.data.BlockCard
        }
        break

      case ScopeUserCommands.Referral: {
        if (scopeData.data) {
          for (let contactId in scopeData.data) {
            let contactData = {
              ID: parseInt(contactId),
              ...scopeData.data[contactId]
            }
            const dialog = DialogModel.FromJSON(contactData)
            this._coomeetChatInstance.dialogsList.addModel(dialog)
          }
        }
      }
        break

      case ScopeUserCommands.MailConfirm: {
        //{"event":{"Cmd":"MailConfirm","Cid":""},"data":{"isConfirmEmail":2,"sendEmailBlocked":0,"needConfirmEmail":0,"changeEmailStatus":0,"ageRange":2,"email":"padepo2744@momoshe.com","changeEmail":""}}
        this._userModel.value.isConfirmEmail = scopeData.data.isConfirmEmail
        this._userModel.value.needConfirmEmail = scopeData.data.needConfirmEmail
        this._userModel.value.changeEmailStatus = scopeData.data.changeEmailStatus
        this._userModel.value.changeEmail = scopeData?.data.changeEmail
        this._userModel.value.ageRange = scopeData?.data.ageRange
        this._userModel.value.email = scopeData?.data.email

        if (!scopeData.data.needConfirmEmail) {
          this._userModel.value.email = (scopeData?.data.changeEmail??'') === '' ? scopeData?.data.email : scopeData?.data.changeEmail
          this._userModel.value.changeEmail = ''
        }
      }
        break

      case ScopeUserCommands.AddBonus:
      case ScopeUserCommands.PromoBonus:
        if (scopeData.data.bonus?.score !== undefined)  {
          this._userModel.value.score = scopeData.data.bonus.score
        }
        if (scopeData.data.paidGroup !== undefined) this._userModel.value.paidGroup = scopeData.data.paidGroup
        if (scopeData.data.paidMinutes !== undefined) this._userModel.value.paidMinutes = scopeData.data.paidMinutes

        break
    }
  }

  public login({email, password} : {email: string, password: string}) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.useCache.value = true
    return this.sendCommand(ScopeUserCommands.Login, {
      email: email,
      password: password
    })
  }

  public oneTap(idToken: string) : void {
    this.sendCommand(ScopeUserCommands.OneTap, {
      IdToken: idToken
    })
  }

  public restore(email: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.Restore, {
      email: email
    })
  }

  public logout() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.Logout, {})
  }

  public registration({email, password} : {email: string, password: string}) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.Registration, {
      email,
      password
    })
  }

  public registrationComplete({agerange, username} : {agerange: number, username: string}) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.RegistrationComplete, {
      agerange,
      username
    })
  }

  public mailConfirm(email: string, source: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.MailConfirm, {
      email,
      source
    })
  }

  public confirmCode(email: string, code: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ConfirmCode, {
      email,
      code
    })
  }

  public acceptTerms() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.AcceptTerms, {})
  }

  public changeUsername(name: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangeUsername, {
      name
    }).then(({command, response}) => {
      this._coomeetChatInstance.user.value.username = name
      return {command, response}
    })
  }

  public changePassword(password: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangePassword, {
      password
    })
  }

  public changePersonal(email: string, agerange: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangePersonal, {
      email,
      agerange
    }).then(({command, response}) => {
      this._userModel.value.isConfirmEmail = response?.data.isConfirmEmail
      this._userModel.value.needConfirmEmail = response?.data.needConfirmEmail
      this._userModel.value.changeEmailStatus = response?.data.changeEmailStatus
      this._userModel.value.changeEmail = response?.data.changeEmail
      this._userModel.value.email = response?.data.email
      this._userModel.value.ageRange = response?.data.ageRange
      return {command, response}
    })
  }

  public cancelChangeEmail() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.CancelChangeEmail, {}).then(({command, response}) => {
      this._coomeetChatInstance.user.value.changeEmail = response?.data.changeEmail
      this._coomeetChatInstance.user.value.changeEmailStatus = response?.data.changeEmailStatus
      this._coomeetChatInstance.user.value.email = response?.data.email
      this._coomeetChatInstance.user.value.isConfirmEmail = response?.data.isConfirmEmail
      this._coomeetChatInstance.user.value.needConfirmEmail = response?.data.needConfirmEmail

      return {command, response}
    })
  }

  public changeNotification({
    NoticeUserMessage,
    NoticeSystemNew,
    NoticeGifts,
    NoticePartnerNews,
    NoticeUserInvite,
    NoticeBonusMinutesAddition
  } : {
    NoticeUserMessage?: number,
    NoticeSystemNew?: number,
    NoticeGifts?: number,
    NoticePartnerNews?: number,
    NoticeUserInvite?: number,
    NoticeBonusMinutesAddition?: number
  }) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangeNotification, {
      NoticeUserMessage,
      NoticeSystemNew,
      NoticeGifts,
      NoticePartnerNews,
      NoticeUserInvite,
      NoticeBonusMinutesAddition
    })
  }

  public changePrivacy(ShowAnyContent: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangePrivacy, {
      ShowAnyContent
    })
  }

  public makeAvatar(base64: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.MakeAvatar, {
      byte: base64
    }, { type: 'image/png' }).then(({command, response}) => {
      this._coomeetChatInstance.user.value.avatar = response?.data.avatar as UserModelAvatar
      return {command, response}
    })
  }

  public removeAvatar() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.RemoveAvatar, {}).then(({command, response}) => {
      this._coomeetChatInstance.user.value.avatar = response?.data.avatar as UserModelAvatar
      return {command, response}
    })
  }

  public accountDelete() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.AccountDelete, {})
  }

  public accountDeleteConfirm(code: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.AccountDeleteConfirm, {
      code
    })
  }

  public accountDeleteReason(msg: string, code: string) : Promise<CommandPromiseArguments> {
    this.sendCommand(ScopeUserCommands.AccountDeleteReason, {
      msg,
      code
    })

    return Promise.resolve({
      command: null,
      response: null
    })
  }

  public abuse(dialog: DialogModel & StoryModel, type: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.Abuse, {
      id: dialog.womanId||dialog.id,
      type
    }).then(({command, response}) => {
      this._coomeetChatInstance.currentDialog.value = null
      this._coomeetChatInstance.historyDeleteUser(dialog)
      this._coomeetChatInstance.removeDialogFromAllLists(dialog)
      this._coomeetChatInstance.dialogsHistory.removeModel(dialog)
      return {command, response}
    })
  }

  public blockUser(dialog: DialogModel, block: boolean) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.BlockUser, {
      id: dialog.id,
      type: block ? 1 : 0
    }).then(({command, response}) => {
      dialog.delete = block ? DialogModelDelete.IamBlocked : 0
      if (!block) this._coomeetChatInstance.setCurrentDialog(dialog)

      if (block) {
        this._coomeetChatInstance.dialogsHistory.removeModel(dialog)
        this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Blocked)!.addModel(dialog)
      } else {
        this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Blocked)!.removeModel(dialog)
        dialog.clearMessages()

        this._coomeetChatInstance.requestDialogById(dialog.id).then((requestedDialog) => {
          dialog.setData(JSON.parse(requestedDialog?.toJson()))
          this._coomeetChatInstance.markDialogAsRead(dialog)
        })
      }
      return {command, response}
    })
  }

  public userCardCheck() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.UserCardCheck, {})
  }

  public userCardConfirm(CaptureId: string, amount: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.UserCardConfirm, {
      CaptureId,
      amount
    })
  }

  public userCardCheckCancel() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.UserCardCheckCancel, {})
  }

  public paymentHistory() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.PaymentHistory, {})
  }

  public referrals(limit: number, skip: number, type: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.Referrals, {
      limit: limit,
      skip: skip,
      type: type
    })
  }

  public webPushViewMsg(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.WebPushViewMsg, {
      type: enabled ? 1 : 0
    })
  }

  public webPushShow(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.WebPushShow, {
      type: enabled ? 1 : 0
    })
  }

  public changeTranslationSettings(enabled: boolean, lang: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangeTranslationSettings, {
      isEnabled: enabled ? 1 : 0,
      lang
    }).then(({command, response}) => {
      this._coomeetChatInstance.user.value.settings!.TranslateLang = lang
      this._coomeetChatInstance.user.value.settings!.TranslateIn = enabled ? 1 : 0
      return {command, response}
    })
  }

  public subscribe(code: string, domain: string, webPushBonus: boolean, filter = 1) : Promise<CommandPromiseArguments> {
    if (code) {
      return this.sendCommand(ScopeUserCommands.Subscribe, {
        code,
        domain,
        webPushBonus: webPushBonus ? 1 : 0,
        filter
      }).then(({command, response}) => {
        this._userModel.value.webPushSubscribe = 1
        this._userModel.value.webPushShow = 1
        this._userModel.value.webPushViewMsg = 1
        return {command, response}
      })
    } else {
      return Promise.reject('Push notification, no code for subscribe request')
    }
  }

  public unSubscribe(code: string, domain: string, webPushBonus: boolean, filter = 4) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.UnSubscribe, {
      code,
      domain,
      webPushBonus: webPushBonus ? 1 : 0,
      filter
    }).then(({command, response}) => {
      this._userModel.value.webPushShow = 0
      this._userModel.value.webPushViewMsg = 0
      return {command, response}
    })
  }

  public webPushAfterBlock(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.WebPushAfterBlock, {
      type: enabled ? 1 : 0
    }).then(({command, response}) => {
      this._userModel.value.webPushAfterBlock = enabled ? 1 : 0
      return {command, response}
    })
  }

  public accountVerification(byte: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.AccountVerification, {
      byte
    }, {type: this._coomeetChatInstance.videoMimeType})
      .then(({command, response}) => {
        return {command, response}
      })
  }

  public changeLang(lang: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.ChangeLang, {
      lang
    }).then(({command, response}) => {
      if (this._userModel.value.settings?.TranslateLang !== lang) {
        this._userModel.value.settings!.TranslateLang = lang
        this._coomeetChatInstance.changeTranslationSettings(!!this._userModel.value.settings!.TranslateIn, lang)
      }
      return {command, response}
    })
  }

  public workStat() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeUserCommands.WorkStat, null)
  }
}
