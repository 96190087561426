<template lang="pug">
#application-wrapper(:class="{android, ios, fullscreen, chrome}")
  template(v-if="(isPushRoute || is404Route) && LocalizationInstance.inited")
    router-view
  template(v-else)
    CoometChat(v-if="ready")
    Intro(v-if="!ready")
    GenderOverlaySelect(v-if="!genderSelectedInOverlay && languageLoaded", @selected="onGenderSelected")
</template>

<script setup lang="ts">
import Intro from './components/Intro.vue'
import GenderOverlaySelect from './components/GenderOverlaySelect.vue'
import {computed, defineAsyncComponent, nextTick, onUnmounted, ref, watch, watchEffect} from 'vue'
import {detect} from 'detect-browser'
import Application from './common/classes/Application.class'
import CoomeetChatInstance, {ConnectionState} from './common/classes/CoomeetChat/CoomeetChat.class'
import {Popups, usePopupOverlayStore} from './stores/popupOverlay'
import {useRoute, useRouter} from 'vue-router'
import pushNotification, {PushNotificationEvents} from './common/classes/PushNotification.class'
import {restoreChatStoreFromLocalStorage, useChatStore} from './stores/chat'
import LocalizationInstance from './common/classes/Localization.class'
import locales from './common/lists/locales'
import {useApplicationStore} from './stores/application'
import iframeMessenger, {
  IFrameCommand,
  IframeMessengerEvent,
  MessengerEvent
} from './common/classes/IframeMessenger.class'
import {UserModelGender} from './common/classes/CoomeetChat/models/UserModel/interfaces'
import soundsPlayer from "./common/classes/SoundsPlayer.class";
import browserStorage, {BrowserStorageKeys} from "./common/classes/CoomeetChat/BrowserStorage.class";
import ErrorLoggerInstance from './common/classes/ErrorLogger.class'

const coomeetChatPromise = import('./components/CoomeetChat.vue').then((data) => {
  chatComponentLoaded.value = true
  return data
})
const CoometChat = defineAsyncComponent(() => coomeetChatPromise)

const route = useRoute()
const router = useRouter()
const appStore = useApplicationStore()
const popupStore = usePopupOverlayStore()
const chatStore = useChatStore()

const chatComponentLoaded = ref(false)
const inited = ref(false)
const iframeInited = ref(!iframeMessenger.active)

let referralLinkInited = false
let storyId: number | null = null


const currentUser = CoomeetChatInstance.user
const initialContactsReceived = CoomeetChatInstance.initialContactsReceived

const genderSelectedInOverlay = ref(!!Application.hash)

const browser = detect()
const android = ref((browser?.os as string).toLowerCase().indexOf('android') >= 0)
const ios = ref((browser?.os as string).toLowerCase().indexOf('ios') >= 0)
const chrome = ref(browser?.name.toLowerCase() === 'crios')
const fullscreen = ref(window.innerHeight === screen.height)

Application.android = android.value
Application.ios = ios.value

const isPushRoute = computed(() => route.name === 'PushNotification')
const is404Route = computed(() => route.name === 'Error404')

const ready = computed(() =>
    inited.value &&
    currentUser.value.id &&
    initialContactsReceived.value &&
    languageLoaded.value &&
    chatComponentLoaded.value &&
    iframeInited.value)

const languageLoaded = computed(() => LocalizationInstance.inited.value)


document.documentElement.style.setProperty('--documentHeight', `${window.innerHeight}px`);
document.documentElement.style.setProperty('--viewportHeight', `${(window.visualViewport?.height??0)}px`);

window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--documentHeight', `${window.innerHeight}px`);
})

window.visualViewport?.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--viewportHeight', `${(window.visualViewport?.height??0)}px`);
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 0)
})

const initCoomeetChat = () => {
  if (!inited.value) {
    // if (route.query.locale && !LocalizationInstance.inited.value) {
    //   LocalizationInstance.setLocale((route.query.locale ?? 'en') as string)
    // }

    if (!isPushRoute.value) {
      CoomeetChatInstance.init()
      inited.value = true
      pushNotification.addEventListener(PushNotificationEvents.Dialog, onNotificationDialog)
    }
  }
}

const onGenderSelected = () => {
  initCoomeetChat()
  genderSelectedInOverlay.value = true
}

const onNotificationDialog = (e: Event) => {
  chatStore.closeVideoChat()
  const {userId, receiverId} = (e as CustomEvent).detail

  if (parseInt(receiverId) !== currentUser.value.id) {
    popupStore.showPopup(Popups.ChangeOfAccount)
  } else {
    router.push(`/dialog/${userId}`)
  }
}

const onMessage = async (e: MessageEvent) => {
  const {cmd, data} = e.data

  switch (cmd) {
    case 'subscription': {
      try {
        if (CoomeetChatInstance.user.value.config?.vapid) {
          if (!pushNotification.subscription) await pushNotification.registerServiceWorker()

          if (pushNotification.subscription) {
            const code = JSON.stringify(pushNotification.subscription)

            CoomeetChatInstance.subscribe(code, location.host, data.withBonus)?.then(() => {
              (e.source as Window)!.postMessage({
                cmd: 'subscribed'
              })

              chatStore.enablePushNotifications()
            }).catch(() => {
              ErrorLoggerInstance.error('Push notifications, no code for subscribe request', pushNotification.subscription, code)
            })
          }
        }
      } catch (e) {
        console.log('Error on register service worker', e)
      } finally {
        // window.removeEventListener('message', onMessage)
      }
    }
      break
  }
}

const onDocumentContextMenu = (e: Event) => {
  const tagName = (e.target as HTMLElement).tagName.toLowerCase()
  const blockRightClickOnTags = ['video', 'img']
  if (blockRightClickOnTags.indexOf(tagName) >= 0) e.preventDefault()
}

window.addEventListener('message', onMessage)
document.addEventListener('contextmenu', onDocumentContextMenu)

onUnmounted(() => {
  document.removeEventListener('contextmenu', onDocumentContextMenu)
  window.removeEventListener('message', onMessage)
  CoomeetChatInstance.dispose()
  inited.value = false
})

if (iframeMessenger.active) {
  iframeMessenger.sendReady()
} else {
  restoreChatStoreFromLocalStorage()
}

iframeMessenger.addEventListener(MessengerEvent.LocaleChange, (e: IframeMessengerEvent) => {
  LocalizationInstance.setLocale(e.data)
})

iframeMessenger.addEventListener(MessengerEvent.ParseUrl, (e: IframeMessengerEvent) => {
  const {path, query} : {path: string, query: string} = e.data

  const params = new URLSearchParams(query)
  const gender = params.get('utm_source')

  storyId = params.get('story') ? parseInt(params.get('story')!) : null

  if (gender) {
    currentUser.value.gender = gender === 'male' ? UserModelGender.Male : UserModelGender.Female
    onGenderSelected()
  }

  const splitPath = path.split('/')
  if (splitPath.length > 0) {
    const referralPart = splitPath[splitPath.length - 1]
    if (referralPart.indexOf('id') >= 0) {
      const id = parseInt(referralPart.replaceAll('id',''))
      Application.setReferralUserId(id)
    }
  }
})

iframeMessenger.addEventListener(MessengerEvent.StorageData, (e: IframeMessengerEvent) => {
  const data = e.data as any

  Object.keys(data).forEach((key) => {
    browserStorage.setItem(key, data[key], false)
  })

  restoreChatStoreFromLocalStorage()
  iframeInited.value = true

  if (browserStorage.getItem(BrowserStorageKeys.UserHash)) {
    onGenderSelected()
  }
})

watchEffect(() => {
  if (route.name === 'Dialog' && parseInt(route.params.id as string) === 107) {
    nextTick(() => {
      router.replace('/dialog/104')
    })
  } else if (ready.value && route.name === 'Dialog' && [104, 106, 101].indexOf(parseInt(route.params.id as string)) >=0 && currentUser.value.isGuest) {
    nextTick(() => {
      router.replace('/support')
    })
  }
})

watchEffect(() => {
  if (!inited.value && router.currentRoute.value.name) {
    if (genderSelectedInOverlay.value) {
      initCoomeetChat()
    }
  }
})

watchEffect(() => {
  if (CoomeetChatInstance.connectionState.value === ConnectionState.Connected && !currentUser.value!.consent) {
    if (!popupStore.popupOpened(Popups.AccountDeleted) &&
        !popupStore.popupOpened(Popups.TermsOfService) &&
        !chatStore.termsWasShowed) {
      popupStore.showPopup(Popups.TermsOfService)
      currentUser.value.consent = 1
      chatStore.termsWasShowed = true
    }
  } else if (CoomeetChatInstance.connectionState.value === ConnectionState.Disconnected) {
    soundsPlayer.stopAllSounds()
    CoomeetChatInstance.incomingCall.value = null
    CoomeetChatInstance.outgoingCall.value = null
    CoomeetChatInstance.closeLocalMediaStream()

    popupStore.hidePopupsOnDisconnect()

    if (!popupStore.popupOpened(Popups.Disconnected) && !popupStore.popupOpened(Popups.DoubleEnter))
      popupStore.showPopup(Popups.Disconnected)

  }
})

watchEffect(() => {
  if (CoomeetChatInstance.connectionState.value === ConnectionState.Connected) {
    soundsPlayer.stopAllSounds()
  }
})

watchEffect(() => {
  if (ready.value) {

    if (!currentUser.value.isGuest) {
      chatStore.termsWasShowed = true
    }

    if (CoomeetChatInstance.initialContactsReceived.value && !referralLinkInited) {
      if (Application.referralUserId) {
        referralLinkInited = true
        chatStore.closeVideoChat()
        if (Application.referralUserId === currentUser.value.id) {
          router.push(`/id${Application.referralUserId}`)
        } else {
          router.push(`/dialog/${Application.referralUserId}`)
        }
      }
    }
  }
})

let pushNotificationsInited = false

watchEffect(async () => {
  if (ready.value) {
    document.body.classList.remove('loading')

    if (CoomeetChatInstance.user.value.config?.vapid && pushNotification.supported && !pushNotificationsInited) {
      pushNotificationsInited = true
      pushNotification.setVapid(CoomeetChatInstance.user.value.config?.vapid)

      if (pushNotification.notificationsGranted) {

        if (CoomeetChatInstance.user.value.webPushSubscribe) {
          chatStore.enablePushNotifications()

          try {
            await pushNotification.registerServiceWorker()

            if (pushNotification.subscription) {
              const code = JSON.stringify(pushNotification.subscription)
              CoomeetChatInstance.subscribe(code, location.host, false)?.catch(() => {
                ErrorLoggerInstance.error('Push notifications, no code for subscribe request', pushNotification.subscription, code)
              })
            }
          } catch (e) {
            console.log('Error on register service worker', e)
          }
        }
      }
    }
  }
})

watchEffect(() => {
  if (LocalizationInstance.locale.value) {
    pushNotification.locale = LocalizationInstance.locale.value
    const filteredLocales = locales
        .filter((localeItem) => localeItem.code.toLowerCase().trim() === LocalizationInstance.locale.value)
    if (filteredLocales.length > 0) appStore.localization = filteredLocales[0]
  }
})

if (Application.mobile) {
  try {
    for (const si in document.styleSheets) {
      const styleSheet = document.styleSheets[si]
      for (let ri = 0; ri < styleSheet.cssRules.length ; ri++) {
        const rule = styleSheet.cssRules[ri] as CSSStyleRule
        if (!rule.selectorText) continue

        if (rule.selectorText.match(':hover')) {
          if (rule.selectorText.match('\.dialog-item')) {
            styleSheet.deleteRule(ri)
          } else {
            if (!rule.selectorText.match('.payment-variants__item:hover') &&
                !rule.selectorText.match('\.communication-item:hover \.communication-item-actions')) {
              rule.selectorText = rule.selectorText.replace(':hover', ':active')
            }
          }
        }
      }
    }
  } catch (ex) {}
}
</script>
