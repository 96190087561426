enum ScopePaymentCommands {
  CheckOut = 'CheckOut',
  PurchaseMinutes = 'PurchaseMinutes',
  PurchasePremium = 'PurchasePremium',
  AutoRefillEnable = 'AutoRefillEnable',
  AutoRefillDisable = 'AutoRefillDisable',
  UserCardRemove = 'UserCardRemove',
  ShowWebPushBonus = 'ShowWebPushBonus',
  UpdateCards = 'UpdateCards',
  UpdateOffers = 'UpdateOffers',
  AutoSubscriptionChangeCard = 'AutoSubscriptionChangeCard',
  AutoSubscriptionDisable = 'AutoSubscriptionDisable',
  LowMinute = 'LowMinute',
  AutoRefillError = 'AutoRefillError',
  ScoreRefunded = 'ScoreRefunded',
  UserCardConfirm = 'UserCardConfirm',
  RefundPremium = 'RefundPremium',
  RefundMinutes = 'RefundMinutes'
}

export default ScopePaymentCommands
