import exp from "constants";

export interface UserModelAvatar {
  s: string,
  m: string,
  o: string,
}

export interface UserModelBan {
  Ban: UserModelBanStatus,
  BanExpired: number,
  Duration: number,
  AccountCheck: string //AccountValidationVerifyUnderAge, //AccountValidationWaite
}

export enum UserModelBanStatus {
  None = 0,
  Porn = 1,
  Advertising = 4,
  Photo = 5,
  Age = 6,
  Gender = 7,
  Lifetime = 10,
  Child = 66,
  TenDays = 67,
  Rules = 8,
  PaypalDispute = -1,
}

export enum UserModelRole {
  Moderator = 'Moderator'
}

export interface UserModelGeo {
  Id: string,
  Name: string,
  Code: string,
  continent_code: string
}

export interface UserModelSettings {
  NoticeUserMessage: number,
  NoticeSystemNew: number,
  NoticeGifts: number,
  NoticePartnerNews: number,
  NoticeUserInvite: number,
  TranslateIn: number,
  TranslateOut: number,
  TranslateLang: string,
  NetWork: string[]

}

export interface UserModelBlockCard {
  CaptureId: number,
  Currency: string,
  Digit: number,
  Number: string,
  Status: string
}

export interface UserModelMaleConfig {
  MessageScorePerMinute: number,
  PromoCountFreeMessage: number,
  screenshotCaptureInterval: number,
  vapid: string,
}

export interface UserModelFemaleConfig {
  MessageCost: number,
  PayTypes: UserModelPayType[],
  balanceForSearchFiltration: number
  cardValidateAmount: number,
  emailMaxLength: number,
  firstAutoPurchaseAttemptMinutesCount: number,
  imageMaxUploadSizeBytes: number,
  messageMaxLength: number,
  minimumTariff: number,
  minutesCountForAutoPurchaseActivation: number,
  passwordMaxLength: number,
  percentForInvite: number,
  pointsForPhotoMessage: number,
  pointsForStoryLike: number,
  pointsForTextMessage: number,
  pointsForVideoMessage: number,
  promoDurationMinutes: number,
  promoPointsForPremiumPurchase: number,
  screenshotCaptureInterval: number,
  secondAutoPurchaseAttemptMinutesCount: number,
  storyLifetime: number,
  storyMaxDuration: number,
  timeEmailConfirmationBonus: number,
  timeEmailConfirmationFreebie: number,
  usernameMaxLength: number,
  vapid: string,
}

export interface UserModelPayType {
  id: string,
  CardBrands: string[],
  MinimalAmount: number,
  Speed: 'fast' | 'medium' | 'slow',
  Validation?: any,
  usdt_types?: string[],
  Number?: string
}

export interface UserModelBill {
  Url: string,
  AccessProlongAllow: number,
  AccessProlongAllowBefore: number,
  Billings: string[],
  CardBrands: string[],
  Cards: UserModelBillCard[],
  LastPaySystem: string,
  TrialEnable: number,
  TrialMinuteEnable: number
  LowCostMinutes: number
  Offers: {
    Access: UserModelBillAccess[] | null,
    Minutes: UserModelBillMinute[] | null
  },
  PremiumSubscription: UserModelBillPremiumSubscription | null,
  vatIncluded?: number
}

export interface UserModelBillPremiumSubscription {
  Currency: string
  IsActive: number
  Minutes: number
  PeriodCount: number
  PeriodUnit: string
  Price: number
  UrlUpdateCard: string
  bonusMillisecondsBalance: number
  bonusMillisecondsDeposit: number
  bonusMillisecondsReward: number
  cardLastNumbers: string
  isEnablingAvailable: number
  isEnablingUpdateCard: number
  isNew: number
  nextAvailabilityDate: number,
  nextBonusMillisecondsAdditionDate: number
}

export interface UserModelBillCard {
  Brand: string
  Id: number
  IsUsedForAutoPurchaseMinutes: number
  IsUsedForPremiumSubscription: number
  Number: string
}

export interface UserModelBillMinute {
  id: number,
  Amount: number,
  AvailableOnce: number,
  Currency: string,
  DiscountCount: number,
  DiscountUnit: string,
  FreeMinutesForAutoPurchase: number,
  IsAutoPurchaseAvailable: number,
  IsAutoPurchaseEnabled: number,
  Minutes: number,
  OneClick: null | number,
  Order: number,
  ProductId: string,
  RelativePrice: number,
  Trial: number,
  Urls: {
    bitcoin: string,
    card: string,
    sbp: string
  }
}

export interface UserModelBillAccess {
  id: number,
  Amount : number,
  AvailableOnce: number,
  BonusMillisecondsReward: number,
  Currency: string,
  DiscountCount: number,
  DiscountUnit: string
  IsSubscription: number,
  Minutes: number
  OldRelativePrice: number
  OneClick: number | null
  Order: number
  PeriodCount: number,
  PeriodUnit: string,
  ProductId: string
  RelativePrice: number
  Trial: number,
  Urls: {
    bitcoin: string,
    card: string,
    sbp: string
  }
}

export interface UserModelTariff {
  id: number,
  FreeRate: number,
  Limit: number,
  PaidRate: number
}

export enum UserModelGender {
  Male = 1,
  Female = 2
}

export enum UserModelStatusReg {
  Guest = 0,
  RegistrationNotComplete = 1,
  RegistrationComplete = 2
}

export enum AccountCheck {
  AccountChecked = 'AccountChecked',
  AccountValidationWaite = 'AccountValidationWaite',
  AccountValidationUploaded = 'AccountValidationUploaded',
  AccountValidationVerifyUnderAge = 'AccountValidationVerifyUnderAge',
  AccountValidationFakeDoc = 'AccountValidationFakeDoc',
  AccountValidationNoDate = 'AccountValidationNoDate',
  AccountValidationNoFace = 'AccountValidationNoFace',
  AccountValidationAlienFace = 'AccountValidationAlienFace',
  AccountValidationAlienDoc = 'AccountValidationAlienDoc',
  AccountValidationBadPhoto = 'AccountValidationBadPhoto',
  AccountValidationUnderAge = 'AccountValidationUnderAge',
  AccountValidationAccountExist = 'AccountValidationAccountExist',
  AccountValidationNoDoc = 'AccountValidationNoDoc',
  AccountValidationSecondCheck = 'AccountValidationSecondCheck',
  AccountValidationCorrupt = 'AccountValidationCorrupt'
}

export interface PayoutRequest {
  id: number,
  balls: number,
  status: string,
  payout: string,
  requisites: string,
  reasonReject: string,
}

export interface UserModelRecordedStory {
  id: number,
  earnedPoints: number,
  expiredTime: number,
  likesCount: number,
  moderationStatus: RecordedStoryModerationStatus,
  viewsCount: number,
  media: {
    gif: string
    gifready: number,
    mp4: string
    mp4ready: number,
    preview: string,
    previewready: number
    webm: string,
    webmready: number
  }
}

export enum RecordedStoryModerationStatus {
  NotChecked = -1,
  Approved = 0,
  FailPorno = 1,
  FailAdvert = 4,
  FailPhoto = 5,
  FailAge = 6,
  FailGender = 7,
  FailRule = 8,
  FailBaby = 66,
  UploadError = 13,
  ConvertError = 13,
  ApiRabbitError = 13
}

export interface UserModelTop {
  BallsLeft: number,
  Position: number
  SecondsLeft: number
}
