<template lang="pug">
.message-form(:class="{disabled: formDisabled}")
  AttachContent
  MessageInput(@message:sent="onMessageSent", @input:focus="onInputFocus", @input:blur="onInputBlur")
</template>
<script lang="ts" setup>
import AttachContent from './MessageForm/AttachContent.vue'
import MessageInput from './MessageForm/MessageInput.vue'
import {computed} from 'vue'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'

const currentDialog = CoomeetChatInstance.currentDialog

const emit = defineEmits(['message:sent', 'input:focus', 'input:blur'])

const formDisabled = computed(() => {
  if (CoomeetChatInstance.sendingMediaInProgress.value) return true

  if (currentDialog.value && currentDialog.value!.isSupport) {
    return !currentDialog.value!.botStatus?.operator
  }
  return false
})

const onMessageSent = () => {
  emit('message:sent')
}

const onInputFocus = () => {
  emit('input:focus')
}

const onInputBlur = () => {
  emit('input:blur')
}
</script>
