import ModelAbstract from './ModelAbstract.class'

interface GiftListItem {
  ID: number,
  minutes: number,
  balls: number,
  title: string,
  image: string,
  count: number
}

const GiftsList : any[] = [
  // {
  //   ID:      15323387168356,
  //   minutes: 2 * 60,
  //   balls:   20,
  // },
  {
    ID:      14792970994592,
    minutes: 5 * 60,
    balls:   50,
    title:   'C_GIFT_FLAVOUR',
    image:   '/pics/gifts/gift-flower.png',
    count: 0
  },
  {
    ID:      14794468052104,
    minutes: 10 * 60,
    balls:   100,
    title:   'C_GIFT_CAT',
    image:   '/pics/gifts/gift-cat.png',
    count: 0
  },
  {
    ID:      14794468835295,
    minutes: 15 * 60,
    balls:   150,
    title:   'C_GIFT_BRILLIANT',
    image:   '/pics/gifts/gift-diamond.png',
    count: 0
  },
  {
    ID:      14804169525798,
    minutes: 25 * 60,
    balls:   250,
    title:   'C_GIFT_DIADEM',
    image:   '/pics/gifts/gift-crown.png',
    count: 0
  },
  {
    ID:      14805911882111,
    minutes: 50 * 60,
    balls:   500,
    title:   'C_GIFT_STRAWBERRY',
    image:   '/pics/gifts/gift-strawberry.png',
    count: 0
  }
]

export default class GiftModel extends ModelAbstract {
  public minutes: number = 0
  public balls: number = 0
  public title: string = ''
  public image: string = ''
  public count: number = 0

  public static CreateGiftById(id: number) : GiftModel | null {
    const list = GiftsList.filter((giftListItem) => giftListItem.ID === id)

    if (list.length > 0) {
      const giftModel = new GiftModel()
      giftModel.setData(list[0])
      return giftModel
    }

    return null
  }

  setData(data: any) {
    super.setData(data)

    this.minutes = data.minutes
    this.balls = data.balls
    this.title = data.title
    this.image = data.image
    this.count = data.count
  }
}

export const GiftsListModels: GiftModel[] = GiftsList.map((giftListItem) => GiftModel.CreateGiftById(giftListItem.ID)) as GiftModel[]
