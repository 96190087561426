import Scope from './Scope.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import ScopeCommonCommands from './ScopeCommon/commands'
import DialogModel from '../../models/DialogModel.class'

interface TypingListTimeoutItem {
  dialog: DialogModel,
  timeout?: NodeJS.Timeout
}

export default class ScopeCommon extends Scope {
  private _typingList : TypingListTimeoutItem[] = []

  private _addClearTypingTimeout(dialog: DialogModel) {
    let timeoutItem = this._getTypingTimeout(dialog)

    if (timeoutItem.timeout) {
      clearTimeout(timeoutItem.timeout)
    }

    timeoutItem.timeout = setTimeout(() => {
      dialog.typing = false
      this._removeTypingTimeout(dialog)
    }, 30000)
  }

  private _removeTypingTimeout(dialog: DialogModel) : void {

    this._typingList = this._typingList.filter((item) => {
      if (item.dialog === dialog) {
        clearTimeout(item.timeout)
      }
      return item.dialog !== dialog
    })
  }

  public _getTypingTimeout(dialog: DialogModel) : TypingListTimeoutItem {
    const list = this._typingList.filter((item) => item.dialog === dialog)
    return list.length > 0 ? list[0] : {dialog}
  }

  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      case ScopeCommonCommands.Write: {
        //{"event":{"Cmd":"Write","Cid":""},"data":{"id":16748337502072,"type":0}}
        const dialogId = scopeData.data.id as number
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (dialog) {
          dialog.typing = !!scopeData.data.type
          if (dialog.typing) {
            this._addClearTypingTimeout(dialog)
          } else {
            this._removeTypingTimeout(dialog)
          }
        }
      }
        break

      //{"event":{"Cmd":"ShowTopBonus","Cid":""},"data":{"paidGroup":4,"paidMinutes":338,"score":1663,"balls":250,"place":1,"period":"hour","tariffs":{"2":{"Limit":1007,"PaidRate":12,"FreeRate":5},"3":{"Limit":509,"PaidRate":11,"FreeRate":4},"4":{"Limit":0,"PaidRate":10,"FreeRate":3},"1":{"Limit":1121,"PaidRate":24,"FreeRate":7}},"top":{"Position":69132,"BallsLeft":6394,"SecondsLeft":58060}}}
      case ScopeCommonCommands.ShowTopBonus: {
        if (scopeData.data.paidGroup) this._coomeetChatInstance.user.value.paidGroup = scopeData.data.paidGroup
        if (scopeData.data.paidGroup) this._coomeetChatInstance.user.value.paidMinutes = scopeData.data.paidMinutes
      }
        break


      //{"event":{"Cmd":"UpdateStory","Cid":""},"data":{"recordedStory":{"media":{"mp4":"5-628fbf70-1591-4be4-9368-2fd5a4537fd4","preview":"5-fd09b2fb-58c3-4516-910a-acbf383fa983","gif":"5-5cfc1715-e9ce-4f5b-b4f4-4b92de4661bf","webm":"5-44296fd9-2dec-4b4a-839c-cd37e3dd5797.webm","webmready":1,"mp4ready":1,"previewready":1,"gifready":1},"moderationStatus":-1,"id":4383976,"viewsCount":0,"earnedPoints":0,"likesCount":0,"expiredTime":0}}}
      case ScopeCommonCommands.UpdateStory: {
        this._coomeetChatInstance.user.value.recordedStory = scopeData.data.recordedStory
      }
        break

      case ScopeCommonCommands.StoryAutoDeleted: {
        this._coomeetChatInstance.user.value.recordedStory = null
      }
        break
    }
  }

  public async write(dialog: DialogModel, typing: boolean) : Promise<CommandPromiseArguments> {
    const user = this._coomeetChatInstance.user.value

    if (user && user.typing !== typing) {
      user.typing = typing
      return await this.sendCommand(ScopeCommonCommands.Write, {
        id: dialog.id,
        type: typing ? 1 : 0
      })
    } else {
      return {command: null, response: null}
    }
  }

  public async viewSettings() : Promise<CommandPromiseArguments> {
    const user = this._coomeetChatInstance.user

    if (!user.value.viewSettings) {
      const data = await this.sendCommand(ScopeCommonCommands.ViewSettings, {
        type: 1
      })

      user.value.viewSettings = 1

      return data
    } else {
      return {command: null, response: null}
    }
  }

  public viewTop(period: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeCommonCommands.ViewTop, {
      code: period
    })
  }

  public publishStory(videoBase64 : string, previewBase64 : string, duration: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeCommonCommands.PublishStory, {
      preview: previewBase64,
      byte: videoBase64,
      duration: Math.round(duration)
    }, { type : this._coomeetChatInstance.videoMimeType }).then(({command, response}) => {
      this._coomeetChatInstance.user.value.recordedStory = response?.data.recordedStory
      return {command, response}
    })
  }

  public removeStory() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeCommonCommands.RemoveStory, null).then(({command, response}) => {
      this._coomeetChatInstance.user.value.recordedStory = null

      return {command, response}
    })
  }

  public fingerPrint(token: string) : void {
    this.sendCommand(ScopeCommonCommands.FingerPrint, {
      token
    })
  }

  public info() : void {
    if (!this._webSocketApi.connectionInfoWasSent) {
      this.sendCommand(ScopeCommonCommands.Info, {
        ConnectTime: this._webSocketApi.connectionDuration
      })

      this._webSocketApi.connectionInfoWasSent = true
    }
  }
}
