export enum Sound {
  CallIncoming = 'CallIncoming',
  CallOutgoing = 'CallOutgoing',
  Gift = 'Gift',
  NewMessage = 'NewMessage',
  MessageSend = 'MessageSend'
}

interface SoundItem {
  audio: HTMLAudioElement,
  id: Sound
}

class SoundsPlayer {
  private _sounds : SoundItem[] = []
  private _currentSound: SoundItem | null = null

  constructor() {
    this._createSounds()
  }

  private _createSounds() : void {
    this._sounds = [
      {
        id: Sound.CallIncoming,
        audio: new Audio('/sounds/call-incoming.mp3')
      },
      {
        id: Sound.CallOutgoing,
        audio: new Audio('/sounds/call-outgoing.mp3')
      },
      {
        id: Sound.Gift,
        audio: new Audio('/sounds/gift.mp3')
      },
      {
        id: Sound.NewMessage,
        audio: new Audio('/sounds/message-new.mp3')
      },
      {
        id: Sound.MessageSend,
        audio: new Audio('/sounds/message-send.mp3')
      }
    ]
  }

  public playSound(sound: Sound, loop: boolean = false) : void {
    const filtered = this._sounds.filter((item) => item.id === sound)

    if (filtered.length > 0) {
      this._currentSound = filtered[0]
      this._currentSound.audio.loop = loop
      this._currentSound.audio.currentTime = 0
      this._currentSound.audio.play().catch((e) => {})
    }
  }

  public stopSound(sound: Sound) : void {
    const filtered = this._sounds.filter((item) => item.id === sound)

    if (filtered.length > 0) {
      this._currentSound = filtered[0]
      this._currentSound.audio.pause()
      /**
       * Вызываем перезагрузку audio элемента после остановки, чтобы скрыть виджет плеера в уведомлениях
       * на мобильных ОС, иначе остановленный звук может воспроизвестись повторно после действий юзера или ОС
       */
      this._currentSound.audio.load()
    }
  }

  public stopAllSounds () : void {
    this._sounds.forEach((soundItem) => {
      soundItem.audio.pause()
      /**
       * Вызываем перезагрузку audio элемента после остановки, чтобы скрыть виджет плеера в уведомлениях
       * на мобильных ОС, иначе остановленный звук может воспроизвестись повторно после действий юзера или ОС
       */
      soundItem.audio.load()
    })
  }
}

const soundsPlayer = new SoundsPlayer()

export default soundsPlayer
