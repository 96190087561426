import {ref, Ref, UnwrapRef} from 'vue'
import ModelAbstract from './ModelAbstract.class'

export default class ModelsList<T extends ModelAbstract> {
  public readonly modelsList: Ref<T[]> = ref([])

  public get length () : number {
    return this.modelsList.value.length
  }

  public addModel(model: T) : boolean {
    if (this.modelsList.value.filter((modelItem: T) => modelItem.id === model.id).length === 0) {
      this.modelsList.value.push(model)
      return true
    }

    return false
  }

  public removeModel(model: T) : void {
    this.modelsList.value = this.modelsList.value.filter((modelItem: T) => modelItem.id !== model.id)
  }

  public getById(id: number) : T | null {
    const list = this.modelsList.value.filter((modelItem: T) => {
      return modelItem.id === id
    })
    return list.length > 0 ? list[0] : null
  }

  public clear() : void {
    this.modelsList.value = []
  }
}
