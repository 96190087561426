<template lang="pug">
.gender-overlay-select
  .gender-overlay-select__bg
  .gender-select
    .gender-select__title {{t('C_GENDER_TITLE')}}
    .gender-items
      .gender-item(v-for="(genderItem, index) in gendersList",
        :class="{active: genderItem.id === selectedGenderItem?.id, male: genderItem.id === 'male', female: genderItem.id === 'female'}",
        :key="genderItem.id",
        @click="onGenderItemClick(genderItem)")
        .gender-item__icon
          .selected-indicator
            .selected-indicator__icon
        .gender-item__label {{t(genderItem.title)}}
    UiSimpleButton.size-56(v-if="selectedGenderItem",
      :class="{'color-blue': selectedGenderItem.id === 'male', 'color-pink': selectedGenderItem.id === 'female'}",
      @click="onContinueClick") {{t('A_CONTINUE')}}

  LanguageSelect(v-if="!isRusvideodatingOrigin")
  .wait(:class="{visible: false}")
    .wait__icon(v-if="!isRusvideodatingOrigin")
      BirdIcon
    .wait__text {{t('A_WAITING')}}
</template>
<script lang="ts" setup>
import {ref} from 'vue'
import BirdIcon from './../assets/images/icons/bird.svg'
import LanguageSelect from './GenderOverlaySelect/LanguageSelect.vue'
import UiSimpleButton from './../components/ui/Buttons/UiSimpleButton.vue'
import CoomeetChatInstance from '../common/classes/CoomeetChat/CoomeetChat.class'
import iframeMessenger from '../common/classes/IframeMessenger.class'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const currentUser = CoomeetChatInstance.user
const emit = defineEmits(['selected'])

interface GenderItem {
  title: string,
  id: string
}

const gendersList = ref<GenderItem[]>([
  {
    title: 'S_MALE',
    id: 'male',
  },
  {
    title: 'S_FEMALE',
    id: 'female'
  }
])

const isRusvideodatingOrigin = iframeMessenger.isRusvideodatingOrigin

const selectedGenderItem = ref<GenderItem | null>(null)

const onGenderItemClick = (genderItem: GenderItem) => {
  selectedGenderItem.value = genderItem
}

const onContinueClick = () => {
  if (selectedGenderItem.value) {
    currentUser.value!.gender = selectedGenderItem.value!.id === 'male' ? 1 : 2
    emit('selected')
  }
}
</script>
