<template lang="pug">
.actions-list(v-if="currentDialog?.isFriend || currentDialog?.Promo || currentDialog?.accountDeleted")
  .actions-list__item(
    v-if="!currentDialog.accountDeleted && !currentDialog.isSupport && !callButtonDisabled",
    :class="{disabled: callButtonDisabled}",
    @click="onCallClick",
    v-tooltip:bottom="t('APP_TO_CALL')")
    .icon
      CallIcon
  .actions-list__item.disabled-call-button(v-else-if="!currentDialog.accountDeleted && !currentDialog.isSupport && callButtonDisabled", v-tooltip:bottom="disabledCallButtonTooltip")
    .icon
      CallIcon
  .actions-list__item(
    v-if="!currentDialog.accountDeleted && !currentDialog.isSupport",
    @click="onBookmarkItemClick",
    v-tooltip="t('C_NOTE_USER')")
    .icon(:class="{dotted: currentDialog && !!currentDialog.comment}")
      BookmarkIcon
      .icon__dot
  .actions-list__item(
    :class="{active: menuVisible}",
    ref="menuButton"
    @click="onMenuClick")
    .icon
      DotsIcon

  teleport(to="body")
    .chat-messages-options-list(ref="optionsList", v-show="menuVisible")
      .option-item(v-for="(optionItem, index) in menuOptionsList",
        :key="index",
        :class="{disabled: optionDisabled(optionItem)}",
        @click="onOptionItemClick(optionItem)")
        .option-item__label {{t(optionItem.label)}}

  GiftsList(ref="giftsList", v-if="!wasDeletedByOpponent")
</template>
<script lang="ts" setup>
import CallIcon from '../../../../../assets/images/icons/call-green-size-20.svg'
import BookmarkIcon from '../../../../../assets/images/icons/bookmark.svg'
import DotsIcon from '../../../../../assets/images/icons/dots-menu.svg'
import {computed, nextTick, onMounted, onUnmounted, ref, watchEffect} from 'vue'
import GiftsList from './ActionsList/GiftsList.vue'
import {Popups, usePopupOverlayStore} from '../../../../../stores/popupOverlay'
import CoomeetChatInstance, {VideoDialogState} from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import DialogModel from '../../../../../common/classes/CoomeetChat/models/DialogModel.class'
import {DialogModelDelete} from '../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {useChatStore} from '../../../../../stores/chat'
import {useI18n} from 'vue-i18n'
import {hideTooltip} from '../../../../../directives/tooltip'
import Application from '../../../../../common/classes/Application.class'

interface OptionItem {
  id: string,
  label: string
}

const {t} = useI18n()

const popupsStore = usePopupOverlayStore()
const chatStore = useChatStore()
const user = CoomeetChatInstance.user
const currentDialog = CoomeetChatInstance.currentDialog
const currentVideoDialog = CoomeetChatInstance.currentVideoDialog

const menuOptionsList = computed(() => {
  if (currentDialog.value?.accountDeleted) {
    return [
      {
        id: 'clear',
        label: 'C_MSNGR_CLEAR'
      },
      {
        id: 'delete-contact',
        label: 'C_FRIEND_DELETE'
      }
    ]
  }

  return !currentDialog.value?.isSupport ? [
    {
      id: 'favorite',
      label: 'C_FRIEND_FAVORITE_ADD'
    },
    {
      id: 'edit',
      label: 'APP_EDIT'
    },
    {
      id: 'clear',
      label: 'C_MSNGR_CLEAR'
    },
    {
      id: 'complain',
      label: 'A_ABUSE_SEND'
    },
    {
      id: 'block',
      label: 'APP_BLOCK'
    },
    {
      id: 'delete-contact',
      label: 'C_FRIEND_DELETE'
    }
  ] : [
    {
      id: 'delete-contact',
      label: 'C_FRIEND_DELETE'
    }
  ]
})

const menuVisible = ref(false)
const giftsList = ref<null | InstanceType<typeof GiftsList>>(null)

const callButtonDisabled = computed(() => !currentDialog.value!.online ||
    CoomeetChatInstance.videoDialogState.value === VideoDialogState.Connected ||
    (CoomeetChatInstance.outgoingCall.value !== null && !CoomeetChatInstance.outgoingCall.value.callCancelled) ||
    !currentDialog.value?.access ||
    user.value.ban
)

const disabledCallButtonTooltip = computed(() => {
  if (user.value.ban) return t('C_BAN_TITLE')
  if (currentDialog.value && !currentDialog.value!.online) return t('APP_CALL_UNAVAILABLE', {br: "\r\n"})
  if (currentDialog.value && !currentDialog.value!.access) return t('APP_CALL_UNAVAILABLE_NOT_PREMIUM', {br: "\r\n"})
  if (
    CoomeetChatInstance.videoDialogState.value === VideoDialogState.Connected ||
    (CoomeetChatInstance.outgoingCall.value !== null && !CoomeetChatInstance.outgoingCall.value.callCancelled)
  ) return t('APP_CALL_UNAVAILABLE_DURING_CALL')
})

const optionDisabled = (option: OptionItem) : boolean => {
  if (currentVideoDialog.value?.id === currentDialog.value?.id && CoomeetChatInstance.videoDialogState.value !== VideoDialogState.VideoCallAccept && CoomeetChatInstance.videoDialogState.value !== VideoDialogState.Vote) {
    const disabledIds = ['clear', 'block', 'delete-contact']
    return disabledIds.indexOf(option.id) >= 0
  }
  if (currentDialog.value?.Promo && option.id === 'favorite') {
    return true;
  }

  return false
}

const onMenuClick = () => {
  menuVisible.value = !menuVisible.value
  nextTick(() => {
    updateMenuPosition()
  })
}

const menuButton = ref<HTMLElement | null>(null)
const optionsList = ref<HTMLElement | null>(null)

const wasDeletedByOpponent = computed(() => {
  return currentDialog.value ? currentDialog.value!.delete === DialogModelDelete.MeDeleted : false
})

const updateMenuPosition = () => {
  if (optionsList.value && menuButton.value) {
    const menuButtonEl = menuButton.value!
    const optionsListEl = optionsList.value!

    const rect = menuButtonEl.getBoundingClientRect()
    const optionsRect = optionsListEl.getBoundingClientRect()

    optionsListEl.style.left = `${rect.left + rect.width - optionsRect.width}px`
    optionsListEl.style.top = `${rect.top + rect.height + 5}px`
  }
}

const onWindowResize = () => {
  updateMenuPosition()
}

const onDocumentClick = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('.chat-messages-options-list') !== optionsList.value) {
    if ((e.target as HTMLElement).closest('.actions-list__item') !== menuButton.value) {
      menuVisible.value = false
    }
  }
}

const onCallClick = () => {
  if (!Application.hasVideoDevices) {
    popupsStore.showPopup(Popups.NoCameraAccess)
  } else {
    CoomeetChatInstance.clearVideoChatState()
    popupsStore.hidePopup(Popups.VideoChatRating)
    popupsStore.hidePopup(Popups.AnsweredTheCall)

    if (CoomeetChatInstance.outgoingCall.value) CoomeetChatInstance.outgoingCall.value!.callCancelled = false
    CoomeetChatInstance.outgoingCall.value = null

    nextTick(() => {
      if (currentDialog.value) currentDialog.value!.callCancelled = false
      CoomeetChatInstance.outgoingCall.value = currentDialog.value
      chatStore.videoChatWasOpenedOnOutgoingCall = chatStore.videoChatOpened
    })
  }

  hideTooltip()
}

const onBookmarkItemClick = () => {
  popupsStore.showPopup(Popups.UserNotes, currentDialog.value! as DialogModel)
  hideTooltip()
}

const onGiftsItemClick = () => {
  const {toggleVisibility} = giftsList.value!
  toggleVisibility()
  hideTooltip()
}

const onOptionItemClick = (optionItem: OptionItem) => {
  menuVisible.value = false

  switch (optionItem.id) {
    case 'favorite': {
      const currentDialog = CoomeetChatInstance.currentDialog.value! as DialogModel
      CoomeetChatInstance.toggleFavoriteDialogStatus(currentDialog)
    }
      break

    case 'edit':
      popupsStore.showPopup(Popups.EditUser)
      break

    case 'clear':
      popupsStore.showPopup(Popups.ConfirmChatClear)
      break

    case 'complain':
      popupsStore.showPopup(Popups.Complain, currentDialog.value)
      break

    case 'block':
      popupsStore.showPopup(Popups.BlockUser)
      break

    case 'delete-contact':
      popupsStore.showPopup(Popups.DeleteDialog)
      break
  }
}

onMounted(() => {
  updateMenuPosition()
  window.addEventListener('resize', onWindowResize)
  document.addEventListener('click', onDocumentClick)
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
  document.removeEventListener('click', onDocumentClick)
})


watchEffect(() => {
  const currentDialog = CoomeetChatInstance.currentDialog.value
  const filteredList = menuOptionsList.value.filter((optionItem) => optionItem.id === 'favorite')
  if (filteredList.length > 0 && currentDialog) filteredList[0].label = currentDialog.favorite ? 'C_FRIEND_FAVORITE_REMOVE' : 'C_FRIEND_FAVORITE_ADD'
})

watchEffect(() => {
  if (!currentDialog.value?.isFriend) {
    menuVisible.value = false
  }
})
</script>
