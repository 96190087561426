<template lang="pug">
.date-messages(ref="root")
  .date-messages__date {{isToday ? t('S_TODAY') : props.block.date}}
  .date-messages__messages-list
    .additional-info(v-if="isToday")
      .additional-info__item
        .additional-info__item--secured
          .additional-info__item--secured__icon
            LockIcon
          .additional-info__item--secured__text {{t('SYS_MSG_PROTECT')}}

      .additional-info__item(v-if="currentDialog?.Promo?.balls")
        .additional-info__item--incomming
          .additional-info__item--incomming__title(v-html="t('C_MSNGR_FEMALE_BALL_PROMO')")
          .additional-info__item--incomming__row
            span.additional-info__item--incomming__row__text(v-html="t('C_PROMO_ACTION_MESSAGE')")
            span.additional-info__item--incomming__row__dots
            span.additional-info__item--incomming__row__points(v-html="'+' + t('APP_PL_BALL_', {n: 1})")

          .additional-info__item--incomming__row
            span.additional-info__item--incomming__row__text(v-html="t('C_PROMO_ACTION_PHOTO')")
            span.additional-info__item--incomming__row__dots
            span.additional-info__item--incomming__row__points(v-html="'+' + t('APP_PL_BALL_', {n: 1})")

          .additional-info__item--incomming__row
            span.additional-info__item--incomming__row__text(v-html="t('C_PROMO_ACTION_VIDEO')")
            span.additional-info__item--incomming__row__dots
            span.additional-info__item--incomming__row__points(v-html="'+' + t('APP_PL_BALL_', {n: 1})")


      .additional-info__item(v-if="false")
        .additional-info__item--yellow
          .additional-info__item--yellow__text(v-html="t('SYS_MSG_21', {points: `<span class='orange'>+` + currentDialog?.Promo?.balls + `</span>`})")

      .additional-info__item(v-if="!currentDialog?.isSupport && !currentDialog?.Promo?.balls")
        .additional-info__item--yellow
          .additional-info__item--yellow__text(v-html="t('C_MSNGR_FEMALE_BALL', {ball: t('APP_PL_POINT_', {n: 1})})")


      .additional-info__item(v-show="!pushNotificationsClosed")
        .additional-info__item--notifications
          .additional-info__item--notifications__text(v-html="t('APP_EMAIL_NOTIFY', {notify: `<span>${t('APP_NOTIFICATION_APPEAL_ACTION')}</span>`})")

    .message-item(v-for="(message, index) in props.block.messages",
      :class="`message-${message.id}`",
      :key="index")
      template(v-if="!message.markedForResend")
        component(:is="getMessageComponent(message)", :message="message")
      template(v-else)
        .message-item__resend
          .message-item__resend--icon(@click="onResendMessageClick(message)")
            ReloadIcon
          .message-item__resend--message
            component(:is="getMessageComponent(message)", :message="message")


    .additional-info(v-if="currentDialog?.Promo && isToday && currentDialog.access !== 1")
      .additional-info__item
        PromoTimer

</template>
<script lang="ts" setup>
import {ref, computed, onBeforeUnmount, onMounted} from 'vue'
import ReloadIcon from '@/assets/images/icons/reload-size-14.svg'
import LockIcon from '@/assets/images/icons/lock-black-size-16.svg'
import TextMessage from './DateMessages/TextMessage.vue'
import SystemMessage from './DateMessages/SystemMessage.vue'
import PhotoMessage from './DateMessages/PhotoMessage.vue'
import VideoMessage from './DateMessages/VideoMessage.vue'
import GiftMessage from './DateMessages/GiftMessage.vue'
import StickerMessage from './DateMessages/StickerMessage.vue'
import type {MessagesBlock} from '../Messages.vue'
import type {
  DialogModelMessage
} from '../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {
  DialogModelMessageType
} from '../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import CoomeetChatInstance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import StickerModel from '../../../../../common/classes/CoomeetChat/models/StickerModel.class'
import {useI18n} from 'vue-i18n'
import pushNotification from '../../../../../common/classes/PushNotification.class'
import {useChatStore} from '../../../../../stores/chat'
import {Popups, usePopupOverlayStore} from "../../../../../stores/popupOverlay";
import PromoTimer from "./DateMessages/PromoTimer.vue";

const props = defineProps<{
  block: MessagesBlock
}>()

const {t} = useI18n()
const chatStore = useChatStore()
const popupStore = usePopupOverlayStore()

const root = ref<HTMLDivElement | null>(null)
const currentDialog = CoomeetChatInstance.currentDialog
const user = CoomeetChatInstance.user



const messageComponent = computed(() => {
  let component = TextMessage
  return component
})

const isToday = computed(() => {
  const blockDate = new Date(props.block.unixTime * 1000).toLocaleDateString()
  const nowDate = new Date().toLocaleDateString()

  return blockDate === nowDate
})

const pushNotificationsClosed = computed(() => !pushNotification.supported ||
    chatStore.pushNotificationReminderHiddenInDialogsList ||
    chatStore.pushNotificationsEnabled ||
    user.value.isGuest
)

const getMessageComponent = (message: DialogModelMessage) : any => {
  let component = TextMessage

  switch (message.type) {
    case DialogModelMessageType.Text:
      component = TextMessage
      break
    case DialogModelMessageType.System:
      component = SystemMessage
      break

    case DialogModelMessageType.Picture:
      component = PhotoMessage
      break
    case DialogModelMessageType.NewFormatVideo:
    case DialogModelMessageType.Video:
      component = VideoMessage
      break
    case DialogModelMessageType.Gift:
      component = GiftMessage
      break
    case DialogModelMessageType.Sticker:
      component = StickerMessage
      break
  }

  return component
}

const onResendMessageClick = (message: DialogModelMessage) => {
  if (message.scopeData) currentDialog.value!.removeMessageWithScopeData(message.scopeData)

  switch (message.type) {
    case DialogModelMessageType.Text:
      CoomeetChatInstance.sendMessage(message.scopeData?.data.msg, currentDialog.value!)
      break
    case DialogModelMessageType.Picture:
      CoomeetChatInstance.sendPhotoMessage(message.scopeData?.data.byte)?.catch(({response}) => {
        if ([406, 440].indexOf(response.data.code) < 0) popupStore.showPopup(Popups.UploadError)
      })
      break
    case DialogModelMessageType.Sticker:
      const sticker = new StickerModel(message.scopeData?.data.msg)
      CoomeetChatInstance.sendSticker(sticker)
      break
    case DialogModelMessageType.Video:
    case DialogModelMessageType.NewFormatVideo:
      CoomeetChatInstance.sendVideoMessage({
        videoBase64: message.scopeData?.data.byte,
        previewBase64: message.scopeData?.data.preview
      })
      break
  }
}

const getVisibleUnreadMessages = () => {
  if (root.value) {
    const visibleMessages : DialogModelMessage[] = []

    const scrollArea = root.value!.closest('.ui-scroll-area')!
    const scrollAreaRect = scrollArea.getBoundingClientRect()

    const unreadMessages = props.block.messages.filter((messageItem) => !messageItem.read && messageItem.inbox)

    return unreadMessages

    // Отказались от запроса Read {id, msgId}, смысла в фильтрации по видимости сообщения нет,
    // но код пока оставлю
    //
    // unreadMessages.forEach((messageItem) => {
    //   const messageElement = root.value!.querySelector(`.message-item.message-${messageItem.id}`)!
    //   const messageRect = messageElement.getBoundingClientRect()
    //
    //   if (messageRect.top >= scrollAreaRect.top &&
    //       messageRect.top + messageRect.height <= scrollAreaRect.top + scrollAreaRect.height) {
    //     visibleMessages.push(messageItem)
    //   }
    // })
    //
    // return visibleMessages
  }

  return []
}

const onEnableNotificationsClick = () => {
  pushNotification.openEnableNotificationsPopup()
}

onMounted(() => {
  if (isToday.value)
    document.querySelector('.additional-info__item--notifications__text span')!.addEventListener('click', onEnableNotificationsClick)
})

onBeforeUnmount(() => {
  if (isToday.value)
    document.querySelector('.additional-info__item--notifications__text span')!.removeEventListener('click', onEnableNotificationsClick)
})

defineExpose({
  getVisibleUnreadMessages
})

</script>
