<template lang="pug">
.attach-content(ref="menuButton", @click="onAttachContentClick")
  .attach-content__icon
  teleport(to="body")
    .attach-content-options-list(v-show="menuVisible", ref="optionsList")
      .option-item(v-for="(optionItem, index) in optionsListData",
        :key="index",
        @click="onOptionItemClick(optionItem)")
        .option-item__label {{t(optionItem.label)}}
</template>
<script lang="ts" setup>
import {ref, onMounted, onUnmounted, nextTick} from 'vue'
import {usePopupOverlayStore, Popups} from '../../../../../stores/popupOverlay'
import CoomeetChatInstance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {useI18n} from 'vue-i18n'

interface OptionItem {
  id: string,
  label: string
}

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const optionsListData = ref<OptionItem[]>(window.MediaRecorder ? [
  {
    id: 'record-video',
    label: 'A_RECORD_MAKE'
  },
  {
    id: 'take-photo',
    label: 'A_PHOTO_MAKE'
  },
  {
    id: 'upload-photo',
    label: 'A_PHOTO_UPLOAD'
  }
] : [
  {
    id: 'take-photo',
    label: 'A_PHOTO_MAKE'
  },
  {
    id: 'upload-photo',
    label: 'A_PHOTO_UPLOAD'
  }
])

const menuVisible = ref(false)
const user = CoomeetChatInstance.user

const onAttachContentClick = () => {
  menuVisible.value = !menuVisible.value
  nextTick(() => {
    updateMenuPosition()
  })
}

const menuButton = ref<HTMLElement | null>(null)
const optionsList = ref<HTMLElement | null>(null)

const updateMenuPosition = () => {
  const menuButtonEl = menuButton.value!
  const optionsListEl = optionsList.value!

  const rect = menuButtonEl.getBoundingClientRect()
  const optionsRect = optionsListEl.getBoundingClientRect()

  optionsListEl.style.left = `${rect.left}px`
  optionsListEl.style.top = `${rect.top - optionsRect.height - 5}px`
}

const onWindowResize = () => {
  updateMenuPosition()
}

const onDocumentClick = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('.attach-content-options-list') !== optionsList.value) {
    if ((e.target as HTMLElement).closest('.attach-content') !== menuButton.value) {
      menuVisible.value = false
    }
  }
}

const onOptionItemClick = (optionItem : OptionItem) => {
  menuVisible.value = false
  switch (optionItem.id) {
    case 'record-video':
      popupsStore.showPopup(Popups.RecordVideo)
      break
    case 'take-photo':
      popupsStore.showPopup(Popups.CreatePhoto)
      break
    case 'upload-photo':
      popupsStore.showPopup(Popups.UploadPhoto)
      break
  }
}

window.visualViewport?.addEventListener('resize', onWindowResize)
window.addEventListener('resize', onWindowResize)

onMounted(() => {
  updateMenuPosition()
  document.addEventListener('click', onDocumentClick)
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
  window.visualViewport?.removeEventListener('resize', onWindowResize)
  document.removeEventListener('click', onDocumentClick)
})
</script>
