import {Ref} from 'vue'
import ModelsList from '../../models/ModelsList.class'
import DialogModel from '../../models/DialogModel.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import ScopeMessengerCommands from './ScopeMessenger/commands'
import Scope from './Scope.class'
import {
  DialogModelDelete,
  DialogModelMessage,
  DialogModelMessageType,
  DialogModelMessageVideo,
  DialogModelStatus
} from '../../models/DialogModel/interfaces'
import {ContactsListOffset, ContactsListRule, VideoDialogState} from '../../CoomeetChat.class'
import StickerModel from '../../models/StickerModel.class'
import GiftModel from '../../models/GiftModel.class'
import ScopeUserCommands from './ScopeUser/commands'
import applicationCache from '../../../ApplicationCache.class'

export default class ScopeMessenger extends Scope {
  private get _dialogsList () : ModelsList<DialogModel> { return this._coomeetChatInstance.dialogsList }
  private get _totalContactsCount () : Ref<number> { return this._coomeetChatInstance.totalContactsCount }
  private get _unreadMessagesCount () : Ref<number> { return this._coomeetChatInstance.unreadMessagesCount }
  private get _currentDialog() : Ref<DialogModel | null > { return this._coomeetChatInstance.currentDialog }
  private get _currentVideoDialog() : Ref<DialogModel | null > { return this._coomeetChatInstance.currentVideoDialog }

  private _sentInviteRequestsToUsersIds : number[] = []
  private _acceptInviteRequestsToUsersIds : number[] = []

  protected _init() {
    this._coomeetChatInstance.subscribeSocketSignal({
      signal: ScopeUserCommands.NeedUpgrade,
      callback: () => {
        this._sentInviteRequestsToUsersIds = []
        this._acceptInviteRequestsToUsersIds = []
      }
    })
  }

  private _isDialogActive(dialog:DialogModel) : boolean {
    return dialog.id === this._currentDialog.value?.id
  }

  async processData(scopeData: WebSocketApiScopeData) : Promise<void> {
    super.processData(scopeData)

    switch (scopeData.event.Cmd) {

      case ScopeMessengerCommands.RemoveUser: {
        for (let id in scopeData.data.contacts) {
          const contactId = parseInt(id)
          let contactData = {
            ID: contactId,
            ...scopeData.data.contacts[contactId]
          }

          const dialog = this._dialogsList.getById(contactId)
          if (dialog) {
            dialog.setData(contactData)
          }
        }
      }
        break

      case ScopeMessengerCommands.UpdateNotification:
      case ScopeMessengerCommands.Invite:
      case ScopeMessengerCommands.InviteSend:
      case ScopeMessengerCommands.InviteAccept:
      case ScopeMessengerCommands.InviteDecline:
      case ScopeMessengerCommands.ContactList:
      case ScopeMessengerCommands.OnlineUpdate: {
        if (!scopeData.event.Cid) {
          if (scopeData.event.Cmd === ScopeMessengerCommands.ContactList) {
            this._coomeetChatInstance.useCache.value = false
            if (scopeData.data.support) {
              DialogModel.supportDataList = []
              for (let supportId of Object.keys(scopeData.data.support)) {
                const data = {
                  id: parseInt(supportId),
                  ...scopeData.data.support[supportId]
                }

                DialogModel.supportDataList.push(data)
              }
            }

            if (this._coomeetChatInstance.currentContactsListRule.value !== ContactsListRule.Last) {
              this._coomeetChatInstance.getContactsList(this._coomeetChatInstance.currentContactsListRule.value)
            }

            this._coomeetChatInstance.dialogsList.modelsList.value.forEach((dialogItem) => {
              dialogItem.resetInitialState()
            })
            if (this._coomeetChatInstance.currentDialog.value) {
              this._coomeetChatInstance.getDialogMessages(this._coomeetChatInstance.currentDialog.value)
            }
          }

          if (scopeData.data.total !== undefined) this._totalContactsCount.value = scopeData.data.total as number

          for (let contactId in scopeData.data.contacts) {
            const id = parseInt(contactId)

            let contactData = {
              ID: id,
              ...scopeData.data.contacts[contactId]
            }

            let dialog: DialogModel | null = this._dialogsList.getById(id)

            if (dialog) {
              dialog.setData(contactData)
            } else if (scopeData.event.Cmd !== ScopeMessengerCommands.OnlineUpdate) {
              dialog = DialogModel.FromJSON(contactData)
            }

            if (scopeData.event.Cmd === ScopeMessengerCommands.InviteSend) {
              this._sentInviteRequestsToUsersIds = this._sentInviteRequestsToUsersIds.filter((id) => id !== dialog?.id)
            }

            if (dialog) {
              dialog.hidden = false
              this._dialogsList.addModel(dialog)
              this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Last)!.addModel(dialog)

              if (scopeData.event.Cmd === ScopeMessengerCommands.InviteAccept) {
                if (this._isDialogActive(dialog)) {
                  await this.markDialogAsRead(dialog)
                  await this.getDialogMessages(dialog, true)
                } else {
                  dialog.resetInitialState()
                }
              }

              if (dialog.newMessage > 0) {
                if (this._isDialogActive(dialog)) {
                  dialog.newMessage = 0
                  this._coomeetChatInstance.markDialogAsRead(dialog)
                } else {
                  this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Unread)!.addModel(dialog)
                }
              }
            }
          }
        }
      }
        break

      case ScopeMessengerCommands.InviteCancel: {
        if (!scopeData.event.Cid) {
          for (let contactId in scopeData.data.contacts) {
            const id = parseInt(contactId)
            const dialog = this._dialogsList.getById(id)
            if (dialog) {
              dialog.setData({
                ID: id,
                ...scopeData.data.contacts[contactId]
              })

              dialog.status = null

              this._coomeetChatInstance.removeDialogFromAllLists(dialog)
            }
          }
        }
      }
        break

      case ScopeMessengerCommands.OnlineStatus: {
        const dialogId = parseInt(scopeData.data.id as string)
        let dialog = this._dialogsList.getById(dialogId)

        if (dialog) {
          dialog.online = scopeData.data.status as number

          if (dialog.online) {
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Online)!.addModel(dialog)
          } else {
            dialog.typing = false
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Online)!.removeModel(dialog)
          }
        }
      }
        break

      case ScopeMessengerCommands.LowScoreMessage:
        this._coomeetChatInstance.dialogsList.modelsList.value.forEach((dialogItem) => {
          dialogItem.markMessageForResend(scopeData)
        })
        break

      case ScopeMessengerCommands.GetMessages:
      case ScopeMessengerCommands.SendCompleted: {
        const dialogId = scopeData.data.id as number
        const dialog = this._dialogsList.getById(dialogId)

        if (dialog) {
          dialog.firstMessagesReceived = true

          if (scopeData.event.Cmd === ScopeMessengerCommands.GetMessages) {
            if (Object.keys(scopeData.data.messages).length < dialog.messagesListOffset.limit) {
              dialog.allMessagesLoaded = true
            }
          }

          if (scopeData.event.Cmd === ScopeMessengerCommands.SendCompleted) {
            if (scopeData.data.bot_status) {
              dialog.setBotStatus(scopeData.data.bot_status)
            }
          }

          if (dialog.messagesListOffset.skip === 0) {
            applicationCache.clearDialogMessages(dialog)
          }

          for (let messageId in scopeData.data.messages) {
            const messageData = scopeData.data.messages[messageId]
            dialog.addMessage(messageData, scopeData)

            if (scopeData.event.Cmd === ScopeMessengerCommands.SendCompleted) {
              dialog.setLastMessage(messageData)
            }
          }

          if (scopeData.event.Cmd === ScopeMessengerCommands.GetMessages) {
            if (this._isDialogActive(dialog)) {
              if (dialog.newMessage > 0) this.markDialogAsRead(dialog)

              dialog.messagesList
                .filter((messageItem) => messageItem.inbox && !messageItem.read)
                .forEach((messageItem) => messageItem.read = 1)

              dialog.newMessage = 0
            }
          }
        }
      }
        break

      case ScopeMessengerCommands.OnlineMessage:
      {
        const dialogId = scopeData.data.id as number
        let dialog = this._dialogsList.getById(dialogId)

        if (!dialog || dialog?.hidden) {
          const dialogFromSocket = await this._getDialogFromSocket(dialogId)

          if (dialogFromSocket) {
            if (!dialog) {
              dialog = dialogFromSocket
            } else {
              dialog.hidden = false
              dialog.setData(dialogFromSocket?.json)
            }
          }

          if (dialog) {
            this._dialogsList.addModel(dialog)
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Last)!.addModel(dialog)
          }
        }

        if (dialog) {
          for (let messageId in scopeData.data.messages) {
            const messageData = scopeData.data.messages[messageId]
            dialog.addMessage(messageData)
            dialog.setLastMessage(messageData)

            if (this._coomeetChatInstance.user && messageData.ball) {
              this._coomeetChatInstance.user.value.score +=  messageData.ball
            }

            if (messageData.inbox) {
              if (this._currentVideoDialog.value &&
                this._currentVideoDialog.value?.id === dialogId &&
                this._coomeetChatInstance.videoDialogState.value === VideoDialogState.Connected) {
                this.markMessageAsRead(dialog, messageData)

                dialog.newMessage = dialog.originalMessagesList
                  .filter((messageItem) => messageItem.inbox && !messageItem.read).length

              } else if (this._isDialogActive(dialog) || (this._currentDialog.value?.id === this._currentVideoDialog.value?.id &&
                this._coomeetChatInstance.videoDialogState.value === VideoDialogState.Connected)) {

                if (document.hasFocus()) {
                  this._coomeetChatInstance.markDialogAsRead(dialog)
                  dialog.newMessage = 0
                }

                // Отказались от сигнала Read {id, msgId}
                //
                //this.markMessageAsRead(dialog, messageData)
                // dialog.newMessage = dialog.messagesList
                //   .filter((messageItem) => messageItem.inbox && !messageItem.read).length

              } else {
                this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Unread)!.addModel(dialog)
                dialog.newMessage ++
              }
            }
          }
        }
      }
        break

      case ScopeMessengerCommands.ReadAll: {
        const dialogId = scopeData.data.id as number
        const dialog = this._dialogsList.getById(dialogId)
        if (dialog) {
          dialog.messagesList.forEach((messageItem) => {
            if (!messageItem.read && !messageItem.inbox) messageItem.read = 1
          })

          this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Unread)!.addModel(dialog)
        }
      }
        break

      case ScopeMessengerCommands.Read : {
        const dialogId = scopeData.data.id as number
        const messageId = scopeData.data.idmsg as number

        const dialog = this._dialogsList.getById(dialogId)
        if (dialog) {
          const message = dialog.getMessageById(messageId)
          if (message) {
            message.read = 1
          }

          dialog.newMessage = dialog.originalMessagesList.filter((messageItem) => messageItem.inbox && !messageItem.read).length
        }
      }
      break

      case ScopeMessengerCommands.MediaStatus: {
        const dialog = this._coomeetChatInstance.dialogsList.getById(scopeData.data.id)
        if (dialog) {
          const messageId = scopeData.data.msgid as number
          const message = dialog.getMessageById(messageId)

          if (message && message.type === DialogModelMessageType.NewFormatVideo) {
            const video = message.msg as DialogModelMessageVideo

            switch (scopeData.data.type as string) {
              case 'preview':
                video.previewready = 1
                video.preview = scopeData.data.source
                break
              case 'webm':
                video.webmready = 1
                video.webm = scopeData.data.source
                break
              case 'mp4':
                video.mp4ready = 1
                video.mp4 = scopeData.data.source
                break
            }
          }
        }
      }
        break

      case ScopeMessengerCommands.Gift: {
        const dialogId = scopeData.data.id as number
        if (this._coomeetChatInstance.currentVideoDialog.value?.id === dialogId) {
          const gift = GiftModel.CreateGiftById(scopeData.data.gift.id as number)
          this._coomeetChatInstance.currentVideoDialog.value!.videoChatGift = gift
          this._coomeetChatInstance.currentVideoDialog.value!.score -= gift?.minutes??0
        }
      }
        break
    }
  }

  private async _getDialogFromSocket(dialogId: number) : Promise<DialogModel | null> {
    let dialog : DialogModel | null = null
    const {response} = await this.userInfo(dialogId)

    if (response) {
      for (let contactId in response.data) {
        let contactData = {
          ID: parseInt(contactId),
          ...response.data[contactId]
        }
        dialog = DialogModel.FromJSON(contactData)
      }
    }

    return dialog
  }

  /** Команды **/

  public userSearch(name: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.UserSearch, {
      name
    }).then(({command, response}) => {
      if (response !== null) {
        const currentModelsList = this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Search)!

        for (let contactId in response.data.contacts) {
          let contactData = {
            ID: parseInt(contactId),
            ...response.data.contacts[contactId]
          }

          let dialog = this._dialogsList.getById(parseInt(contactId))

          if (!dialog) {
            dialog = DialogModel.FromJSON(contactData)
            this._dialogsList.addModel(dialog)
          }

          currentModelsList.addModel(dialog)
        }
      }
      return {command, response}
    })
  }

  public getContactsList(offset: ContactsListOffset, rule : ContactsListRule) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.ContactList, {
      limit: offset.limit,
      skip: offset.skip,
      rule
    }).then(({command, response}) => {
      if (response !== null) {
        const currentModelsList = this._coomeetChatInstance.dialogsListByRule.get(rule)!

        this._unreadMessagesCount.value = response.data.unread as number
        this._totalContactsCount.value = response.data.total as number

        for (let contactId in response.data.contacts) {
          let contactData = {
            ID: parseInt(contactId),
            ...response.data.contacts[contactId]
          }

          let dialog = this._dialogsList.getById(parseInt(contactId))

          if (dialog && contactData.delete === 4) {
            this._dialogsList.removeModel(dialog)
            dialog = null
          }

          if (!dialog) {
            dialog = DialogModel.FromJSON(contactData)
            this._dialogsList.addModel(dialog)
          } else {
            dialog.setData(contactData)
          }

          currentModelsList.addModel(dialog)

          if (dialog?.newMessage > 0) {
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Unread)!.addModel(dialog!)
          }
        }
      }

      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public getDialogMessages(dialogModel: DialogModel, clear = false) : Promise<CommandPromiseArguments> {
    if (clear) {
      dialogModel.messagesListOffset.skip = 0
      if (dialogModel.id !== this._currentVideoDialog.value?.id) dialogModel.clearMessages()
    }

    if (!dialogModel.messagesLoading) {
      const {limit, skip} = dialogModel.messagesListOffset
      dialogModel.messagesLoading = true

      return this.sendCommand(ScopeMessengerCommands.GetMessages, {
        id: dialogModel.id,
        limit,
        skip
      }).then(({command, response}) => {
        dialogModel.messagesListOffset.skip += limit

        if (response?.data.bot_status) {
          dialogModel.setBotStatus(response.data.bot_status)
        }
        dialogModel.messagesLoading = false
        dialogModel.removeTempMessages()
        return {command, response}
      })
    } else {
      return Promise.resolve<CommandPromiseArguments>({command: null, response: null})
    }
  }

  public sendTextMessage(message: string, dialog: DialogModel, commandId?:number) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.sendingMediaInProgress.value = true
    const promise = this.sendCommand(ScopeMessengerCommands.SendMessage, {
      id: dialog.id,
      msg: message,
      type: DialogModelMessageType.Text,
      command_id: commandId
    }).then(({response, command}) => {
      if (response?.data.bot_status) {
        dialog.setBotStatus(response.data.bot_status)
      }
      return {command, response}
    }) as Promise<CommandPromiseArguments>

    promise.catch(({command, response}) => {
      dialog.markMessageForResend(command)
    }).finally(() => {
      this._coomeetChatInstance.sendingMediaInProgress.value = false
    })

    return promise
  }

  public async sendPhotoMessage(base64: string, dialog: DialogModel) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.sendingMediaInProgress.value = true
    const promise = this.sendCommand(ScopeMessengerCommands.SendMessage, {
      id: dialog.id,
      byte: base64,
      type: DialogModelMessageType.Picture
    },{ type: 'image/png' }).then(({command, response}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>

    promise.catch(({command, response}) => {
      dialog.markMessageForResend(command)
    }).finally(() => {
      this._coomeetChatInstance.sendingMediaInProgress.value = false
    })

    return promise
  }

  public sendVideoMessage({videoBase64, previewBase64} : {videoBase64: string, previewBase64: string}, dialog: DialogModel) {
    this._coomeetChatInstance.sendingMediaInProgress.value = true

    const promise = this.sendCommand(ScopeMessengerCommands.SendMessage, {
      id: dialog.id,
      preview: previewBase64,
      byte: videoBase64,
      type: DialogModelMessageType.NewFormatVideo
    }, { type : this._coomeetChatInstance.videoMimeType }).then(({response, command}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>

    promise.catch(({command, response}) => {
      dialog.markMessageForResend(command)
    }).finally(() => {
      this._coomeetChatInstance.sendingMediaInProgress.value = false
    })

    return promise
  }

  public markMessageAsRead(dialog: DialogModel, message: DialogModelMessage) : Promise<CommandPromiseArguments> {
    message.read = 1
    dialog.newMessage = dialog.messagesList.filter((messageItem) => messageItem.inbox && !messageItem.read).length
    return this.sendCommand(ScopeMessengerCommands.Read, {
      id: dialog.id,
      idmsg: message.id
    }).then(({command, response}) => {
      return {command, response}
    })
  }

  public markDialogsAsRead(dialogs: DialogModel[]) : Promise<CommandPromiseArguments> {
    const ids = dialogs.map((dialogItem) => dialogItem.id)
    return this.sendCommand(ScopeMessengerCommands.ReadMulty, {
      ids
    }).then(({command, response}) => {
      dialogs.forEach((dialogItem) => {
        dialogItem.newMessage = 0
      })
      return {command, response}
    })
  }

  public markDialogAsRead(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    dialog.messagesList
      .filter((messageItem) => messageItem.inbox)
      .forEach((messageItem) => messageItem.read = 1)
    return this.sendCommand(ScopeMessengerCommands.ReadAll, {
      id: dialog.id
    }).then(({command, response}) => {
      dialog.newMessage = 0
      return  {command, response}
    })
  }

  public readFull() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.ReadFull, {}).then(({command, response}) => {
      this._coomeetChatInstance.dialogsList.modelsList.value.forEach((dialogItem) => {
        dialogItem.newMessage = 0
        dialogItem.messagesList
          .filter((messageItem) => messageItem.inbox)
          .forEach((messageItem) => messageItem.read = 1)
      })
      return {command, response}
    })
  }

  public clearFull() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.ClearFull, {}).then(({command, response}) => {
      this._coomeetChatInstance.dialogsList.modelsList.value.forEach((dialogItem) => {
        if (dialogItem.status === DialogModelStatus.ApprovedInvite) dialogItem.clearMessages()
      })
      return {command, response}
    })
  }

  public addDialogToFavorites(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.Favorite, {
      id: dialog.id,
      type: 1
    }).then(({command, response}) => {

      if (response) {
        for (let id in response.data.contacts) {
          const contactId = parseInt(id)
          const data = {
            ID: contactId,
            ...response.data.contacts[id]
          }

          const dialog = this._dialogsList.getById(contactId)
          if (dialog) {
            dialog.favorite = data.favorite
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Favorite)!.addModel(dialog)
          }
        }
      }

      return {command, response}
    })
  }

  public removeDialogFromFavorites(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.Favorite, {
      id: dialog.id,
      type: 0
    }).then(({command, response}) => {
      if (response) {
        for (let id in response.data.contacts) {
          const contactId = parseInt(id)
          const data = {
            ID: contactId,
            ...response.data.contacts[id]
          }

          const dialog = this._dialogsList.getById(contactId)
          if (dialog) {
            dialog.favorite = data.favorite
            this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Favorite)!.removeModel(dialog)
          }
        }
      }
      return {command, response}
    })
  }

  public removeUser(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    this._coomeetChatInstance.dialogsHistory.removeModel(dialog)
    this._coomeetChatInstance.removeDialogFromAllLists(dialog)
    return this.sendCommand(ScopeMessengerCommands.RemoveUser, {
      id: dialog.id
    }).then(({command, response}) => {
      dialog.delete = DialogModelDelete.NotDeleted
      return {command, response}
    })
  }

  public invite(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    // на эту команду нет ответа, приходи InviteSend без Cid

    if (this._sentInviteRequestsToUsersIds.indexOf(dialog.id) < 0) {
      this._sentInviteRequestsToUsersIds.push(dialog.id)

      return this.sendCommand(ScopeMessengerCommands.Invite, {
        id: dialog.id
      })
    }

    return Promise.resolve<CommandPromiseArguments>({
      command: null,
      response: null
    })
  }

  public inviteDecline(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.InviteDecline, {
      id: dialog.id
    }).then(({command, response}) => {
      this._coomeetChatInstance.removeDialogFromAllLists(dialog)

      const historyDialog = this._coomeetChatInstance.dialogsHistory.getById(dialog.id)

      if (historyDialog) {
        historyDialog.setData({
          ...historyDialog,
          ID: historyDialog.id,
          status: DialogModelStatus.HasNoInvite,
          delete: DialogModelDelete.NotDeleted
        })
      }

      return {command, response}
    })
  }

  public inviteAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    if (this._acceptInviteRequestsToUsersIds.indexOf(dialog.id) < 0) {
      this._acceptInviteRequestsToUsersIds.push(dialog.id)

      const promise = this.sendCommand(ScopeMessengerCommands.InviteAccept, {
        id: dialog.id
      })

      promise.then(({command, response}) => {
        if (response) {
          for (let id in response.data.contacts) {
            const contactId = parseInt(id)
            const data = {
              ID: contactId,
              ...response.data.contacts[id]
            }

            const dialog = this._dialogsList.getById(contactId)

            if (dialog) {
              dialog.hidden = false
              dialog.setData(data)

              if (this._isDialogActive(dialog)) {
                this.getDialogMessages(dialog, true)

                this._coomeetChatInstance.setCurrentDialog(dialog)
                this._coomeetChatInstance.dialogsListByRule.get(ContactsListRule.Last)!.addModel(dialog)
              }
            }
          }
        }
        return {command, response}
      })

      promise.finally(() => {
        this._acceptInviteRequestsToUsersIds = this._acceptInviteRequestsToUsersIds
          .filter((id) => id !== dialog.id)
      })

      return promise
    }

    return Promise.resolve<CommandPromiseArguments>({
      command: null,
      response: null
    })
  }

  public inviteCancel(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.InviteCancel, {
      id: dialog.id
    }).then(({command, response}) => {
      dialog.status = null
      this._coomeetChatInstance.removeDialogFromAllLists(dialog)
      return {command, response}
    })
  }

  public userInfo(dialogId: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.UserInfo, {
      id: dialogId
    })
  }

  public getContact(dialogId: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.GetContact, {
      id: dialogId
    })
  }

  public comment(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.Comment, {
      id: dialog.id
    }).then(({command, response}) => {
      if (response) {
        dialog.comment = response.data.comment as string
      }
      return {command, response}
    })
  }

  public commentSave(dialog: DialogModel, text: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.CommentSave, {
      id: dialog.id,
      msg: text
    }).then(({command, response}) => {
      if (response) {
        dialog.comment = text
      }
      return {command, response}
    })
  }

  public clear(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.Clear, {
      id: dialog.id,
    }).then(({command, response}) => {
      if (response) {
        dialog.clearMessages()
        setTimeout(() => {
          dialog.setLastMessage(response.data.lastMessage)
          dialog.addMessage(response.data.lastMessage)
        }, 0)
      }
      return {command, response}
    })
  }

  public clearMulty(dialogs: DialogModel[]) : Promise<CommandPromiseArguments> {
    const ids = dialogs.map((dialogItem) => dialogItem.id)

    return this.sendCommand(ScopeMessengerCommands.ClearMulty, {
      ids
    }).then(({command, response}) => {
      dialogs.forEach((dialogItem) => {
        if (dialogItem.status === DialogModelStatus.ApprovedInvite) {
          dialogItem.clearMessages()
          dialogItem.clearLastMessage()
        }
      })
      return {command, response}
    })
  }

  public gift(dialog: DialogModel, giftId: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.Gift, {
      id: dialog.id,
      present: giftId
    }).then(({command, response}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public afterGift(dialog: DialogModel, giftId: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.AfterGift, {
      id: dialog.id,
      present: giftId
    }).then(({command, response}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public sendSticker(sticker: StickerModel, dialog: DialogModel) : Promise<CommandPromiseArguments>  {
    return this.sendCommand(ScopeMessengerCommands.SendMessage, {
      id: dialog.id,
      msg: sticker.id,
      type: DialogModelMessageType.Sticker
    }).then(({command, response}) => {
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public contactRename(dialog: DialogModel, name: string) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.ContactRename, {
      id: dialog.id,
      name
    }).then(({command, response}) => {
      dialog.username = name
      return {command, response}
    }) as Promise<CommandPromiseArguments>
  }

  public supportAlert(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeMessengerCommands.SupportAlert, {
      id: dialog.id
    }).then(({command, response}) => {
      if (response?.data.systemInfoMessage)
        dialog.supportSystemInfoMessage = response.data.systemInfoMessage
      return {command, response}
    })
  }
}
