import browserStorage, {BrowserStorageKeys} from '../CoomeetChat/BrowserStorage.class'

export default class OptionsCache {
  private _cache = {
    unreadMessagesCount: 0 as number
  }
  private _saveTimeout: NodeJS.Timeout | null = null

  constructor() {
    this._getCache()
  }

  private _getCache() : void {
    const cache = browserStorage.getItem(BrowserStorageKeys.CoomeetAppCacheOptions)
    if (cache) {
      this._cache = JSON.parse(cache)
    }
  }

  private _saveCache() {
    if (this._saveTimeout) clearTimeout(this._saveTimeout)
    this._saveTimeout = null

    this._saveTimeout = setTimeout(() => {
      browserStorage.setItem(BrowserStorageKeys.CoomeetAppCacheOptions, JSON.stringify(this._cache))
    }, 100)
  }

  public get unreadMessagesCount() : number {
    return this._cache.unreadMessagesCount
  }

  public set unreadMessagesCount(count: number) {
    this._cache.unreadMessagesCount = count
    this._saveCache()
  }
}
