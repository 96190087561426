let i = 1

function *MessageIdGenerator() {
  while (true) {
    yield i++
  }
}

const messageIdGenerator = MessageIdGenerator()

const getVideoChatMessageId = () : number => (messageIdGenerator.next().value as number)

export default getVideoChatMessageId
export const updateVideoChatMessageIdPosition = (from : number) : void  => {
  i = from
}
