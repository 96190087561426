import { defineStore } from 'pinia'
import type {DialogModelMessage} from '../common/classes/CoomeetChat/models/DialogModel/interfaces'
import DialogModel from '../common/classes/CoomeetChat/models/DialogModel.class'
import GiftModel from '../common/classes/CoomeetChat/models/GiftModel.class'
import browserStorage, {BrowserStorageKeys} from '../common/classes/CoomeetChat/BrowserStorage.class'

export enum VideoChatMode {
  VideoHistory = 'VideoHistory',
  VideoChat = 'VideoChat'
}

export const useChatStore = defineStore({
  id: 'chat',
  state: () => ({
    playingVideo: null as (DialogModelMessage | null),

    lastVideoDialog: null as (DialogModel | null),

    editDialogs: false,
    searchDialogsByName: false,
    searchDialogsName: '',

    selectedDialogs: [] as DialogModel[],
    videoChatCameraMinimized: false,
    videoChatOpened: true,
    videoChatMode: null as VideoChatMode | null,
    videoChatControlsHideTimer: null as (NodeJS.Timeout | null),
    videoChatControlsOpened: true,
    videoChatMessagesOpened: true,
    videoChatSendedGift: null as GiftModel | null,
    videoStreamsSwapped: false,
    micMuted: false,
    videoSoundEnabled: true,
    videoDeviceId: 'default',
    audioDeviceId: 'default',
    videoFacingMode: true,

    storiesCapturedVideoChatState: false,

    storiesRecorderOpened: false,

    pushNotificationsEnabled: false,
    pushNotificationReminderHidden: false,
    pushNotificationReminderHiddenInDialogsList: false,

    findOnlySafe: false,

    historySortBy: 'last-message',

    videoChatWasOpenedOnOutgoingCall: false,
    lastChatType: "Video",
    cameraLeftPosition: 0,
    cameraTopPosition: 0,
    cameraSize: 1,

    termsWasShowed: false
  }),
  getters: {
  },
  actions: {
    hidePushNotificationsReminder() {
      this.pushNotificationReminderHidden = true
    },

    hidePushNotificationsReminderInDialogsList() {
      this.pushNotificationReminderHiddenInDialogsList = true
    },

    enablePushNotifications() : void {
      this.pushNotificationsEnabled = true
    },

    setLastVideoDialog(dialog: DialogModel) : void {
      this.lastVideoDialog = dialog
    },
    setlastChatType(type : string) : void {
      this.lastChatType = type
    },

    setVideoSoundEnabled(enabled : boolean) : void {
      this.videoSoundEnabled = enabled
      browserStorage.setItem(BrowserStorageKeys.VideoSoundEnabled.toString(), enabled ? 'true' : 'false')
    },
    setVideoFacingMode(enabled: boolean) : void {
      this.videoFacingMode = enabled
      browserStorage.setItem(BrowserStorageKeys.FaceMode.toString(), enabled ? 'true' : 'false')
    },

    setFindOnlySafe(enabled: boolean) : void {
      this.findOnlySafe = enabled
      browserStorage.setItem(BrowserStorageKeys.FindSafe, enabled ? 'true' : 'false')
    },

    openStoriesRecorder() : void {
      this.storiesRecorderOpened = true
    },
    closeStoriesRecorder() : void {
      this.storiesRecorderOpened = false
    },

    setMicMuteState(muted: boolean) : void {
      this.micMuted = muted
      browserStorage.setItem(BrowserStorageKeys.MicrophoneMuted.toString(), muted ? 'true' : 'false')
    },
    setVideoDeviceId(deviceId: string) : void {
      this.videoDeviceId = deviceId
      browserStorage.setItem(BrowserStorageKeys.VideoDeviceId.toString(), deviceId)
    },
    setAudioDeviceId(deviceId: string) : void {
      this.audioDeviceId = deviceId
      browserStorage.setItem(BrowserStorageKeys.AudioDeviceId.toString(), deviceId)
    },

    swapVideoStreams(swap: boolean) : void {
      this.videoStreamsSwapped = swap
    },
    setVideoChatMode (mode: VideoChatMode | null) {
      this.videoChatMode = mode
    },

    setVideoChatSendedGift(gift: GiftModel | null) {
      this.videoChatSendedGift = gift
    },

    hideVideoChatControlsByTimer() {
      if (this.videoChatControlsHideTimer) {
        clearTimeout(this.videoChatControlsHideTimer);
      }
      this.videoChatControlsHideTimer = setTimeout(() => {
        this.videoChatControlsOpened = false
      }, 60000)
    },
    hideVideoChatControls() {
      this.videoChatControlsOpened = false
      if (this.videoChatControlsHideTimer)  {
        clearTimeout(this.videoChatControlsHideTimer);
        this.videoChatControlsHideTimer = null
      }
    },
    showVideoChatControls() {
      this.videoChatControlsOpened = true
      if (this.videoChatControlsHideTimer)  {
        clearTimeout(this.videoChatControlsHideTimer);
        this.videoChatControlsHideTimer = null
      }
    },
    toggleVideoChatControls() {
      this.videoChatControlsOpened ? this.hideVideoChatControls() : this.showVideoChatControls()
    },

    hideVideoChatMessages() {
      this.videoChatMessagesOpened = false
    },
    showVideoChatMessages() {
      this.videoChatMessagesOpened = true
    },
    toggleVideoChatMessages() {
      this.videoChatMessagesOpened ? this.hideVideoChatMessages() : this.showVideoChatMessages()
    },

    openVideoChat () {
      this.videoChatOpened = true
    },
    closeVideoChat () {
      this.videoChatOpened = false
    },

    setPlayingVideo (video : DialogModelMessage | null) {
      this.playingVideo = video
    },

    toggleDialogsSearchMode() : void {
      this.searchDialogsByName = !this.searchDialogsByName
    },

    toggleDialogsEditMode() : void {
      this.editDialogs = !this.editDialogs
      if (!this.editDialogs) {
        this.selectedDialogs = []
      }
    },

    disableDialogsEditMode () : void {
      this.editDialogs = false
      this.selectedDialogs = []
    },

    selectDialog(dialog: DialogModel) : void {
      if (!this.dialogSelected(dialog)){
        this.selectedDialogs.push(dialog)
      }
    },

    unselectDialog(dialog: DialogModel) : void {
      this.selectedDialogs = this.selectedDialogs.filter((dialogItem) => dialogItem.id !== dialog.id)
    },

    dialogSelected(dialog: DialogModel) : boolean {
      return this.selectedDialogs.filter((dialogItem) => dialogItem.id === dialog.id).length > 0
    }
  }
})

export const restoreChatStoreFromLocalStorage = () => {
  const chatStore = useChatStore()
  chatStore.micMuted = browserStorage.getItem(BrowserStorageKeys.MicrophoneMuted) === 'true'
  chatStore.videoFacingMode = browserStorage.getItem(BrowserStorageKeys.FaceMode) === 'false' ? false : true
  chatStore.videoDeviceId = browserStorage.getItem(BrowserStorageKeys.VideoDeviceId)??'default'
  chatStore.audioDeviceId = browserStorage.getItem(BrowserStorageKeys.AudioDeviceId)??'default'
  chatStore.findOnlySafe = browserStorage.getItem(BrowserStorageKeys.FindSafe) === 'true'
  chatStore.videoSoundEnabled = browserStorage.getItem(BrowserStorageKeys.VideoSoundEnabled) === 'false' ? false : true
}
