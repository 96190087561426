import {ContactsListRule} from '../CoomeetChat/CoomeetChat.class'
import DialogModel from '../CoomeetChat/models/DialogModel.class'
import browserStorage, {BrowserStorageKeys} from "../CoomeetChat/BrowserStorage.class";

export default class DialogsCache {
  private _cachedLists: any = {}
  private _saveTimeout: NodeJS.Timeout | null = null

  public get cache() {
    return this._cachedLists
  }


  constructor() {
    this._getCache()
  }

  private _getCache() : void {
    const cache = browserStorage.getItem(BrowserStorageKeys.CoomeetAppCacheDialogs)
    if (cache) {
      this._cachedLists = JSON.parse(cache)
    }
  }

  private _saveCache() {
    if (this._saveTimeout) clearTimeout(this._saveTimeout)
    this._saveTimeout = null

    this._saveTimeout = setTimeout(() => {
      browserStorage.setItem(BrowserStorageKeys.CoomeetAppCacheDialogs, JSON.stringify(this._cachedLists))
    }, 100)
  }

  public cacheList(rule: ContactsListRule, modelsList: DialogModel[]) {
    const list = modelsList.map((dialog) => JSON.parse(JSON.stringify(dialog.json)))
    this._cachedLists[rule] = list

    this._saveCache()
  }

  public clearList(rule: ContactsListRule) {
    delete this._cachedLists[rule]
    this._saveCache()
  }

  public clear() : void {
    this._cachedLists = {}
    this._saveCache()
  }
}
