import DialogModel from '../CoomeetChat/models/DialogModel.class'
import {DialogModelMessage} from '../CoomeetChat/models/DialogModel/interfaces'
import browserStorage, {BrowserStorageKeys} from '../CoomeetChat/BrowserStorage.class'

export default class MessagesCache {
  private _cache: any = {}
  private _saveTimeout : NodeJS.Timeout | null = null

  public get cache() {
    return this._cache
  }

  constructor() {
    this._getCache()
  }

  private _getCache() : void {
    const cache = browserStorage.getItem(BrowserStorageKeys.CoomeetAppCacheMessages)
    if (cache) {
      this._cache = JSON.parse(cache)
    }
  }

  private _saveCache() {
    if (this._saveTimeout) clearTimeout(this._saveTimeout)
    this._saveTimeout = null

    this._saveTimeout = setTimeout(() => {
      browserStorage.setItem(BrowserStorageKeys.CoomeetAppCacheMessages, JSON.stringify(this._cache))
    }, 100)
  }

  public addDialog(dialog: DialogModel, messagesList: DialogModelMessage[]) {
    let list = [] as any[]

    messagesList.forEach((messageItem) => {
      const exists = list.filter((cacheMessageItem: any) => cacheMessageItem.id === messageItem.id).length > 0
      if (!exists) {
        const data = JSON.parse(JSON.stringify(messageItem))
        delete data['gift']
        delete data['sticker']
        delete data['scopeData']

        list.push(data)
      }
    })

    if (list.length > 30) {
      list = list.slice(0, 30)
    }

    this._cache[dialog.id] = list

    this._saveCache()
  }

  public deleteDialogById(dialogId: number) : void {
    if (this._cache[dialogId]) {
      delete this._cache[dialogId]
    }

    this._saveCache()
  }

  public clearDialog(dialog: DialogModel) : void {
    this.deleteDialogById(dialog.id)
  }

  public clear() : void {
    this._cache = {}
    this._saveCache()
  }

  public getDialogMessagesFromCache(dialog: DialogModel) : any[] {
    if (!this._cache[dialog.id]) this._cache[dialog.id] = []
    const list = this._cache[dialog.id]

    return list
  }
}
