<template lang="pug">
.messages
  DateMessages(v-for="(messageBlock, index) in messagesBlocksList",
    :ref="el => { dateMessages[index] = el }",
    :key="messageBlock.date",
    :block="messageBlock")
</template>

<script lang="ts" setup>
import type {DialogModelMessage} from '../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import DialogModel from '../../../../common/classes/CoomeetChat/models/DialogModel.class'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {ref, shallowRef, watchEffect, nextTick} from 'vue'
import type {UnwrapRef} from 'vue'
import moment from 'moment'
import DateMessages from './Messages/DateMessages.vue'
import {useI18n} from 'vue-i18n'
import applicationCache from '../../../../common/classes/ApplicationCache.class'

export interface MessagesBlock {
  date: string,
  unixTime: number,
  messages: DialogModelMessage[]
}

const {d} = useI18n()

const dateMessages = ref<{getVisibleUnreadMessages: () => DialogModelMessage[] }[]>([])

const messagesBlocksList = ref<MessagesBlock[]>([])
const lastDialog = shallowRef<DialogModel | null>(null)

const getBlockByUnixTime = (time: number) : UnwrapRef<MessagesBlock> => {
  //const formattedDate = moment(time * 1000).format('dddd, MMMM D, YYYY')
  let formattedDate = d(new Date(time * 1000), 'date')
  const nowYear = new Date().getFullYear()
  const dialogYear = new Date(time * 1000).getFullYear()

  if (nowYear === dialogYear) {
    formattedDate = d(new Date(time * 1000), 'day-month-long')
  }

  formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.substring(1)
  const list = messagesBlocksList.value.filter((blockItem) => blockItem.date === formattedDate)

  if (list.length > 0) {
    return list[0]
  } else {
    const messageBlock = {
      date: formattedDate,
      unixTime: time,
      messages: []
    }

    messagesBlocksList.value.push(messageBlock)
    messagesBlocksList.value.sort((a, b) => a.unixTime > b.unixTime ? 1 : -1)

    return messageBlock
  }
}

const messageBlockContainsMessage = (messageBlock : MessagesBlock, message: any) : boolean => {
  return messageBlock.messages.filter((messageItem) => messageItem.id === message.id).length > 0
}

const getVisibleUnreadMessages = () => {
  let list: DialogModelMessage[] = []
  dateMessages.value.forEach((dateMessagesItem) => {
    if (dateMessagesItem) dateMessagesItem.getVisibleUnreadMessages().forEach((messageItem) => list.push(messageItem))
  })

  return list
}

watchEffect(() => {
  if (CoomeetChatInstance.currentDialog.value?.firstMessagesReceived) {
    messagesBlocksList.value = [getBlockByUnixTime(Date.now() / 1000)]
  }
})

watchEffect(() => {
  if (lastDialog.value?.id !== CoomeetChatInstance.currentDialog.value?.id) {
    lastDialog.value = CoomeetChatInstance.currentDialog.value
    messagesBlocksList.value = [getBlockByUnixTime(Date.now() / 1000)]
  }

  if (CoomeetChatInstance.currentDialog.value?.messagesList.length === 0) {
    messagesBlocksList.value = [getBlockByUnixTime(Date.now() / 1000)]
  } else {
    if (CoomeetChatInstance.currentDialog.value) {
      CoomeetChatInstance
          .currentDialog
          .value!.messagesList
          .forEach((messageItem: DialogModelMessage) => {
            const block = getBlockByUnixTime(messageItem.creationTime / 1000)
            if (!messageBlockContainsMessage(block as MessagesBlock, messageItem)) {
              block.messages.push(messageItem)
              block.messages.sort((a, b) => a.creationTime > b.creationTime ? 1 : -1)
            }
          })

      applicationCache.addDialogMessages(CoomeetChatInstance.currentDialog.value,
          CoomeetChatInstance.currentDialog.value!.messagesList)

      messagesBlocksList.value!.forEach((messagesBlockItem) => {
        messagesBlockItem.messages = messagesBlockItem.messages.filter((messageItem) => {
          return CoomeetChatInstance
              .currentDialog
              .value!.messagesList
              .filter((dialogMessageItem) => dialogMessageItem.id === messageItem.id)
              .length > 0
        })
      })
    }
  }
})

defineExpose({
  getVisibleUnreadMessages
})
</script>
