enum ScopeMessengerCommands {
  BlockUser = 'BlockUser',
  Clear = 'Clear',
  ClearMulty = 'ClearMulty',
  Comment = 'Comment',
  CommentSave = 'CommentSave',
  ContactCount = 'ContactCount',
  ContactList = 'ContactList',
  ContactRename = 'ContactRename',
  Favorite = 'Favorite',
  GetContact = 'GetContact',
  GetMessages = 'GetMessages',
  Gift = 'Gift',
  GiftSend = 'GiftSend',
  AfterGift = 'AfterGift',
  Invite = 'Invite',
  InviteAccept = 'InviteAccept',
  InviteCancel = 'InviteCancel',
  InviteDecline = 'InviteDecline',
  InviteSend = 'InviteSend',
  Logger = 'Logger',
  MediaStatus = 'MediaStatus',
  OnlineMessage = 'OnlineMessage',
  OnlineStatus = 'OnlineStatus',
  OnlineUpdate = 'OnlineUpdate',
  Read = 'Read',
  ReadAll = 'ReadAll',
  ReadMulty = 'ReadMulty',
  Referral = 'Referral',
  RemoveUser = 'RemoveUser',
  SendCompleted = 'SendCompleted',
  SendMessage = 'SendMessage',
  SendVideoMessage = 'SendVideoMessage',
  UserInfo = 'UserInfo',
  UserSearch = 'UserSearch',
  VideoReady = 'VideoReady',
  LowScoreMessage = 'LowScoreMessage',
  SupportAlert = 'SupportAlert',
  ReadFull = 'ReadFull',
  ClearFull = 'ClearFull',
  UpdateNotification = 'UpdateNotification'
}

export default ScopeMessengerCommands
