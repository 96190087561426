import Scope from './Scope.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import ScopeStoryCommands from "./ScopeStory/commands";
import StoryModel from "../../models/StoryModel.class";

export default class ScopeStory extends Scope {
  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      default:
        break
    }
  }

  public getNextStory() : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeStoryCommands.GetNextStory, {})
  }

  public getAuthorStory(womanId: number) : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeStoryCommands.GetAuthorStory, {
      womanId
    })
  }

  public updateStoryLike(story: StoryModel, status: boolean) : Promise<CommandPromiseArguments> {
    story.isLiked = status ? 1 : 0
    return this.sendCommand(ScopeStoryCommands.UpdateStoryLike, {
      id: story.id,
      womanId: story.womanId,
      status: status ? 1 : 0
    }).then(({command, response}) => {
      return {command, response}
    })
  }

  public storyAbuse(story: StoryModel, type: number)  : Promise<CommandPromiseArguments> {
    return this.sendCommand(ScopeStoryCommands.StoryAbuse, {
      storyId: story.id,
      type
    })
  }
}
