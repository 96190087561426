<template lang="pug">
.gift-item(@click="onGiftClick")
  .gift-item__image(:style="{backgroundImage: `url('${props.gift.image}')`}")
  .gift-item__title {{t(props.gift.title)}}
  .gift-item__cost {{t('C_GIFT_PRICE')}}
  .gift-item__cost {{t('APP_PL_MINUTE_', {n: costMinutes})}}
</template>
<script lang="ts" setup>
import {ref} from 'vue'
import GiftModel from '../../../../../../../../common/classes/CoomeetChat/models/GiftModel.class'
import CoomeetChatInstance from '../../../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {usePopupOverlayStore, Popups} from '../../../../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const props = defineProps<{
  gift: GiftModel
}>()

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const user = CoomeetChatInstance.user
const costMinutes = ref<number>(Math.floor(props.gift.minutes / 60))
const emit = defineEmits(['send'])

const onGiftClick = () => {
  if (user.value.score / 60 > costMinutes.value) {
    CoomeetChatInstance.sendGift(props.gift)
  } else {
    popupsStore.showPopup(Popups.BuyMinutes)
  }
  emit('send')
}
</script>
