<template lang="pug">
.dialog-not-selected
  template(v-if="emailConfirmed")
    .dialog-not-selected__image
    .dialog-not-selected__text {{t('APP_MSNGR_EMPTY_TEXT')}}
  template(v-else)
    .dialog-not-selected__email-form
      .dialog-not-selected__email-form--icon
        MessageIcon
      .dialog-not-selected__email-form--title {{t('C_SUBSCRIBE_TITLE')}}
      .dialog-not-selected__email-form--description {{t('C_SUBSCRIBE_TEXT')}}
      .dialog-not-selected__email-form--actions
        UiSimpleButton.size-46.color-pink(@click="onConfirmEmailClick") {{t('A_SUBSCRIBE')}}
        UiSimpleButton.size-46.color-white(@click="onSkipClick") {{t('A_SKIP')}}
</template>
<script lang="ts" setup>
import MessageIcon from '@/assets/images/icons/mail-with-percent-size-60.svg'
import UiSimpleButton from '../../../components/ui/Buttons/UiSimpleButton.vue'
import CoomeetChatInstance from '../../../common/classes/CoomeetChat/CoomeetChat.class'
import {computed, ref} from 'vue'
import {usePopupOverlayStore, Popups} from '../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const user = CoomeetChatInstance.user
const skipped = ref(false)

const emailConfirmed = computed(() => !user.value.needConfirmEmail || skipped.value)

const onConfirmEmailClick = () => {
  popupsStore.showPopup(Popups.EmailConfirm)
}

const onSkipClick = () => {
  skipped.value = true
}
</script>
